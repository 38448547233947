import * as React from "react";
import { Box, Button, Divider, Drawer, Grid } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useMutation } from "react-query";
import http from "../../../services/http-common";
import { setSessionExpired, useMainController } from "../../../context";
import { Spinner } from "@material-tailwind/react";
import PricingTable from "../../../pages/home/Pricing/PricingTable";

export default function Subscription({ sidebar = false }) {
  const [dispatchHttp] = useMainController();
  const [allProducts, setAllProducts] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(null);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const getProducts = useMutation(
    () => {
      return http.get("stripe/products", null, setSessionExpired, dispatchHttp);
    },
    {
      onSuccess: ({ data }) => {
        setAllProducts(data.products);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const handleBuy = (id, isRecurring) => {
    setLoading(true);
    buyProduct.mutate({ id, isRecurring });
  };

  const navigate = (url) => (window.location.href = url);

  const buyProduct = useMutation(
    (data) => {
      console.log(data.id, data.isRecurring);
      return http.post(
        `stripe/create-checkout-session`,
        {
          priceId: data.id,
          isRecurring: data.isRecurring,
        },
        null
      );
    },
    {
      onSuccess: ({ data }) => {
        navigate(data.url);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  React.useEffect(() => {
    getProducts.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <nav
        aria-label=""
        className={`main mailbox folders ${
          sidebar && "!absolute !py-2 bottom-0 w-full"
        }`}
      >
        <Divider />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              width: { xs: "190px", lg: "206px", xl: "206px" },
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {/* <span className="flex w-full flex-row flex-wrap-reverse justify-between">
              <div className="flex items-center gap-2">
                <span className="flex h-7 w-7 items-center justify-center rounded-full border border-token-border-light">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon-sm shrink-0"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.5001 3.44338C12.1907 3.26474 11.8095 3.26474 11.5001 3.44338L4.83984 7.28868C4.53044 7.46731 4.33984 7.79744 4.33984 8.1547V15.8453C4.33984 16.2026 4.53044 16.5327 4.83984 16.7113L11.5001 20.5566C11.8095 20.7353 12.1907 20.7353 12.5001 20.5566L19.1604 16.7113C19.4698 16.5327 19.6604 16.2026 19.6604 15.8453V8.1547C19.6604 7.79744 19.4698 7.46731 19.1604 7.28868L12.5001 3.44338ZM10.5001 1.71133C11.4283 1.17543 12.5719 1.17543 13.5001 1.71133L20.1604 5.55663C21.0886 6.09252 21.6604 7.0829 21.6604 8.1547V15.8453C21.6604 16.9171 21.0886 17.9075 20.1604 18.4434L13.5001 22.2887C12.5719 22.8246 11.4283 22.8246 10.5001 22.2887L3.83984 18.4434C2.91164 17.9075 2.33984 16.9171 2.33984 15.8453V8.1547C2.33984 7.0829 2.91164 6.09252 3.83984 5.55663L10.5001 1.71133Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M9.44133 11.4454L9.92944 9.98105C10.0321 9.67299 10.4679 9.67299 10.5706 9.98105L11.0587 11.4454C11.2941 12.1517 11.8483 12.7059 12.5546 12.9413L14.019 13.4294C14.327 13.5321 14.327 13.9679 14.019 14.0706L12.5546 14.5587C11.8483 14.7941 11.2941 15.3483 11.0587 16.0546L10.5706 17.519C10.4679 17.827 10.0321 17.827 9.92944 17.519L9.44133 16.0546C9.2059 15.3483 8.65167 14.7941 7.94537 14.5587L6.48105 14.0706C6.17298 13.9679 6.17298 13.5321 6.48105 13.4294L7.94537 12.9413C8.65167 12.7059 9.2059 12.1517 9.44133 11.4454Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M14.4946 8.05961L14.7996 7.14441C14.8638 6.95187 15.1362 6.95187 15.2004 7.14441L15.5054 8.05961C15.6526 8.50104 15.999 8.84744 16.4404 8.99458L17.3556 9.29965C17.5481 9.36383 17.5481 9.63617 17.3556 9.70035L16.4404 10.0054C15.999 10.1526 15.6526 10.499 15.5054 10.9404L15.2004 11.8556C15.1362 12.0481 14.8638 12.0481 14.7996 11.8556L14.4946 10.9404C14.3474 10.499 14.001 10.1526 13.5596 10.0054L12.6444 9.70035C12.4519 9.63617 12.4519 9.36383 12.6444 9.29965L13.5596 8.99458C14.001 8.84744 14.3474 8.50104 14.4946 8.05961Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
                <div className="flex flex-col">
                  <span>Upgrade plan</span>
                  <span className="line-clamp-1 text-xs text-token-text-tertiary">
                    More access to the best models
                  </span>
                </div>
              </div>
            </span> */}
            <LoadingButton
              size="small"
              onClick={handleOpen}
              sx={{
                mt: 2,
                borderRadius: "10px",
                height: "50.65px",
                mb: "20px",
                width: "205px",
              }}
              type="submit"
              variant="contained"
            >
              Upgrade plan
            </LoadingButton>
          </Box>
        </Box>
      </nav>
      <Drawer
        anchor={"bottom"}
        open={open}
        className="!w-full"
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: "100vw", height: "100vh", paddingX: "10px" }}
          role="presentation"
        >
          <div className="relative grid grid-cols-[1fr_auto_1fr] px-6 py-4 md:pb-10 md:pt-[4.5rem]">
            {/* <span className="mb-1 mt-1 text-2xl font-semibold md:mb-0 md:mt-0 md:text-3xl">
              Buy Tokens
            </span> */}
            <button
              onClick={toggleDrawer(false)}
              className="justify-self-end text-token-text-primary opacity-50 transition hover:opacity-75 md:absolute md:right-6 md:top-6"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon-lg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.63603 5.63604C6.02656 5.24552 6.65972 5.24552 7.05025 5.63604L12 10.5858L16.9497 5.63604C17.3403 5.24552 17.9734 5.24552 18.364 5.63604C18.7545 6.02657 18.7545 6.65973 18.364 7.05025L13.4142 12L18.364 16.9497C18.7545 17.3403 18.7545 17.9734 18.364 18.364C17.9734 18.7545 17.3403 18.7545 16.9497 18.364L12 13.4142L7.05025 18.364C6.65972 18.7545 6.02656 18.7545 5.63603 18.364C5.24551 17.9734 5.24551 17.3403 5.63603 16.9497L10.5858 12L5.63603 7.05025C5.24551 6.65973 5.24551 6.02657 5.63603 5.63604Z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>
          </div>
          <Grid container spacing={2}>
            {allProducts ? (
              <PricingTable plans={allProducts} />
            ) : (
              // allProducts.map((product) => (
              //   <Grid item xs={10} md={4} lg={3}>
              //     <div className="flex justify-center items-center">
              //       <Box sx={{ width: 400 }}>
              //         <div className="text-sm relative flex-1 flex gap-5 flex-col border-token-border-light border-t border-l border-r border-b rounded-xl md:min-h-[30rem] md:rounded-none md:border-r-0 md:last:border-r md:first:rounded-tl-xl md:first:rounded-bl-xl md:last:rounded-tr-xl md:last:rounded-br-xl md:max-w-96 justify-center px-6 pt-6 pb-10 md:pb-6">
              //           <div className="relative flex flex-col bg-token-main-surface-primary">
              //             <div className="flex flex-col gap-1">
              //               <p className="flex items-center gap-2 text-xl font-semibold">
              //                 <svg
              //                   width="24"
              //                   height="24"
              //                   viewBox="0 0 24 24"
              //                   fill="none"
              //                   xmlns="http://www.w3.org/2000/svg"
              //                   className="icon-md text-[#000080]"
              //                 >
              //                   <path
              //                     d="M6.394 4.444c.188-.592 1.024-.592 1.212 0C8.4 8.9 9.1 9.6 13.556 10.394c.592.188.592 1.024 0 1.212C9.1 12.4 8.4 13.1 7.606 17.556c-.188.592-1.024.592-1.212 0C5.6 13.1 4.9 12.4.444 11.606c-.592-.188-.592-1.024 0-1.212C4.9 9.6 5.6 8.9 6.394 4.444Zm8.716 9.841a.41.41 0 0 1 .78 0c.51 2.865.96 3.315 3.825 3.826.38.12.38.658 0 .778-2.865.511-3.315.961-3.826 3.826a.408.408 0 0 1-.778 0c-.511-2.865-.961-3.315-3.826-3.826a.408.408 0 0 1 0-.778c2.865-.511 3.315-.961 3.826-3.826Zm2.457-12.968a.454.454 0 0 1 .866 0C19 4.5 19.5 5 22.683 5.567a.454.454 0 0 1 0 .866C19.5 7 19 7.5 18.433 10.683a.454.454 0 0 1-.866 0C17 7.5 16.5 7 13.317 6.433a.454.454 0 0 1 0-.866C16.5 5 17 4.5 17.567 1.317Z"
              //                     fill="currentColor"
              //                   ></path>
              //                 </svg>
              //                 {product.name}
              //               </p>
              //             </div>
              //           </div>
              //           <div className="flex flex-col flex-grow gap-2">
              //             <div className="relative bg-token-main-surface-primary">
              //               <div className="text-l flex justify-start gap-2">
              //                 <svg
              //                   width="24"
              //                   height="24"
              //                   viewBox="0 0 24 24"
              //                   fill="none"
              //                   xmlns="http://www.w3.org/2000/svg"
              //                   className="mt-0.5 h-4 w-4 shrink-0"
              //                 >
              //                   <path
              //                     fill-rule="evenodd"
              //                     clip-rule="evenodd"
              //                     d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z"
              //                     fill="currentColor"
              //                   ></path>
              //                 </svg>
              //                 <span>
              //                   Tokens:{" "}
              //                   {Number(
              //                     product.metadata.tokens
              //                   )?.toLocaleString()}
              //                 </span>
              //               </div>
              //             </div>
              //             <div className="relative bg-token-main-surface-primary">
              //               <div className="text-l flex justify-start gap-2">
              //                 <svg
              //                   width="24"
              //                   height="24"
              //                   viewBox="0 0 24 24"
              //                   fill="none"
              //                   xmlns="http://www.w3.org/2000/svg"
              //                   className="mt-0.5 h-4 w-4 shrink-0"
              //                 >
              //                   <path
              //                     fill-rule="evenodd"
              //                     clip-rule="evenodd"
              //                     d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z"
              //                     fill="currentColor"
              //                   ></path>
              //                 </svg>
              //                 <span>
              //                   Average Questions:{" "}
              //                   {product.metadata.average_questions}
              //                 </span>
              //               </div>
              //             </div>
              //             <div className="relative bg-token-main-surface-primary">
              //               <div className="text-l flex justify-start gap-2">
              //                 <svg
              //                   width="24"
              //                   height="24"
              //                   viewBox="0 0 24 24"
              //                   fill="none"
              //                   xmlns="http://www.w3.org/2000/svg"
              //                   className="mt-0.5 h-4 w-4 shrink-0"
              //                 >
              //                   <path
              //                     fill-rule="evenodd"
              //                     clip-rule="evenodd"
              //                     d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z"
              //                     fill="currentColor"
              //                   ></path>
              //                 </svg>
              //                 <span>
              //                   Max References:{" "}
              //                   {product.metadata.max_references}
              //                 </span>
              //               </div>
              //             </div>
              //             <div className="relative bg-token-main-surface-primary">
              //               <div className="text-l flex justify-start gap-2">
              //                 {product.metadata.scientific_documents ===
              //                 "no" ? (
              //                   "X"
              //                 ) : (
              //                   <svg
              //                     width="24"
              //                     height="24"
              //                     viewBox="0 0 24 24"
              //                     fill="none"
              //                     xmlns="http://www.w3.org/2000/svg"
              //                     className="mt-0.5 h-4 w-4 shrink-0"
              //                   >
              //                     <path
              //                       fill-rule="evenodd"
              //                       clip-rule="evenodd"
              //                       d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z"
              //                       fill="currentColor"
              //                     ></path>
              //                   </svg>
              //                 )}
              //                 <span>Scientific Documents:</span>
              //               </div>
              //             </div>
              //             <div className="relative bg-token-main-surface-primary">
              //               <div className="text-l flex justify-start gap-2">
              //                 {product.metadata.legal_documents === "no" ? (
              //                   "X"
              //                 ) : (
              //                   <svg
              //                     width="24"
              //                     height="24"
              //                     viewBox="0 0 24 24"
              //                     fill="none"
              //                     xmlns="http://www.w3.org/2000/svg"
              //                     className="mt-0.5 h-4 w-4 shrink-0"
              //                   >
              //                     <path
              //                       fill-rule="evenodd"
              //                       clip-rule="evenodd"
              //                       d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z"
              //                       fill="currentColor"
              //                     ></path>
              //                   </svg>
              //                 )}
              //                 <span>Legal References:</span>
              //               </div>
              //             </div>
              //             <div className="relative bg-token-main-surface-primary">
              //               <div className="text-l flex justify-start gap-2">
              //                 <svg
              //                   width="24"
              //                   height="24"
              //                   viewBox="0 0 24 24"
              //                   fill="none"
              //                   xmlns="http://www.w3.org/2000/svg"
              //                   className="mt-0.5 h-4 w-4 shrink-0"
              //                 >
              //                   <path
              //                     fill-rule="evenodd"
              //                     clip-rule="evenodd"
              //                     d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z"
              //                     fill="currentColor"
              //                   ></path>
              //                 </svg>
              //                 <span>{product.metadata.support}</span>
              //               </div>
              //             </div>
              //           </div>
              // <div className="relative flex flex-col ">
              //   <div>
              //     <Button
              //       disabled={loading}
              //       size="small"
              //       type="button"
              //       onClick={() =>
              //         handleBuy(
              //           product.default_price.id,
              //           product.default_price.recurring ? true : false
              //         )
              //       }
              //       variant="contained"
              //       className="w-full  "
              //       sx={{
              //         mt: 2,
              //         borderRadius: "10px",
              //         height: "50.65px",
              //         mb: "20px",
              //       }}
              //     >
              //       Buy Now
              //     </Button>
              //   </div>
              // </div>
              //         </div>
              //       </Box>
              //     </div>
              //   </Grid>
              // ))
              <Spinner />
            )}
          </Grid>
        </Box>
      </Drawer>
    </>
  );
}
