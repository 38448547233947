import * as actionTypes from "../constants";

// ** Import type
import { Message } from "../../types/message";

const initialState: {
  end: boolean;
  llmModel: any;
  loading: boolean;
  messages: Message[];
  isStreaming: boolean;
  answerError: boolean;
  quickResponses: [];
} = {
  end: true,
  llmModel: localStorage.getItem("model"),
  loading: false,
  messages: [],
  isStreaming: false,
  quickResponses: [],
  answerError: false,
};

const reducer = (
  prevState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    // ** When user press enterKey or send message button
    case actionTypes.ADD_MESSAGE:
      return {
        ...prevState,
        loading: true,
        end: prevState.end,
        messages: [
          {
            sender: "human",
            message: action.payload?.finalChat,
          },
          ...prevState.messages,
        ],
      };

    case actionTypes.ADD_CHAT_MESSAGE:
      return {
        ...prevState,
        loading: false,
        end: prevState.end,
        messages: [
          {
            sender: "bot",
            id: action.payload?.data.messages[1].id,
            message: action.payload?.data.messages[1].content,
            model: action.payload?.data.messages[1].llm,
            intent: action.payload?.data.messages[1]?.intent,
            tokens: action.payload?.data.messages[1].tokens,
            feedback: action.payload?.data.messages[1].feedback,
            refer: JSON.parse(
              action.payload?.data.messages[1].refer
                ? action.payload?.data.messages[1].refer
                : "[]"
            ),
            question: action.payload?.data.messages[1].question,
          },
          ...prevState.messages,
        ],
        quickResponses: action.payload?.data?.questions
          ? action.payload?.data?.questions
          : [],
      };
    // ** After fetch answer
    case actionTypes.GET_ANSWER_REQUEST_SUCCESS:
      let messages: Message[] = [];
      action.payload?.data?.messages.reverse().map((_item: any) => {
        if (_item.role !== "user") {
          messages.push({
            id: _item.id,
            sender: "bot",
            feedback: _item.feedback,
            message: _item.content,
            intent: _item?.intent,
            model: _item.llm ? _item.llm : "",
            tokens: _item.tokens ? _item.tokens : "",
            refer: JSON.parse(_item.refer ? _item.refer : "[]"),
            question: _item.question,
          });
        }
        return [];
      });
      return {
        loading: false,
        end: action.payload?.data?.end,
        messages: [...messages, ...prevState.messages],
        quickResponses: action.payload?.data.questions
          ? action.payload?.data.questions
          : [],
      };

    case actionTypes.GET_ANSWER_REQUEST:
    case actionTypes.GET_ANSWER_AGAIN_REQUEST:
      return {
        ...prevState,
        loading: true,
        answerError: false,
      };
    case actionTypes.GET_ANSWER_REQUEST_ERROR:
      return {
        ...prevState,
        loading: false,
        answerError: true,
      };

    case actionTypes.GET_CHAT_HISTORY_REQUEST_SUCCESS:
      let chatHistory: Message[] = [];
      action.payload?.data?.messages.reverse().map((_item: any) => {
        if (_item.role !== "user") {
          chatHistory.push({
            id: _item.id,
            sender: "bot",
            message: _item.content,
            intent: _item?.intent,
            model: _item.llm,
            tokens: _item.tokens,
            feedback: _item.feedback,
            question: _item.question,
            refer: JSON.parse(_item.refer ? _item.refer : "[]"),
          });
        } else {
          chatHistory.push({
            id: _item.id,
            sender: "human",
            message: _item.content,
            feedback: _item.feedback,
          });
        }
        return 0;
      });
      return {
        ...prevState,
        end: action.payload?.data?.end,
        quickResponses: action.payload?.data.questions
          ? action.payload?.data.questions
          : [],
        messages: [...prevState.messages, ...chatHistory],
      };

    case actionTypes.ADD_SUGGESTION_QUESTIONS:
      return {
        quickResponses: action.payload?.data?.quickReplies,
      };

    // case actionTypes.DELETE_CHAT_HISTORY_REQUEST_SUCCESS:
    //   return {
    //     ...prevState,
    //     end: true,
    //     messages: [],
    //     quickResponses: [],
    //   };
    case actionTypes.CLEAR_MESSAGES_HISTORY:
      return {
        ...prevState,
        end: true,
        messages: [],
        quickResponses: [],
      };
    case actionTypes.EMBEDDING_HISTORY_REQUEST_SUCCESS:
      return {
        ...prevState,
        embeddingMessage: { ...action.payload?.data },
      };
    case actionTypes.UPDATE_MODEL_SUCCESS:
      return {
        ...prevState,
        llmModel: action.payload,
      };
    case actionTypes.CHAT_STREAMING:
      return {
        ...prevState,
        isStreaming: action.payload,
      };
    default:
      return prevState;
  }
};

export default reducer;
