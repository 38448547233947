import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  Input,
  DialogFooter,
  Select,
  Option,
} from "@material-tailwind/react";
import { toast } from "react-toastify";
import http from "../../services/http-common";
import { useMutation } from "react-query";
import { generateCouponCode } from "../../widgets/CouponCode";

function AddCoupon({ fetchAllCoupons }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({
    code: "",
    name: "",
    status: "",
    expiryDate: "",
    tokens: 10000,
    redemptions: 1,
  });

  const toogleOpen = () => {
    setOpen(!open);
  };

  const onChangeContent = (e) => {
    if (loading) return false;
    setContent({ ...content, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    createCoupon.mutate();
  };

  const createCoupon = useMutation(
    (webId) => {
      return http.post(`coupon/create`, content);
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        fetchAllCoupons();
        toogleOpen();
        setContent({
          code: "",
          status: "",
          name: "",
          expiryDate: "",
          tokens: null,
          redemptions: null,
        });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => {
        setLoading(false);
      },
      onMutate: () => {
        setLoading(true);
      },
    }
  );

  const handleGenerateCode = () => {
    let code = generateCouponCode(6);
    setContent({ ...content, code: code });
  };

  return (
    <>
      <Button
        variant="gradient"
        size="sm"
        className="flex items-center gap-3 py-2 px-4 "
        onClick={toogleOpen}
      >
        Add Coupon
      </Button>
      <Dialog
        size="xs"
        open={open}
        handler={toogleOpen}
        className="h-auto"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader>Add Coupon </DialogHeader>

        <DialogBody divider className="flex-col max-h-full  ">
          <form className="mt-2 mb-2  w-auto  max-w-screen-md  ">
            <div className=" flex flex-col gap-2 w-auto ">
              <div className=" my-1">
                <Input
                  type="text"
                  name="name"
                  label="Name"
                  value={content?.name}
                  onChange={onChangeContent}
                />
              </div>
              <div className="my-1">
                <div className="relative flex w-full max-w-[24rem]">
                  <Input
                    type="text"
                    name="code"
                    label=" Coupon Code"
                    value={content?.code}
                    onChange={onChangeContent}
                    min="10000"
                    step="500"
                    className="pr-20"
                    containerProps={{
                      className: "min-w-0",
                    }}
                  />
                  <Button
                    size="sm"
                    color={"gray"}
                    onClick={handleGenerateCode}
                    className="!absolute right-1 top-1 rounded"
                  >
                    Generate
                  </Button>
                </div>
              </div>
              <div className="flex w-full max-w-[24rem] my-1">
                <Select
                  name="status"
                  label="Status"
                  value={content?.status}
                  onChange={(val) => setContent({ ...content, status: val })}
                >
                  <Option value="ACTIVE">Active</Option>
                  <Option value="INACTIVE">Inactive</Option>
                  <Option value="EXPIRED">Expired</Option>
                </Select>
              </div>
              <div className="flex w-full max-w-[24rem] my-1">
                <Input
                  name="tokens"
                  type="number"
                  label="Tokens"
                  value={content?.tokens}
                  onChange={onChangeContent}
                />
              </div>
              <div className="flex w-full max-w-[24rem] my-1">
                <Input
                  name="redemptions"
                  type="number"
                  label="Redemptions"
                  value={content?.redemptions}
                  onChange={onChangeContent}
                />
              </div>
              <div className="flex w-full max-w-[24rem] my-1">
                <Input
                  name="expiryDate"
                  type="date"
                  label="Expiry Date"
                  value={content?.expiryDate}
                  onChange={onChangeContent}
                />
              </div>
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={toogleOpen}
            className="mr-1"
            disabled={loading}
          >
            <span>Close</span>
          </Button>
          <Button variant="gradient" onClick={onSubmit} disabled={loading}>
            <span>Submit</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default AddCoupon;
