import { styled, alpha } from "@mui/material/styles";
import { Box, Stack, Button, Container, Typography } from "@mui/material";
import { bgGradient } from "../../utils/cssStyles";
import useStyles from "./styles/styles";
import UserService from "../../KeyCloackServices/UserService";
import Shape from "./components/Shape";
import HowItWorks from "./HowItWorks";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, 0.9),
    imgUrl: "https://zone-ui.vercel.app/assets/background/overlay_1.jpg",
  }),
  position: "relative",
  overflow: "hidden",
  // [theme.breakpoints.up("md")]: {
  //   height: `calc(100vh - ${HEADER.H_MAIN_DESKTOP}px)`,
  // },
}));
const clases = {
  header1: {
    fontSize: {
      xs: "8.9vw !important",
      md: "3.38vw !important",
    },
    fontWeight: "600 !important",
    // letterSpacing: "-1.33px !important",
  },
  header2: {
    fontSize: {
      xs: "6.5vw !important",
      md: "2.5vw !important",
    },
    fontWeight: "550 !important",
    // letterSpacing: "-1.0px !important",
  },
  contentText: {
    fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
    fontWeight: "500 !important",
    color: "#757B8A !important",
    textAlign: { xs: "center", md: "left" },
  },
  buttonSx: {
    width: { md: "22.48vw", xs: "39.95vw" },
    fontSize: { xs: "3.56vw", md: "1.11vw" },
    paddingY: { xs: "5vw", md: "1.5vw" },
  },
};

export default function HomeHero() {
  const classes = useStyles();

  return (
    <StyledRoot
      sx={{
        height: 1,
        background: {
          xs: "linear-gradient(180deg, #F6F8FD 0%, #FFFFFF 100%)",
          md: "linear-gradient(180deg, #F7F9FF 11.26%, #FFFFFF 99.96%)",
        },
      }}
    >
      <Shape secNumber="1" justifyContent="end" />
      <Container id="home">
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems={"center"}
          sx={{
            py: 15,
            textAlign: { xs: "center", md: "left" },
          }}
        >

          <Typography sx={clases.header1}>
            Welcome,
            <Box component="span" sx={{ color: "primary.main" }}>
              {` Seeker!`}
            </Box>
             
          </Typography>
          <Typography sx={clases.header2}>
          To EntheoGPT – Your Psychedelic AI Guide
          </Typography>

          <Typography
            variant="h5"
            sx={{
              color: "text.secondary",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Discover, Prepare & Integrate
          </Typography>

          <HowItWorks />
          <Box
            className={classes.flexCenter}
            sx={{
              gap: "2vw",
              p: { md: "1vw", xs: "5vw" },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={clases.buttonSx}
              onClick={() => UserService.doLogin()}
            >
              Try Now
            </Button>
          </Box>
        </Stack>
      </Container>
    </StyledRoot>
  );
}
