import { all, fork } from "redux-saga/effects";

import authSaga from "./auth/saga";
import botSaga from "./bot/saga";
import msgSaga from "./messages/saga";
import dataSaga from "./data/saga";
import dataFlow from "./dataFlow/saga";
import websocket from "./websocket/saga";

export default function* rootSaga() {
  yield all([
    fork(msgSaga),
    fork(authSaga),
    fork(dataSaga),
    fork(botSaga),
    fork(dataFlow),
    fork(websocket),
  ]);
}
