// Bot.js
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { IoSend } from "react-icons/io5";
import "./Bot.css";
import { BotMessage, BotThinking } from "./BotThinking";
import { useSelector } from "react-redux";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
// import Appointment from "./Appointment";
import { LuUser } from "react-icons/lu";
import { Avatar, Typography } from "@material-tailwind/react";
import { IoIosArrowDown } from "react-icons/io";
import { getBotDetails } from "../../store/bot/actions";
import { httpMushPro } from "../../services/http-common";
import { toast } from "react-toastify";

const Bot = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isChating, setIsChating] = useState(false);
  const { botDetails } = useSelector((state) => state.bot);
  const { botStarters } = useSelector((state) => state.dataFlow);
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState({
    role: "Human",
    content: "",
  });
  const [allMessages, setAllMessages] = useState(null);
  const [isAppointment, setIsAppointment] = useState(false);

  const handleSubmit = () => {
    setIsChating(true);
    allMessages.push(userMessage);
    setLoading(true);
    // scrollToBottom();
    postMessage.mutate(null);
  };

  const getAllMessages = useMutation(
    () => {
      return httpMushPro.getPBotMessages(
        `/user/bots/getAllMessages`,
        { botId: botDetails?.id },
        null
      );
    },
    {
      onSuccess: ({ data }) => {
        setAllMessages(data?.messages);
        setLoading(false);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const postMessage = useMutation(
    (test) => {
      let content = test ? test : userMessage.content;
      setUserMessage({
        role: "Human",
        content: "",
      });
      return httpMushPro.postPBotMessages(
        `user/bots/writeMessage`,
        {
          botId: botDetails?.id,
          question: content,
          llm: "gpt-4o",
          kcid: user.id,
        },
        null
      );
    },
    {
      onSuccess: ({ data }) => {
        allMessages.push(data?.messages[1]);
        setIsAppointment(
          data?.messages[1].bookAppointment
            ? data?.messages[1].bookAppointment
            : false
        );
        // scrollToBottom();

        setUserMessage({
          role: "Human",
          content: "",
        });
        setLoading(false);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
        setLoading(false);
        setUserMessage({
          role: "Human",
          content: "",
        });
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit();
    }
  };

  const resetMessages = useMutation(
    async () => {
      return await httpMushPro.delete("user/bots/resetMessages", null);
    },
    {
      onSuccess: () => {
        toast.success("Reset Successfully !");
        getAllMessages.mutate();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleResetMessages = () => {
    resetMessages.mutate();
  };
  useEffect(() => {
    botDetails?.id && getAllMessages.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botDetails]);

  useEffect(() => {
    dispatch(getBotDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="h-[92vh]">
        <div className="flex flex-col h-full">
          <Typography
            variant="h5"
            className="sm:flex hidden  justify-center py-3 h-[42px]"
          >
            Preview
          </Typography>
          {!isChating ? (
            <>
              <div className="container h-full pb-14 relative pt-2">
                <div className="h-full  pl-5 flex justify-center items-center">
                  <div>
                    <div className="flex items-center justify-center pt-3">
                      {botDetails?.iconUrl ? (
                        <div className="flex flex-col justify-center items-center gap-2">
                          <Avatar
                            size="xl"
                            variant="circular"
                            alt="Bot logo"
                            className="border-2 border-white"
                            src={botDetails?.iconUrl}
                          />
                        </div>
                      ) : (
                        <div className="flex flex-col justify-center items-center gap-2">
                          <button
                            className="h-20 w-20 me-2"
                            type="button"
                            id="radix-:ri:"
                            aria-haspopup="menu"
                            aria-expanded="false"
                            data-state="closed"
                          >
                            <div class="flex h-full w-full items-center justify-center rounded-full border-2 border-dashed border-token-border-medium">
                              <LuUser className="text-4xl" />
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="pb-4 flex flex-col justify-center items-center">
                      <h3 className="w-full text-pretty text-lg font-bold text-center">
                        {botDetails.botName}
                      </h3>
                      {/* <h5 className="w-8/12 text-pretty text-lg font-bold text-center">
                A friendly and reliable partner, always ready to help with a warm smile and genuine kindness
              </h5> */}
                    </div>
                    <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-4">
                      {botStarters &&
                        botStarters.slice(0, 4).map((starter, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-center cursor-pointer"
                          >
                            <div className="w-48 h-min  rounded-xl border-2 border-gray-200">
                              <div className=" p-2">
                                <p className="text-sm text-start line-clamp-3 max-w-full text-balance text-gray-600 dark:text-gray-500 break-word">
                                  {starter.question}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-20 !w-[inherit] pe-2 ">
                  <div className={`chatbot  w-auto `}>
                    <div className="chat-input   ">
                      <ArrowPathIcon
                        width={32}
                        className=" cursor-pointer"
                        onClick={handleResetMessages}
                      />
                      <textarea
                        value={userMessage.content}
                        onChange={(e) =>
                          setUserMessage({
                            ...userMessage,
                            content: e.target.value,
                          })
                        }
                        onKeyDown={handleKeyPress}
                        placeholder="Send a message"
                        className="overflow-hidden px-2"
                        required
                      ></textarea>
                      <span
                        id="send-btn"
                        type="submit"
                        className="flex justify-center items-center"
                        onClick={handleSubmit}
                      >
                        <IoSend />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <ul
                className="chatbox !h-full"
                id="suggestions-container"
                ref={messagesEndRef}
              >
                {allMessages?.map((message, index) => {
                  return (
                    <>
                      {message.role === "assistant" ? (
                        <li className="chat incoming">
                          <span class="material-symbols-outlined">
                            <img
                              src={botDetails?.iconUrl}
                              alt="ico-mushgpt"
                              className="w-full"
                            />
                          </span>
                          <BotMessage
                            message={message.content}
                            messageIndex={index}
                            allMessages={allMessages}
                            questionId={message?.id && message?.id}
                            question={
                              message?.question ? message?.question : ""
                            }
                            refer={
                              message?.refer ? JSON.parse(message.refer) : null
                            }
                          />
                          {/* {isAppointment &&
                              allMessages.length - 1 === index && (
                                <Appointment />
                              )} */}
                        </li>
                      ) : (
                        <li className="chat outgoing ">
                          <span>
                            <img
                              src={
                                user?.attributes?.avatar
                                  ? user?.attributes?.avatar[0]
                                  : "https://chat.mushgpt.com/images/seeker.png"
                              }
                              alt="ico-mushgpt"
                              className="rounded-circle !h-[inherit] "
                            />
                          </span>
                          <p>{message.content}</p>
                        </li>
                      )}
                    </>
                  );
                })}
                {loading && <BotThinking />}
              </ul>{" "}
              <div className={`chatbot w-auto `}>
                {/* <div className="chat-header">
                  <div className="headTop flex justify-between items-center">
                    <div className="avatars-wrapper  operators-avatar-1 flex justify-start !w-full">
                      <div className="header-ava">
                        <img
                          src={botDetails ? botDetails?.iconUrl : ""}
                          alt="logo"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <h2 className="oneline ms-3 ">
                        <span>Chat with </span>
                        <br />
                        <span id="bot-name">
                          {botDetails ? botDetails?.botName + "!" : ""}
                        </span>
                      </h2>
                    </div>

                    <div className="actionIcon">
                      <span
                        className=" flex justify-center items-center cursor-pointer"
                        onClick={() => setIsChating(false)}
                      >
                        <IoIosArrowDown
                          size={25}
                          className="text-xl font-bold"
                        />
                      </span>
                    </div>
                  </div>

                  <div className="offline-message">
                    <span>
                      <span>We typically reply within a few minutes.</span>
                    </span>
                  </div>
                </div> */}

                <div>
                  <div className="chat-input ">
                    <ArrowPathIcon
                      width={32}
                      className=" cursor-pointer"
                      onClick={handleResetMessages}
                    />
                    <textarea
                      value={userMessage.content}
                      onChange={(e) =>
                        setUserMessage({
                          ...userMessage,
                          content: e.target.value,
                        })
                      }
                      onKeyDown={handleKeyPress}
                      placeholder="Send a message"
                      className="overflow-hidden px-2"
                      required
                    ></textarea>
                    <span
                      id="send-btn"
                      type="submit"
                      className="flex justify-center items-center"
                      onClick={handleSubmit}
                    >
                      <IoSend />
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Bot;
