import { Fragment, useState } from "react";
import "./formBuilder.css";
import { Checkbox, Typography } from "@material-tailwind/react";

const CheckBoxInput = ({ item, index, handleOptionValues }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (event) => {
    const { value, checked } = event.target;
    setSelectedValues((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
    handleOptionValues(item.id, "content", selectedValues);
  };

  return (
    <Fragment>
      <form className=" mb-2 w-full">
        <div className="mb-1 flex flex-col gap-6">
          <Typography variant="h6" color="blue-gray" className="-mb-3">
            {item.required && "* "}
            {index + 1 + ". " + item.value}
          </Typography>
          {item.options && item.options.length > 0 && (
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1">
              {item.options.map((opt, key) => (
                <div className="flex " key={opt.id}>
                  <Checkbox
                    name="content"
                    label={opt.value}
                    onChange={handleChange}
                    checked={item.content.includes(opt.value)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </form>
    </Fragment>
  );
};

export default CheckBoxInput;
