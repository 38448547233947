import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";

// ** Import MUI
import {
  Box,
  FormControl,
  TextField,
  FormLabel,
  // Select,
  // MenuItem,
  Autocomplete,
  Grid,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import moment from 'moment';

import MuiPhoneNumber from "material-ui-phone-number";

import type { FC } from "react";

import { updateProfile } from "../../store/auth/actions";
import { CountryType, countries } from "../../utils/countries";
import { StatesType, states } from "../../utils/state";
import { useMounted } from "../../hooks/use-mounted";
import AlertDeleteAccount from "../../pages/alertDeleteAccount";
import { handleUploadLogo } from "../../store/bot/actions";

const validationSchema = Yup.object({
  firstName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(255)
    .required("Required"),
  email: Yup.string().email("Must be a valid email").max(255).required(),
});

// ! Start Component
const ProfileSetting: FC = () => {
  const isMounted = useMounted();
  const dispatch = useDispatch();

  const { user } = useSelector((state: any) => state.auth);
  const [fileName, setFileName] = useState("Change Profile");
  const [isUploading, setIsUploading] = useState(false);
  const [previewAvatar, setPreviewAvatar] = useState<any>(
    user.avatar ? user.avatar : "/images/seeker.png"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [attributesData, setAttributesData] = useState<any>({
    phone_numbers: user?.attributes?.phone_number
      ? user?.attributes?.phone_number[0]
      : "",
    expertise: user?.attributes?.expertise ? user?.attributes?.expertise : "",
    theraupatic_approaches: user?.attributes?.theraupatic_approaches
      ? user?.attributes?.theraupatic_approaches
      : "",
    files: user?.attributes?.files ? user?.attributes?.files : "",
    avatar: user?.attributes?.avatar ? user?.attributes?.avatar[0] : "",
    address: user?.attributes?.address ? user?.attributes?.address[0] : "",
    country: user?.attributes?.country ? user?.attributes?.country[0] : "",
    state: user?.attributes?.state ? user?.attributes?.state[0] : "",
    zip: user?.attributes?.zip ? user?.attributes?.zip[0] : "",
  });

  const onUploadCallBack = ({ status, returnData }: any) => {
    if (status === "success") {
      setIsUploading(false);
      handleChangeAttributes("avatar", [returnData.data.imageUrl]);
      setFileName("Upload Successfully");
    } else {
      setIsUploading(false);
    }
  };

  const imageSelect = (e: any) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", e.currentTarget.files[0]);
    dispatch(handleUploadLogo(formData, onUploadCallBack));
    e?.currentTarget?.files[0] &&
      setPreviewAvatar(URL.createObjectURL(e.currentTarget.files[0]));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user.firstName,
      email: user.email,
      attributes: attributesData || {
        phone_number: "",
        files: "",
        avatar: [""],
        address: "",
        country: "",
        state: "",
        zip: "",
      },
    },
    validationSchema,
    onSubmit: async (values: any, helpers: any): Promise<void> => {
      setLoading(true);
      dispatch(updateProfile(values, helpers, setLoading));
    },
  });

  // const datePickerChange = (value: Date | null) => {
  //   formik.setFieldTouched("birthday", true);
  //   formik.setFieldValue("birthday", value, true);
  // };

  const onPhoneNumberChange = (c: any) => {
    setAttributesData((prev: any) => ({ ...prev, phone_number: c }));
  };

  const onChangeCountry = (event: any, value: string) => {
    // if (String(formik.values.country) !== value && isMounted()) {
    setAttributesData((prev: any) => ({ ...prev, country: value }));
  };

  const onChangeState = (event: any, value: string) => {
    setAttributesData((prev: any) => ({ ...prev, state: value }));
  };

  // const defaultCountry = (): CountryType => {
  // 	if (user.country) {
  // 		const country = countries.find((el) => el.label === user.country);
  // 		return {
  // 			code: country?.code || '',
  // 			label: country?.label || '',
  // 			phone: country?.phone || '',
  // 		};
  // 	}
  // 	const country = countries.find((el) => el.code === 'US');
  // 	return {
  // 		code: country?.code || '',
  // 		label: country?.label || '',
  // 		phone: country?.phone || '',
  // 	};
  // };
  const handleChangeAttributes = (name: any, value: any) => {
    setAttributesData((prev: any) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    const initialValues = () => {
      setPreviewAvatar(
        user?.attributes?.avatar ? user?.attributes?.avatar[0] : null
      );

      setAttributesData({
        phone_number: user?.attributes?.phone_number
          ? user?.attributes?.phone_number[0]
          : "",
        expertise: user?.attributes?.expertise
          ? user?.attributes?.expertise
          : "",
        theraupatic_approaches: user?.attributes?.theraupatic_approaches
          ? user?.attributes?.theraupatic_approaches
          : "",
        files: user?.attributes?.files ? user?.attributes?.files : "",
        avatar: user?.attributes?.avatar ? user?.attributes?.avatar[0] : "",
        address: user?.attributes?.address ? user?.attributes?.address[0] : "",
        country: user?.attributes?.country ? user?.attributes?.country[0] : "",
        state: user?.attributes?.state ? user?.attributes?.state[0] : "",
        zip: user?.attributes?.zip ? user?.attributes?.zip[0] : "",
        city: user?.attributes?.city ? user?.attributes?.city[0] : "",
      });
    };
    user && initialValues();
  }, [user]);
  return (
    <>
      <Box
        sx={{
          marginTop: "25px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            mt: "20px",
            mb: "10px",
          }}
        >
          <Avatar
            src={previewAvatar}
            sx={{
              height: 80,
              width: 80,
              margin: { lg: "0px 20px 0px 0px", xs: "0px auto 20px auto" },
              "& .MuiAvatar-img": {
                objectFit: "cover",
              },
            }}
          >
            <AccountCircleRoundedIcon fontSize="large" />
          </Avatar>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              className="hover:!text-[#000080]"
              size="small"
              color="primary"
              variant="outlined"
              component="label"
              sx={{ borderRadius: "9px" }}
            >
              {isUploading ? <CircularProgress /> : fileName}
              <input type="file" name="avatar" hidden onChange={imageSelect} />
            </Button>
          </Box>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Name</FormLabel>
              <TextField
                size="small"
                error={!!(formik.touched.firstName && formik.errors.firstName)}
                fullWidth
                helperText={
                  (formik.touched.firstName &&
                    formik.errors.firstName) as string
                }
                name="firstName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.firstName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Email</FormLabel>
              <TextField
                size="small"
                error={!!(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={
                  (formik.touched.email && formik.errors.email) as string
                }
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
                autoComplete="aa"
              />
            </FormControl>
          </Grid>
        </Grid>

        <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
          <FormLabel sx={{ marginBottom: "8px" }}>Phone number</FormLabel>
          <MuiPhoneNumber
            size="small"
            defaultCountry="us"
            variant="outlined"
            fullWidth
            name="phoneNumber"
            onBlur={formik.handleBlur}
            onChange={onPhoneNumberChange}
            type="text"
            value={attributesData?.phone_number}
          />
        </FormControl>

        {/* <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Address</FormLabel>
              <TextField
                size="small"
                fullWidth
                name="address"
                onBlur={formik.handleBlur}
                onChange={(e) =>
                  handleChangeAttributes("address", e.target.value)
                }
                value={attributesData?.address}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              />
            </FormControl>
          </Grid>
        </Grid> */}
        {/* <Grid container spacing={4}>
          <Grid item xs={12} lmg={6}>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>City</FormLabel>
              <TextField
                size="small"
                fullWidth
                name="city"
                onChange={(e) => handleChangeAttributes("city", e.target.value)}
                value={attributesData?.city}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              />
            </FormControl>
          </Grid>
          {isMounted() && (
            <Grid item xs={12} lmg={6}>
              <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
                <FormLabel sx={{ marginBottom: "8px" }}>State</FormLabel>
                <Autocomplete
                  size="small"
                  id="country-select-demo"
                  options={states}
                  defaultValue={
                    // {
                    // 	...defaultCountry(),
                    // }
                    attributesData?.state &&
                    states.find((el: any) => el.label === attributesData?.state)
                    // : states.find((el) => el.code === "3956")
                  }
                  autoHighlight
                  getOptionLabel={(option: StatesType) => option.label}
                  onInputChange={onChangeState}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="state"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} lmg={6}>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Zip</FormLabel>
              <TextField
                size="small"
                fullWidth
                name="zip"
                onChange={(e) => handleChangeAttributes("zip", e.target.value)}
                value={attributesData?.zip}
                type="text"
              />
            </FormControl>
          </Grid>
          {isMounted() && (
            <Grid item xs={12} lmg={6}>
              <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
                <FormLabel sx={{ marginBottom: "8px" }}>Country</FormLabel>
                <Autocomplete
                  id="country-select-demo"
                  options={countries}
                  defaultValue={
                    // {
                    // 	...defaultCountry(),
                    // }
                    user.country
                      ? countries.find((el: any) => el.label === user.country)
                      : countries.find((el: any) => el.code === "US")
                  }
                  autoHighlight
                  getOptionLabel={(option: CountryType) => option.label}
                  onInputChange={onChangeCountry}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
        </Grid> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column-reverse" },
            justifyContent: { sm: "space-between", xs: "start" },
            alignItems: "center",
          }}
        >
          <AlertDeleteAccount />
          <form noValidate onSubmit={formik.handleSubmit}>
            <LoadingButton
              loading={loading}
              size="small"
              sx={{
                mt: 2,
                borderRadius: "10px",
                height: "50.65px",
                mb: "20px",
                width: "205px",
              }}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default ProfileSetting;
