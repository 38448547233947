import React, { useEffect, useState } from "react";
import { Typography, Switch, Input, Button } from "@material-tailwind/react";
import { Divider } from "@mui/material";
import { useMutation } from "react-query";
import http from "../../services/http-common";
import { setSessionExpired, useMainController } from "../../context";
import { toast } from "react-toastify";

const DataControl = ({ handleOpen }) => {
  const dispatchHttp = useMainController();
  const [loading, setLoading] = useState(false);
  const [dataController, setDataController] = useState({
    autoRecharge: null,
    allowContentToBeUsedInTraining: null,
    tokensThreshold: null,
  });

  const updateData = useMutation(
    async (id) => {
      return await http.put(`setting/update/${id}`, dataController);
    },
    {
      onSuccess: ({ data }) => {
        setLoading(false);
        handleOpen();
        toast.success(data.message);
      },
      onError: (error) => {
        setLoading(false);
      },
    }
  );

  const handleSubmit = () => {
    setLoading(true);
    updateData.mutate(dataController?.id);
  };

  const getDataController = useMutation(
    () => {
      return http.get(
        "setting/find-by-user",
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: ({ data }) => {
        console.log(data);
        setDataController(data.setting);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
  const handleSwitchChange = (field) => {
    setDataController((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  const handleInputChange = (e) => {
    const { value } = e.target;
    setDataController((prevState) => ({
      ...prevState,
      tokensThreshold: value,
    }));
  };

  useEffect(() => {
    !dataController.tokensThreshold && getDataController.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className="w-full flex flex-col gap-4">
      <div className="w-full flex justify-between items-center ">
        <Typography
          variant="small"
          color="blue-gray"
          className="mb-2 font-medium"
        >
          Allow content to be used in training
        </Typography>
        <Switch
          onChange={() => handleSwitchChange("allowContentToBeUsedInTraining")}
          checked={dataController.allowContentToBeUsedInTraining}
        />
      </div>
      <Divider />
      <div className=" flex justify-between items-center ">
        <Typography
          variant="small"
          color="blue-gray"
          className="mb-2 font-medium"
        >
          Auto Recharge
        </Typography>
        <Switch
          onChange={() => handleSwitchChange("autoRecharge")}
          checked={dataController.autoRecharge}
          className="me-2"
        />
      </div>
      <Divider />

      <div className=" flex justify-between items-center ">
        <Typography
          variant="small"
          color="blue-gray"
          className="w-full mb-2 font-medium "
        >
          Tokens Threshold
        </Typography>

        <Input
          value={dataController.tokensThreshold}
          onChange={handleInputChange}
          type="number"
          containerProps={{
            className: "flex justify-end ",
          }}
          className="w-24 !border-t-blue-gray-200 focus:!border-t-gray-900"
          labelProps={{
            className: "before:content-none after:content-none",
          }}
        />
      </div>
      <Divider />

      <Button
        disabled={loading}
        onClick={handleSubmit}
        size="sm"
        className="rounded-md"
      >
        Update
      </Button>
    </form>
  );
};

export default DataControl;
