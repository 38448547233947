import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { httpMushPro } from "../../services/http-common";
import { setSessionExpired, useMainController } from "../../context";
import UserService from "../../KeyCloackServices/UserService";
import { saveBotStarters } from "../../store/dataFlow/actions";
import { toast } from "react-toastify";
import { XMarkIcon } from "@heroicons/react/24/outline";

const BotStarters = () => {
  const dispatch = useDispatch();
  const [dispatchHttps] = useMainController();
  const [botStarters, setBotStarters] = useState([{ question: "" }]);
  const [loading, setLoading] = useState(false);

  const getPrompt = useMutation(
    () => {
      return httpMushPro.get(
        "user/quick-replies/custom-bot",
        null,
        setSessionExpired,
        dispatchHttps
      );
    },
    {
      onSuccess: ({ data }) => {
        dispatch(saveBotStarters(data.quickReplies));
        setBotStarters([
          ...data.quickReplies.map((item) => ({
            question: item.question,
            id: item.id,
          })),
          { question: "", id: null },
        ]);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const getFaqs = useCallback(() => {
    getPrompt.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteFaq = useMutation(
    async (faqId) => {
      return await httpMushPro.deleteFaqs("user/quick-replies/delete", {
        userId: {
          id: faqId,
        },
      });
    },
    {
      onSuccess: () => {
        getFaqs();
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const onDeleteFaq = (id) => {
    deleteFaq.mutate(id);
  };
  useEffect(() => {
    getFaqs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveContent = useMutation(
    (value) => {
      return httpMushPro.post(
        `${
          UserService.getRole()?.roles?.includes("admin")
            ? "user/quick-replies/createForAdmin"
            : "user/quick-replies/createForPro"
        }`,
        {
          question: value,
        },
        null
      );
    },
    {
      onSuccess: (data) => {
        setBotStarters((prevBotStarters) => {
          const updatedBotStarters = [...prevBotStarters];
          updatedBotStarters[updatedBotStarters.length - 1].id =
            data.data.quickReplies?.id;
          updatedBotStarters.push({ question: "", id: null });
          return updatedBotStarters;
        });
        // getFaqs();
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const updateContent = useMutation(
    (data) => {
      return httpMushPro.put(
        `user/quick-replies/update`,
        {
          id: data.id,
          question: data.value,
        },
        null
      );
    },
    {
      onSuccess: ({ data }) => {
        getFaqs();
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    debounceSave(value);
  };

  const handleUpdateStarter = (index, event) => {
    const values = [...botStarters];
    values[index][event.target.name] = event.target.value;
    setBotStarters(values);

    if (values[index].id === null) {
      debounceSave(values[index][event.target.name]);
    } else {
      debounceUpdate({
        value: values[index][event.target.name],
        id: values[index].id,
      });
    }
  };

  const debounceUpdate = useCallback(
    debounce((data) => {
      updateContent.mutate(data);
    }, 1000),
    []
  );

  const debounceSave = useCallback(
    debounce((value) => {
      saveContent.mutate(value);
    }, 1000),
    []
  );

  const handleRemoveInput = (id) => {
    deleteFaq.mutate(id);
  };
  return (
    <>
      <div className="mt-1">
        <div className=" flex flex-col gap-1">
          {botStarters.map((input, index) => (
            <div key={index} className="flex items-center gap-2">
              <input
                type="text"
                name="question"
                value={input.question}
                onChange={(event) => handleUpdateStarter(index, event)}
                className="w-full text-sm overflow-y-auto rounded-lg border border-[#d9d9d9] px-3 py-2 focus:border focus:outline-1   "
                placeholder="Type your question here..."
              />
              {botStarters.length > 1 && input.id !== null && (
                <span
                  className="bg-danger border-none text-white p-0 cursor-pointer"
                  onClick={() => handleRemoveInput(input.id)}
                >
                  <XMarkIcon className="h-5 w-5 bg-white text-black" />
                </span>
              )}
            </div>
          ))}
        </div>{" "}
      </div>
    </>
  );
};

export default BotStarters;
