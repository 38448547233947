import React from "react";
import { LuUser } from "react-icons/lu";
import { Avatar, Typography } from "@material-tailwind/react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { IoSend } from "react-icons/io5";
import "./Bot.css";

const Preview = ({ botDetails }) => {
  return (
    <>
      <Typography
        variant="h5"
        className="sm:flex hidden  justify-center py-3 h-[42px]"
      >
        Preview
      </Typography>
      <div className="container h-full pb-14 relative pt-2">
        <div className="h-full pb-80 pl-5 flex justify-center items-center">
          <div>
            <div className="flex items-center justify-center pt-3">
              {botDetails?.iconUrl ? (
                <div className="flex flex-col justify-center items-center gap-2">
                  <Avatar
                    size="xl"
                    variant="circular"
                    alt="Bot logo"
                    className="border-2 border-white"
                    src={botDetails?.iconUrl}
                  />
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center gap-2">
                  <button
                    className="h-20 w-20 me-2"
                    type="button"
                    id="radix-:ri:"
                    aria-haspopup="menu"
                    aria-expanded="false"
                    data-state="closed"
                  >
                    <div class="flex h-full w-full items-center justify-center rounded-full border-2 border-dashed border-token-border-medium">
                      <LuUser className="text-4xl" />
                    </div>
                  </button>
                </div>
              )}
            </div>
            <div className="pb-4 flex flex-col justify-center items-center">
              <Typography variant="h4" color="blue-gray" className="mb-2">
                {botDetails?.botName}
              </Typography>
              <Typography
                color="blue-gray"
                className="font-medium w-10/12 text-center"
                textGradient
              >
                {botDetails?.primaryDomain}
              </Typography>
            </div>
            {/* <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-4">
              {questions.map((question, index) => (
                <div
                  key={index}
                  onClick={onQuestionClick(index)}
                  className="flex items-center justify-center cursor-pointer"
                >
                  <div className="w-48 h-min  rounded-xl border-2 border-gray-200">
                    <div className="text-pretty p-2">
                      <p className="text-sm text-center">{question}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
        </div>
        <div className="absolute bottom-20 !w-[inherit] pe-2 ">
          <div className={`chatbot  w-auto `}>
            <div className="chat-input border-[#d9d9d9] border">
              <ArrowPathIcon width={32} className=" cursor-not-allowed" />
              <textarea
                placeholder="Send a message"
                className="overflow-hidden px-2 !cursor-not-allowed"
                required
                disabled
              ></textarea>
              <span
                id="send-btn"
                type="submit"
                className="flex justify-center items-center"
              >
                <IoSend />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preview;
