import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import imageCompression from "browser-image-compression";
import {
  Avatar,
  Button,
  Input,
  Textarea,
  Alert,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  getBotDetails,
  handleUploadLogo,
  updateBot,
} from "../../store/bot/actions";
import BotStarters from "./BotStarters";
import BotTrainWebsites from "./BotTrainWebsites";
import BotAllDocs from "./BotAllDocs";

document.title = "Personality | Entheo.Pro by The Entheology Project";

const BotUpdates = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { botDetails } = useSelector((state) => state.bot);
  const [fileName, setFileName] = useState("Change Logo");
  const [isUploading, setIsUploading] = useState(false);
  const [previewAvatar, setPreviewAvatar] = useState(null);
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  useEffect(() => {
    dispatch(getBotDetails());
  }, [dispatch]);

  const selectLoginState = (state) => state.bot;
  const BotProperties = createSelector(selectLoginState, (bot) => ({
    error: bot.error,
    success: bot.success,
  }));

  const { error, success } = useSelector(BotProperties);

  useEffect(() => {
    botDetails?.iconUrl &&
      setPreviewAvatar(botDetails?.iconUrl ? botDetails?.iconUrl : null);
  }, [botDetails]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: botDetails?.id || "",
      uniqueName: botDetails?.uniqueName || "",
      footerNote: botDetails?.footerNote || "",
      botName: botDetails?.botName,
      primaryDomain: botDetails?.primaryDomain,
      LGBTQ: botDetails?.LGBTQ,
      iconUrl: botDetails?.iconUrl,
      greeting: botDetails?.greeting,
      tone: botDetails?.tone || "",
      additionalCertification: botDetails?.additionalCertification || "",
      specialInstruction: botDetails?.specialInstruction || "",
      theraupatic_approaches: [botDetails.theraupatic_approaches] || "",
      expertise: [botDetails.expertise] || "",
      experience: [botDetails.experience] || "",
      kcId: user?.id || "",
      submit: null,
    },
    onSubmit: async (values, helpers) => {
      dispatch(updateBot(values, helpers));
      setFileName("Upload Logo");
    },
  });

  const onUploadCallBack = ({ status, returnData }) => {
    if (status === "success") {
      setIsUploading(false);
      validation.setFieldTouched("iconUrl", true);
      validation.setFieldValue("iconUrl", returnData.data.imageUrl);
      setFileName("Upload Successfully");
    } else {
      setIsUploading(false);
      console.log(returnData.message);
    }
  };

  const imageSelect = async (e) => {
    setIsUploading(true);
    const imageFile = e.currentTarget.files[0];
    const formData = new FormData();

    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        console.log(` ${img.width}x${img.height} Pixels`);
      };
      img.src = event.target.result;
    };

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
      fileType: ".png",
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);

      formData.append("file", compressedFile);
      dispatch(handleUploadLogo(formData, onUploadCallBack));
      compressedFile && setPreviewAvatar(URL.createObjectURL(compressedFile));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="flex-flex-col">
        <div className="w-full user-chat mt-sm-0 ">
          {error && error ? (
            <Alert
              color="red"
              style={{ marginTop: "13px" }}
              isOpen={visible}
              dismissible
              className="flex justify-between items-center"
            >
              {error}
              <Button
                color="inherit"
                onClick={onDismiss}
                className="cursor-pointer"
              >
                <XMarkIcon width={"20px"} />
              </Button>
            </Alert>
          ) : null}
          {success ? (
            <Alert
              color="green"
              style={{ marginTop: "13px" }}
              isOpen={visible}
              dismissible
              className="flex justify-between items-center"
            >
              {success}
              <Button
                color="inherit"
                onClick={onDismiss}
                className="cursor-pointer"
              >
                <XMarkIcon width={"20px"} />
              </Button>
            </Alert>
          ) : null}
          <form noValidate onSubmit={validation.handleSubmit}>
            <div className="pb-12">
              <div class="flex w-full items-center justify-center gap-4">
                <button
                  className="h-20 w-20"
                  type="button"
                  id="radix-:r52:"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  data-state="closed"
                  data-testid="undefined-button"
                  onClick={() => document.getElementById("file-input").click()}
                >
                  <div className="flex relative h-full w-full items-center justify-center rounded-full border-2 border-dashed border-token-border-medium">
                    {previewAvatar ? (
                      <>
                        <img
                          className="h-full w-full rounded-full"
                          width="80"
                          height="80"
                          src={previewAvatar}
                          alt={botDetails?.botName}
                        />
                        {isUploading && (
                          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="text-center h-1/4 w-1/4 animate-spin"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <line x1="12" y1="2" x2="12" y2="6"></line>
                              <line x1="12" y1="18" x2="12" y2="22"></line>
                              <line
                                x1="4.93"
                                y1="4.93"
                                x2="7.76"
                                y2="7.76"
                              ></line>
                              <line
                                x1="16.24"
                                y1="16.24"
                                x2="19.07"
                                y2="19.07"
                              ></line>
                              <line x1="2" y1="12" x2="6" y2="12"></line>
                              <line x1="18" y1="12" x2="22" y2="12"></line>
                              <line
                                x1="4.93"
                                y1="19.07"
                                x2="7.76"
                                y2="16.24"
                              ></line>
                              <line
                                x1="16.24"
                                y1="7.76"
                                x2="19.07"
                                y2="4.93"
                              ></line>
                            </svg>
                          </div>
                        )}
                      </>
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-4xl"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 3C12.5523 3 13 3.44772 13 4L13 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13L13 13L13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20L11 13L4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11L11 11L11 4C11 3.44772 11.4477 3 12 3Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    )}
                  </div>
                </button>
                <input
                  accept="image/png,.png,image/jpeg,.jpg,.jpeg,image/gif,.gif,image/webp,.webp"
                  multiple=""
                  tabIndex="-1"
                  type="file"
                  className="hidden"
                  id="file-input"
                  onChange={imageSelect}
                />
              </div>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Assistant’s Name
                  </label>{" "}
                  <div className="mt-1">
                    <input
                      id="botName"
                      name="botName"
                      type="text"
                      className="w-full text-sm overflow-y-auto rounded-lg border border-[#d9d9d9] px-3 py-2 focus:border focus:outline-1   "
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.botName || ""}
                      error={
                        validation.touched.botName &&
                        Boolean(validation.errors.botName)
                      }
                      helperText={
                        validation.touched.botName && validation.errors.botName
                      }
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    About the Coach
                  </label>
                  <div className="mt-1">
                    <textarea
                      name="primaryDomain"
                      placeholder="Expert in mushroom microdosing, plant medicines, kambo, somatic therapy, and ibogaine."
                      className="w-full text-sm overflow-y-auto rounded-lg border border-[#d9d9d9] px-3 py-2 focus:border focus:outline-1"
                      defaultValue={""}
                      multiline
                      rows={3}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.primaryDomain || ""}
                      error={
                        validation.touched.primaryDomain &&
                        Boolean(validation.errors.primaryDomain)
                      }
                      helperText={
                        validation.touched.primaryDomain &&
                        validation.errors.primaryDomain
                      }
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Instructions
                  </label>{" "}
                  <div className="mt-1">
                    <textarea
                      name="specialInstruction"
                      rows={3}
                      placeholder="Please do not share private information. For contact info or appointments, just ask!"
                      className="w-full text-sm overflow-y-auto rounded-lg border border-[#d9d9d9] px-3 py-2 focus:border focus:outline-1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.specialInstruction || ""}
                      error={
                        validation.touched.specialInstruction &&
                        Boolean(validation.errors.specialInstruction)
                      }
                      helperText={
                        validation.touched.specialInstruction &&
                        validation.errors.specialInstruction
                      }
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Greeting
                  </label>{" "}
                  <div className="mt-1">
                    <textarea
                      name="greeting"
                      placeholder="How can I help you, Seeker?"
                      rows={2}
                      className="w-full text-sm overflow-y-auto rounded-lg border border-[#d9d9d9] px-3 py-2 focus:border focus:outline-1  "
                      disabled
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.greeting || ""}
                      error={
                        validation.touched.greeting &&
                        Boolean(validation.errors.greeting)
                      }
                      helperText={
                        validation.touched.greeting &&
                        validation.errors.greeting
                      }
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Conversation starters
                  </label>{" "}
                  <BotStarters />
                </div>
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Documents
                  </label>
                  <BotAllDocs />
                </div>
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Websites
                  </label>
                  <BotTrainWebsites />
                </div>
              </div>
              <div className="col-span-full">
                <div className="flex mt-3 w-full justify-end items-center">
                  <Button type="submit">Save</Button>{" "}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BotUpdates;
