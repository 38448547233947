import { useRef, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

// ** Import Type
import { FC } from "react";
import { Message } from "../../types/message";
import "./style.css";

// ** Import Child Components
import { HumanMessage, BotMessage, BotThinking } from "./message";
import { getAnswerAgain } from "../../store/messages/actions";
import { Box, IconButton } from "@mui/material";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import { getBotAnswerAgain, getBotChatHistory } from "../../store/bot/actions";

interface ContentContainerProps {
  showSuggestions: boolean;
}

const ContentContainer: FC<ContentContainerProps> = ({ showSuggestions }) => {
  const { botDetails } = useSelector((state: any) => state.bot);
  const dispatch = useDispatch();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const { loading, end, botMessages, answerError } = useSelector(
    (state: any) => state.bot
  );
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const Messages = useMemo(
    () =>
      botMessages.map((_item: Message, index: number) => {
        const key = _item.id || index;
        if (_item.sender === "human") {
          return <HumanMessage key={key} message={_item.message} />;
        } else {
          return (
            <>
              <BotMessage
                showSuggestions={showSuggestions}
                questionId={_item?.id && _item?.id}
                key={key}
                model={_item?.model ? _item?.model : null}
                tokens={_item?.tokens ? _item?.tokens : null}
                question={_item?.question ? _item?.question : ""}
                trigger={_item?.intent ? _item?.intent : ""}
                messageIndex={index}
                message={_item.message}
                feedback={_item.feedback}
                refer={_item.refer}
              />
            </>
          );
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [botMessages]
  );

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    setIsMounted(true);
    dispatch(getBotChatHistory(botDetails?.id));
    // !botMessages.length && dispatch(getBotChatHistory(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getBotChatHistory(botDetails?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botDetails]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [isMounted, loading]);

  const answerAgainHandler = () => {
    dispatch(
      getBotAnswerAgain({
        llm: localStorage.getItem("model")?.toLocaleLowerCase(),
        botId: botDetails?.id,
        question: botMessages[0].message,
      })
    );
  };

  return (
    <InfiniteScroll
      dataLength={botMessages.length}
      next={() => {
        !end && dispatch(getBotChatHistory(botDetails?.id));
      }}
      style={{ display: "flex", flexDirection: "column-reverse" }}
      inverse={true}
      hasMore={true}
      loader={!end && <h4 style={{ textAlign: "center" }}>Loading...</h4>}
      scrollableTarget="scrollableDiv"
    >
      <div ref={messagesEndRef}></div>
      {answerError && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            aria-label="Refresh"
            sx={{ border: "2px solid #A2A5C6", mr: "20px" }}
            onClick={answerAgainHandler}
          >
            <RefreshRoundedIcon />
          </IconButton>
          Please request again.
        </Box>
      )}
      {loading && <BotThinking />}
      {Messages}
    </InfiniteScroll>
  );
};

export default ContentContainer;
