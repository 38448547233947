export const formatResponse = (text) => {
  // text = text?.replace(/(\d+\.[^\d])/g, "<br>$1");

  // Replace underscores with spaces
  text = text?.replace(/_/g, " ");

  // Convert bold text with Tailwind CSS
  text = text?.replace(
    /\\*"(.*?)\\*"/g,
    '<strong class="font-semibold">$1</strong>'
  );
  // text = text?.replace(/[\\]?'(.*?)[\\?]'/g, '<strong class="font-semibold">$1</strong>');

  // - **Text**:
  text = text?.replace(
    // /(\d+\.\s[^-]+-|\d+\.\s\*\*[\w\s\-]+?\*\*:)/g,
    // eslint-disable-next-line no-useless-escape
    /(-\s)(\d+\.\s)?\*\*([^\*]+?):?\*\*:?/g,
    '<li class="list-disc list-inside font-bold"> $3</li>'
  );
  text = text?.replace(
    /(<li class="list-disc list-inside font-bold">.*<\/li>)/g,
    '<ol class="mb-4">$1</ol>'
  );

  // - *Italic Text*:
  text = text?.replace(
    // /(\d+\.\s[^-]+-|\d+\.\s\*\*[\w\s\-]+?\*\*:)/g,
    // eslint-disable-next-line no-useless-escape
    /(-\s)(\d+\.\s)?\*([^\*]+?):?\*:?/g,
    '<i class="font-bold"> $3</i>'
  );

  // Convert headings with Tailwind CSS
  text = text?.replace(
    // /^(\d+)\.\s\*\*(.*?)\*\*:/gm,
    /(?<!^-\s)(\d+\.\s)?\*\*([^*]+?):?\*\*:?/g,
    '<h2 class="text-lg font-bold my-4">$1 $2</h2>'
  );

  // Replace underscores with spaces
  text = text?.replace(/-\s/g, "");

  // Replace email addresses with mailto: anchor tags
  text = text?.replace(
    /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g,
    '<a href="mailto:$1" style="color: #000080;font-weight:500;">$1</a>'
  );

  // Replace [Text](URL) pattern  with anchor tag
  text = text?.replace(
    /\[(.*?)\]\((.*?)\)/g,
    '<a style="color:#000080; font-weight:300" href="$2">$1</a>'
  );

  text = text?.replace(
    /[\s+](\d+\.[^\d])/g,
    `<br><span style="font-weight:bold">$1</span>`
  );

  // Replace # with nothing
  text = text?.replace(/###/g, "");
  text = text?.replace(/##/g, "");
  // // // Convert ordered lists with Tailwind CSS only for lines starting with "-" but not followed by "**"
  // text = text?.replace(
  //   /\[\d\] \*\*(.*?)\*\*:/g,
  //   '<li class="list-decimal list-inside font-bold">$1</li>'
  // );
  // text = text?.replace(
  //   /(<li class="list-decimal list-inside ">.*<\/li>)/g,
  //   '<ol class="mb-4">$1</ol>'
  // );

  // // Enlist details
  // text = text?.replace(/\d+\. (".*") (by .*)$/g, "- $1 $2");

  // // Convert bold text with Tailwind CSS
  // text = text?.replace(
  //   /\*\*(.*?)\*\*/g,
  //   '<strong class="font-semibold">$1</strong>'
  // );

  // Convert bold text with Tailwind CSS
  // text = text?.replace(/(\- +)/g, "<br>");

  // // Replace ### with bold headings
  // text = text?.replace(
  //   /### (.*?)(\n|$)/g,
  //   `<h2 class="text-lg font-bold my-4">$1</h2>\n`
  // );

  // // Convert bold text with Tailwind CSS
  // text = text?.replace(
  //   /\*\*(.*?)\*\*/g,
  //   '<strong class="font-semibold">$1</strong>'
  // );

  // // Convert italic text with Tailwind CSS
  // text = text?.replace(/\*(.*?)\*/g, '<em class="italic">$1</em>');

  // // Convert unordered lists with Tailwind CSS only for lines starting with "-" but not followed by "**"
  // text = text?.replace(
  //   /- (?!\*\*)(.*?)$/gm,
  //   '<li class="list-disc list-inside ml-4">$1</li>'
  // );
  // text = text?.replace(
  //   /(<li class="list-disc list-inside ml-4">.*<\/li>)/g,
  //   '<ul class="mb-4">$1</ul>'
  // );

  // // Convert blockquotes with Tailwind CSS
  // text = text?.replace(
  //   /^> (.*?)$/gm,
  //   '<blockquote class="border-l-4 border-gray-500 pl-4 italic my-4">$1</blockquote>'
  // );

  // // Convert code blocks with Tailwind CSS
  // text = text?.replace(
  //   /```(.*?)```/gs,
  //   '<pre class="bg-gray-100 p-4 rounded my-4"><code>$1</code></pre>'
  // );

  // // Convert inline code with Tailwind CSS
  // text = text?.replace(
  //   /`(.*?)`/g,
  //   '<code class="bg-gray-200 px-1 rounded">$1</code>'
  // );

  // Return formatted text
  return text;
};
