import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  Breadcrumbs,
  Divider,
  IconButton,
  Drawer,
  Chip,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SideBar from "./sidebar";
// ** Import type
import { FC } from "react";
import SelectModel from "./Header/SelectModel";
import { useSelector } from "react-redux";
import GPTSettings from "./Header/GPTSettings";
import { SettingsModal } from "./Header/SettingsModal";

const Header: FC = () => {
  const { botDetails } = useSelector((state: any) => state.bot);
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  useEffect(() => {
    setOpenDrawer(false);
  }, [location]);

  const breadcrumbs = [
    <Typography
      key="2"
      color="text.default"
      sx={{ textTransform: "capitalize" }}
    >
      {location.pathname.includes("chat") ? (
        <span className="flex justify-start items-center gap-2">
          EntheoAI
          {/* {location.pathname.includes('law') ? 'Law' : location.pathname.includes('wisdom') ? 'Wisdom' : 'Science'}  */}
          {/* <Chip
            variant="outlined"
            size="small"
            label={'12,262 documents'}
            className="w-[min-content] normal-case !bg-[#000080] !text-white rounded-full "
          />
           */}
          {/* <SelectModel /> */}
        </span>
      ) : (
        <>
          {location.pathname.includes("/g/") ? (
            <span className="flex justify-start items-center gap-2">
              {botDetails?.botName}
              <GPTSettings />
            </span>
          ) : location.pathname.includes("create-bot") ? (
            ""
          ) : (
            location.pathname.replace("/", "").replace("_", " ")
          )}
        </>
      )}
    </Typography>,
  ];

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          // paddingTop: "40.6px",
          // paddingBottom: "16.6px",
          backgroundColor: "background.default",
        }}
      >
        <Grid
          item
          sx={{ display: { xs: "none", lg: "flex" } }}
          alignItems="center"
        ></Grid>
        <Grid
          item
          sx={{ display: { xs: "flex", lg: "none" } }}
          alignItems="center"
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mx: 0, mb: 0, display: { xs: "flex", lg: "none" } }}
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            {/* <MenuIcon /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M3 8a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm0 8a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1z"
              />
            </svg>
          </IconButton>
          <Drawer
            anchor={"left"}
            open={openDrawer}
            sx={{
              display: { xs: "flex", lg: "none" },
              "& .MuiDrawer-paper": {
                backgroundColor: "transparent",
              },
            }}
            onClose={() => {
              setOpenDrawer(false);
            }}
          >
            <IconButton
              sx={{
                position: "fixed",
                top: "10px",
                left: "calc(80vw - 50px)",
                zIndex: 9999,
              }}
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
            <SideBar mode="drawer" />
          </Drawer>
        </Grid>
        <Grid item sx={{ display: "flex" }} alignItems="center">
          {location.pathname !== "/gpts" && (
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              className="!mt-[10px]"
            >
              {breadcrumbs}
            </Breadcrumbs>
          )}
        </Grid>
        <Grid
          item
          sx={{ display: "flex", marginTop: "10px" }}
          alignItems="center"
        >
          <SettingsModal />
          {/* <SettingsMenu /> */}
        </Grid>
        {/* {location.pathname.includes("/g/") && (
          <Grid item sx={{ display: "flex" }} alignItems="center">
            <GPTSettings />
          </Grid>
        )} */}
      </Grid>
      {location.pathname !== "/gpts" && (
        <Divider sx={{ border: "2px solid background.default" }} />
      )}
    </>
  );
};

export default Header;
