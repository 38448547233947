export function timeAgo(date) {
  const now = new Date();
  const past = new Date(date);
  const secondsAgo = Math.floor((now - past) / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const [key, value] of Object.entries(intervals)) {
    const count = Math.floor(secondsAgo / value);
    if (count > 0) {
      return `${count} ${key}${count !== 1 ? "s" : ""} ago`;
    }
  }

  return "just now";
}

export function dateFormatForInput(isoDate) {
  // Create a new Date object from the ISO string
  const date = new Date(isoDate);

  // Get the year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
  const day = String(date.getDate()).padStart(2, "0");

  // Return the formatted date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
}
export const dateFormat = (inputDate) => {
  const dateObject = new Date(inputDate);

  const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
  const year = dateObject.getFullYear() % 100; // Get last two digits of the year
  const formattedDate = `${month.toString().padStart(2, "0")}/${year
    .toString()
    .padStart(2, "0")}`;

  return formattedDate;
};
