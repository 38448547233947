import { Fragment, useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import uuid from "react-uuid";
import Nestable from "react-nestable";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
//Form Elements
import { RadioInput } from "./elements";
//Components
import CheckBoxInput from "./elements/CheckBoxInput";
import DropdownInput from "./elements/DropdownInput";
import { dropdownOptionsForm } from "./dropdownOptionsForm";
import SliderInput from "./elements/SliderInput";
import { useMutation } from "react-query";
import { httpMushPro } from "../../services/http-common";
import { useParams } from "react-router-dom";
import { setSessionExpired, useMainController } from "../../context";
import { toast } from "react-toastify";
import TextFieldNew from "./elements/TextFieldNew";
import Footer from "../home/Footer";
import Header from "../home/Header";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000080",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Poppins", "Inter", "sans-serif"].join(","),
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: ".7vw",
          width: "10vw",
          fontSize: "1vw",
          fontWeight: "700",
          textAlign: "center",
          color: "#FFFFFF",
          textTransform: "none",
          borderRadius: "50vw",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "50vw",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        paragraph: true,
        mb: "1.5vw",
      },
    },
  },
});

const SeekerForm = () => {
  const { formId } = useParams();
  const [dispatch] = useMainController();
  const [formData, setFormData] = useState(null);

  //State
  const [title, setTitle] = useState("Untitled Form");
  const [description, setDescription] = useState("");
  const [data, setData] = useState([]);
  const [items, setItems] = useState(data);

  //Function to handle sorting of elements
  const handleOnChangeSort = ({ items }) => {
    setData(items);
  };

  //Function to Handle Input Values
  const handleValue = (id, e) => {
    let newArr = data.map((el) => {
      if (el.id === id) {
        return { ...el, [e.target.name]: e.target.value };
      } else {
        return el;
      }
    });
    setData(newArr);
    setItems(newArr);
  };
  //Function to Handle Input chechbox Values
  const handleOptionValues = (id, name, value) => {
    let newArr = data.map((el) => {
      if (el.id === id) {
        return { ...el, [name]: value };
      } else {
        return el;
      }
    });
    setData(newArr);
    setItems(newArr);
  };

  const filterByValue = (value) => {
    return dropdownOptionsForm.filter((option) => option.value === value);
  };
  //Function to Change Option Values
  const handleDefaultOptionValues = (elId, values, setLoading) => {
    let selectedDataOptions = filterByValue(values)[0].options;
    console.log(selectedDataOptions);

    let newArr = data.map((el) => {
      if (el.id === elId) {
        // Update existing options and add new ones if necessary
        selectedDataOptions.forEach((optValue, idx) => {
          if (el.options[idx]) {
            el.options[idx].value = optValue;
          } else {
            el.options = [...el.options, { id: uuid(), value: optValue }];
          }
        });

        // Remove extra options if selectedDataOptions has fewer values
        if (el.options.length > selectedDataOptions.length) {
          el.options = el.options.slice(0, selectedDataOptions.length);
        }

        console.log(el.options);
        return el;
      } else {
        return el;
      }
    });
    setData(newArr);
  };

  const updateForm = useMutation(
    () => {
      return httpMushPro.post("user/seeker-form/submit", {
        formId: formData.id,
        title: title,
        description: description,
        formData: data,
      });
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Form Submited Successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
  const handleSubmit = () => {
    updateForm.mutate();
  };

  //Render items
  const renderElements = ({ item, index }) => {
    switch (item.type) {
      case "text":
        return (
          <TextFieldNew item={item} index={index} handleValue={handleValue} />
        );
      case "checkbox":
        return (
          <CheckBoxInput
            item={item}
            index={index}
            handleOptionValues={handleOptionValues}
          />
        );
      case "dropdown":
        return (
          <DropdownInput
            item={item}
            index={index}
            handleDefaultOptionValues={handleDefaultOptionValues}
            handleValue={handleValue}
          />
        );
      case "radio":
        return (
          <RadioInput item={item} index={index} handleValue={handleValue} />
        );
      case "slider":
        return (
          <SliderInput item={item} index={index} handleValue={handleValue} />
        );

      default:
        return <Fragment></Fragment>;
    }
  };

  const getForm = useMutation(
    () => {
      return httpMushPro.get(
        `user/seeker-form/find-by-id/?id=${formId}`,
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        setTitle(data.records.title);
        setDescription(data.records.description);
        setData(data.records.formData);
        setItems(data.records.formData);
        setFormData(data.records);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  useEffect(() => {
    formId && getForm.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <>
        <div className="container mx-auto p-4">
          <div className="flex flex-col items-center">
            {items.length !== 0 && (
              <Card className="w-full">
                <CardHeader className="shadow-none " floated={false}>
                  <Typography variant="h4" color="blue-gray" className="mb-2">
                    {title}
                  </Typography>
                  <Typography
                    color="blue-gray"
                    className="font-medium"
                    textGradient
                  >
                    {description}
                  </Typography>
                  <hr className="mt-2" />
                </CardHeader>
                <CardBody className="">
                  <Nestable
                    disableDrag={true}
                    items={items}
                    renderItem={renderElements}
                    maxDepth={1}
                    onChange={handleOnChangeSort}
                  />
                </CardBody>
                <CardFooter className="flex justify-end gap-7 pt-2">
                  <Button onClick={handleSubmit}>Submit Form</Button>
                </CardFooter>
              </Card>
              // <Card className="w-full p-4">
              //   <CardHeader color="blue" className="mb-2">
              //     <Typography variant="h4" color="blue-gray" className="mb-2">
              //       {title}
              //     </Typography>

              //     <Typography variant="subtitle1" color="white">
              //       {description}
              //     </Typography>
              //   </CardHeader>
              //   <CardBody>
              // <Nestable
              //   disableDrag={true}
              //   items={items}
              //   renderItem={renderElements}
              //   maxDepth={1}
              //   onChange={handleOnChangeSort}
              // />
              //   </CardBody>
              //   <CardFooter className="pt-0">
              //     <div className="flex justify-end">
              //       <Button color="blue" onClick={handleSubmit}>
              //         Submit Form
              //       </Button>
              //     </div>
              //   </CardFooter>
              // </Card>
            )}
          </div>
        </div>
      </>
      <Footer />
    </ThemeProvider>
  );
};

export default SeekerForm;
