import { Grid, Typography, Box, Container, Button } from "@mui/material";

import useStyles from "../styles/styles";

import Shape from "../components/Shape";
import { Link } from "react-router-dom";

const EntheoGPTPlusPlan = () => {
  // const navigate = useNavigate();
  const classes = useStyles();

  const clases = {
    header2: {
      fontSize: { xs: "7vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: { md: "3.63vw", xs: "0" },
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    header4: {
      fontSize: { xs: "7.63vw", md: "2.08vw !important" },
      fontWeight: "600 !important",
      // letterSpacing: "-1.33px !important",
      color: "#101010 !important",
      paddingY: { md: "0", xs: "0" },
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      my: { xs: "5vw", md: "1vw" },
      // textAlign: { xs: 'center', md: 'left' },
    },
    buttonSx: {
      width: { md: "18vw", xs: "78.63vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
      my: { xs: "9vw", md: "0" },
    },
  };

  return (
    <>
      <Grid
        className={classes.sectionBox}
        id="gptStore"
        sx={{ background: "#f7f9ff" }}
      >
        <Shape secNumber="6" />

        <Container>
          <Grid
            container
            className={classes.containerGrid}
            sx={{
              paddingBottom: "2vw",
              pt: { xs: "17.3vw", md: "3.07vw" },
            }}
          >
            <Grid item className={classes.flexCenter} xs={11} md={12}>
              <Typography sx={clases.header3} className="text-center">
                EntheoGPT+ Plan <br /> $22/month or $240 annually (save $24)
              </Typography>
            </Grid>
            <Box
              className={classes.flexCenter}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Grid item>
                <Box>
                  <Typography sx={clases.contentText}>
                    For seekers wanting more access to knowledge and support,
                    the <span className="font-bold">EntheoGPT+</span> plan
                    offers increased tokens, research, and downloadable
                    resources.
                  </Typography>
                  <ul className="list-disc px-3">
                    <li>
                      <Typography sx={clases.contentText}>
                        <span className="font-bold">Tokens</span>: 150,000 per
                        month
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={clases.contentText}>
                        <span className="font-bold">Average Questions</span>:
                        30-300 questions (depending on complexity)
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={clases.contentText}>
                        <span className="font-bold">Max References</span>: Up to
                        5 references per question
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={clases.contentText}>
                        <span className="font-bold">PsiloScreen Intake</span>:
                        Continue evaluating your readiness for therapy
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={clases.contentText}>
                        <span className="font-bold">Scientific Documents</span>:
                        Save and download research for offline review
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={clases.contentText}>
                        <span className="font-bold">
                          Priority Email Support
                        </span>
                        : Get quicker responses when needed
                      </Typography>
                    </li>
                  </ul>
                  <Typography sx={clases.contentText}>
                    <span className="font-bold">Perfect for</span>: Seekers
                    wanting to dive deeper into their research with more tokens
                    and access to additional support.
                  </Typography>
                </Box>
              </Grid>
            </Box>
            <Box
              className={classes.flexCenter}
              sx={{
                gap: "2vw",
                p: { md: "1vw", xs: "5vw" },
              }}
            >
              <Link to="http://entheogpt.com/pay">
                <Button
                  variant="contained"
                  color="primary"
                  sx={clases.buttonSx}
                >
                  Upgrade Now!
                </Button>
              </Link>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default EntheoGPTPlusPlan;
