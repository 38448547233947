import { Routes as CommonRoutes, Navigate, Route } from "react-router-dom";

// ** Import pages
import Profile from "./pages/profile";
import Help from "./pages/help";
import Dedicate from "./pages/dedicate";
import { TrainBook, TrainWebContent } from "./pages/adminDashboard/train";

import Layout from "./layouts";
import AuthProvider from "./layouts/auth-provider";

// ** import type
import type { FC, ReactNode } from "react";
import VerifyPage from "./pages/verifyPage";
import NotVerifyPage from "./pages/notverifyPage";
import { Users } from "./pages/adminDashboard";
import { OtherAdmin } from "./pages/Personality/Admin/other";
import BotSuggestions from "./pages/Embedding/BotSuggestions";
import Integrations from "./pages/Embedding/Integrations";
import GptsStore from "./pages/GptStore/GptsStore";
import ChatGpts from "./pages/GptStore/ChatGpts";
import Home from "./pages";
import TermsOfServices from "./pages/TermsOfServices";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import UserService from "./KeyCloackServices/UserService";
import SuccessSubscription from "./components/sidebar/Subscription/SuccessSubscription";
import PricingTable from "./pages/home/Pricing/PricingTable";
import { plansDetails } from "./utils/PlanDetails";
import CouponManagement from "./pages/Coupon Management/CouponManagement";
import SeekerForm from "./pages/SeekerForm/SeekerForm";
import EntheoGPT from "./pages/EntheoGPT";
import EntheoLaw from "./pages/EntheoLaw";
import EntheoWisdom from "./pages/EntheoWisdom";
import AutoRegisterBot from "./pages/Personality Bot";

interface MainLayoutProps {
  children?: ReactNode;
}

const Wrapper: FC<MainLayoutProps> = ({ children }) => {
  return (
    <AuthProvider>
      <Layout>{children}</Layout>
    </AuthProvider>
  );
};

const Routes = () => {
  return (
    <CommonRoutes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      <Route path="/terms-of-service/" element={<TermsOfServices />}></Route>
      <Route path="/submit-form/:formId" element={<SeekerForm />}></Route>
      <Route
        path="/pay"
        element={
          <Wrapper>
            <PricingTable />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/my-bot/create-bot"
        element={
          <Wrapper>
            <AutoRegisterBot />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/gpts"
        element={
          <Wrapper>
            <GptsStore />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/g/:uniqueName/*"
        element={
          <Wrapper>
            <ChatGpts />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/chat"
        element={
          <Wrapper>
            <EntheoGPT />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/data"
        element={
          <Wrapper>
            <Dedicate />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/documents"
        element={
          UserService.getRole()?.roles?.includes("admin") ? (
            <Wrapper>
              <TrainBook />
            </Wrapper>
          ) : (
            <Navigate to="/chat" />
          )
        }
      ></Route>

      <Route
        path="/system-settings"
        element={
          UserService.getRole()?.roles?.includes("admin") ? (
            <Wrapper>
              <OtherAdmin />
            </Wrapper>
          ) : (
            <Navigate to="/chat" />
          )
        }
      ></Route>
      <Route
        path="/integration"
        element={
          <Wrapper>
            <Integrations />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/coupon-management"
        element={
          <Wrapper>
            <CouponManagement />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/suggestions"
        element={
          <Wrapper>
            <BotSuggestions />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/websites"
        element={
          UserService.getRole()?.roles?.includes("admin") ? (
            <Wrapper>
              <TrainWebContent />
            </Wrapper>
          ) : (
            <Navigate to="/chat" />
          )
        }
      ></Route>
      <Route
        path="/users"
        element={
          UserService.getRole()?.roles?.includes("admin") ? (
            <Wrapper>
              <Users />
            </Wrapper>
          ) : (
            <Navigate to="/chat" />
          )
        }
      ></Route>
      <Route
        path="/profile"
        element={
          <Wrapper>
            <Profile />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/success/*"
        element={
          <Wrapper>
            <SuccessSubscription />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/help"
        element={
          <Wrapper>
            <Help />
          </Wrapper>
        }
      ></Route>
      <Route path="/verify/:id/:token" element={<VerifyPage />}></Route>
      <Route
        path="/notverify"
        element={
          <AuthProvider>
            <NotVerifyPage />
          </AuthProvider>
        }
      ></Route>
      <Route path="*" element={<Navigate to="/" />} />
    </CommonRoutes>
  );
};
export default Routes;
