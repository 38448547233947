import { Box, Button, IconButton } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import { StopCircleIcon } from "@heroicons/react/24/outline";
import FilterSearch from "./components/chat/FilterSearch";
import ContentEditable from "react-contenteditable";
import {
  BotMessage,
  BotThinking,
  HumanMessage,
} from "./components/chat/message";

const ChatUI = () => {
  const [isMaximizedChat, setIsMaximizedChat] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { sidebarWeb } = useSelector((state) => state.dataFlow);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const chatMessagesRef = useRef(null);
  const socketRef = useRef(null);
  const inputBoxRef = useRef(null);

  useEffect(() => {
    // Initialize WebSocket connection
    socketRef.current = new WebSocket("ws://3.85.250.112:9005");

    socketRef.current.onopen = () => {
      // Request chat history on connection

      socketRef.current.send(
        JSON.stringify({
          type: "get_chat_history",
          kcId: user.id,
          finalId: "last",
          fName: user.firstName,
        })
      );
    };

    socketRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "chat_history") {
        setMessages(
          message.data.map((msg) => ({
            id: msg.id,
            content: msg.content,
            role: msg.role,
            intent: msg?.intent,
            model: msg.llm,
            tokens: msg.tokens,
            feedback: msg.feedback,
            question: msg.question,
            refer: JSON.parse(msg.refer ? msg.refer : "[]"),
          }))
        );
      } else if (message.type === "bot_message") {
        setIsStreaming(false);
        appendStreamMessage(message.content);
      } else if (message.type === "chat_end") {
        // Reset for the next message
        setMessages((prevMessages) => [...prevMessages]);
      } else if (message.type === "saved-messages") {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          if (updatedMessages.length > 0) {
            updatedMessages[updatedMessages.length - 1] = {
              content: message.messages[1].content,
              feedback: message.messages[1].feedback,
              id: message.messages[1].id,
              isApproved: message.messages[1].isApproved,
              kcId: message.messages[1].kcId,
              refer: JSON.parse(message.messages[1].refer),
              role: "assistant",
            };
          }
          return updatedMessages;
        });
      }
    };

    // socketRef.current.onclose = () => {
    //   console.log("Disconnected from server");
    // };

    return () => {
      // socketRef.current.close();
    };
  }, []);

  const sendMessage = () => {
    const message = inputValue.trim();
    if (message) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: message, role: "user" },
      ]);
      setIsStreaming(true);
      socketRef.current.send(
        JSON.stringify({
          kcId: user.id,
          prompt: message,
          model: "dolphin-llama3",
          fName: user.firstName,
        })
      );
      setInputValue("");
      startBotResponse();
    }
  };

  const startBotResponse = () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { content: "", role: "bot" },
    ]);
  };
  const handleChange = (evt) => {
    // const value = evt.target.value.replace(/<[^>]+>/g, "");
    const value = evt.target.value.replace(/<\/?[^>]+(>|$)/g, "");
    setInputValue(value);
  };

  const appendStreamMessage = (content) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      if (lastMessage.role === "bot") {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = {
          ...lastMessage,
          content: lastMessage.content + content,
        };
        return updatedMessages;
      }
      return prevMessages;
    });
  };

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <>
      <Box
        id="scrollableDiv"
        className="max-h-[calc(100vh-230px)]  md:max-h-[calc(100vh-220px)] lg:max-h-[calc(100vh-205px)] xl:max-h-[calc(100vh-205px)] "
        sx={{
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column-reverse",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <div
          ref={chatMessagesRef}
          className="flex-1 p-3 overflow-y-auto flex flex-col space-y-2"
        >
          {messages.map((_item, index) => {
            if (_item.role === "user") {
              return <HumanMessage key={index} message={_item.content} />;
            } else {
              return (
                <>
                  {index === messages.length - 1 && isStreaming ? (
                    <BotThinking />
                  ) : (
                    <BotMessage
                      questionId={_item?.id && _item?.id}
                      key={index}
                      model={_item?.model ? _item?.model : null}
                      tokens={_item?.tokens ? _item?.tokens : null}
                      question={_item?.question ? _item?.question : ""}
                      trigger={_item?.intent ? _item?.intent : ""}
                      messageIndex={index}
                      message={_item.content}
                      feedback={_item.feedback}
                      refer={_item.refer}
                      isStreaming={isStreaming}
                    />
                  )}
                </>
              );
            }
          })}
          {/* {messages.map((msg, index) => (
            <div
              key={index}
              className={`p-3 rounded-lg max-w-[75%] break-words ${
                msg.role === "user"
                  ? "bg-[#000080] text-white self-end"
                  : "bg-gray-100 text-gray-800 self-start"
              }`}
            >
              {msg.content}
            </div>
          ))} */}
        </div>
      </Box>
      <Box
        sx={{
          position: { xs: "fixed", lg: sidebarWeb ? "fixed" : "absolute" },
          bottom: 0,
          minHeight: "90px",
          width: "-webkit-fill-available",
          paddingRight: "40px",
          bgcolor: "background.default",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {/* <Box sx={{ padding: "0px" }}>
          <EditChatFooter />
        </Box> */}
        <Box>
          {/* {!loading && !isStreaming && (
            <>
              <MagicMycQResponse setShowSuggestions={setShowSuggestions} />
            </>
          )} */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isMaximizedChat ? `column` : "row",
              alignItems: "center",
              position: "relative",
            }}
          >
            {!isMaximizedChat && <FilterSearch />}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
              className="w-full ms-2"
            >
              <ContentEditable
                className={`content-editable  !p-[12px_24px] !rounded-md `}
                data-text="Send a message"
                innerRef={inputBoxRef}
                html={inputValue}
                onChange={handleChange}
              />
              <div className="absolute right-1">
                <IconButton aria-label="Submit" onClick={sendMessage}>
                  <SendIcon color="primary" />
                </IconButton>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="w-full max-w-2xl bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col overflow-hidden">
          <div className="bg-blue-500 text-white p-3 text-center font-bold">
            Chat with AI
          </div>

          <div
            ref={chatMessagesRef}
            className="flex-1 p-3 overflow-y-auto flex flex-col space-y-2"
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`p-3 rounded-lg max-w-[75%] break-words ${
                  msg.role === "user"
                    ? "bg-blue-500 text-white self-end"
                    : "bg-gray-100 text-gray-800 self-start"
                }`}
              >
                {msg.content}
              </div>
            ))}
          </div>

          <div className="flex border-t border-gray-200">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && sendMessage()}
              placeholder="Type your message here..."
              className="flex-1 p-3 outline-none"
            />
            <button
              onClick={sendMessage}
              className="bg-blue-500 text-white p-3 hover:bg-blue-600 transition-colors"
            >
              Send
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ChatUI;
