import { useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import parse from "html-react-parser";
// ** Import Type
import { FC } from "react";

import entheoGPTImg from "../../assets/images/egpt-logo-social.svg";
import entheoLawImg from "../../assets/images/egpt-logo-law.svg";
import entheoWisdomImg from "../../assets/images/egpt-logo-wisdom.svg";
import scienceLoader from "../../assets/images/egpt-loader-200px-200px-3.svg";
import lawLoader from "../../assets/images/egpt-law.gif";
import wisdomLoader from "../../assets/images/egpt-loader-200px-200px-3.svg";

import Markdown from "markdown-to-jsx";

import "./style.css";

// ** Import MUI
import {
  Grid,
  Typography,
  Box,
  Avatar,
  IconButton,
  Tooltip,
  useTheme,
  Chip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch, useSelector } from "react-redux";
import ShowSources from "./showSources";
import ResponseFeedback from "./ResponseFeedback";
// import FormattedResponse from "../FormattedResponse";
import { textToHeaderCase } from "../../utils/TextToHeaderCase";
// import ChatOverlays from "./OverLays/ChatOverlays";
import UserService from "../../KeyCloackServices/UserService";
import { saveStatistics } from "../../store/dataFlow/actions";
import { useMutation } from "react-query";
import { setSessionExpired, useMainController } from "../../context";
import http from "../../services/http-common";
import ChatOverlays from "./OverLays/ChatOverlays";
import { useLocation } from "react-router-dom";

interface HumanMessageProps {
  message: string;
}

export const HumanMessage: FC<HumanMessageProps> = ({ message }) => {
  const { user } = useSelector((state: any) => state.auth);
  const [clipboardText, setClipboardText] = useState("Click to copy");
  const theme = useTheme();

  const HandleTooltipClose = () => {
    setTimeout(() => {
      setClipboardText("Click to copy");
    }, 200);
  };
  return (
    <Grid
      container
      justifyContent="end"
      sx={{
        marginTop: "13px",
        marginBottom: "13px",
        paddingLeft: { xs: "10px", md: "90px" },
      }}
    >
      <Grid
        item
        xs={12}
        sx={{ display: "flex", position: "relative" }}
        justifyContent="end"
        alignItems="end"
      >
        <Box
          sx={{ marginRight: { xs: "10px", md: "26px" }, position: "relative" }}
        >
          <Typography
            variant="body1"
            sx={{
              display: "flex",
              backgroundColor: `${
                theme?.palette.mode === "light"
                  ? "background.userMessage"
                  : "#474b5d"
              }`,
              color: "white",
              padding: "18px 26px 18px 24px",
              maxWidth: "835px",
              borderRadius: "18px 18px 0px 18px",
              span: {
                whiteSpace: "inherit !important",
              },
            }}
          >
            {parse(message)}
            <Box
              className="bottom-3 right-0"
              sx={{
                // position: { xs: "inherit", md: "absolute" },
                // marginLeft: { xs: 'calc(100% - 98px)', md: '0px' },
                position: "absolute",
                display: "flex",

                // marginLeft: 0,
              }}
            >
              <CopyToClipboard
                text={message}
                onCopy={() => {
                  // alert("copied");
                  setClipboardText("Copied");
                }}
              >
                <Box>
                  <Tooltip
                    title={clipboardText}
                    placement="top"
                    onClose={HandleTooltipClose}
                  >
                    <IconButton aria-label="copy">
                      <ContentCopyIcon
                        sx={{ height: "16px", width: "16px", color: "white" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CopyToClipboard>
            </Box>
          </Typography>
          <Box
            className={`triangle-down-human ${
              theme?.palette.mode === "dark"
                ? "!border-t-[#474b5d]"
                : "background.userMessage"
            } `}
          ></Box>
        </Box>
        <Avatar
          variant={theme?.palette.mode === "dark" ? "rounded" : "circular"}
          alt="Seeker"
          src={
            user?.attributes?.avatar
              ? user?.attributes?.avatar?.[0]
              : "/images/seeker.png"
          }
          className="!rounded-full "
          sx={{ width: 62, height: 62, display: { xs: "none", md: "block" } }}
        />
      </Grid>
      <Avatar
        variant={theme?.palette.mode === "dark" ? "rounded" : "circular"}
        alt="Seeker"
        src={
          user?.attributes?.avatar
            ? user?.attributes?.avatar?.[0]
            : "/images/seeker.png"
        }
        className=" !rounded-full "
        sx={{
          width: 50,
          height: 50,
          display: { xs: "block", md: "none" },
          mt: "10px",
        }}
      />
    </Grid>
  );
};

interface ReferItem {
  page?: number;
  section?: number;
  name?: string;
  url: string;
  author: string;
  publication_date: string;
  title: string;
}

interface Refer {
  [key: string]: ReferItem[];
}
interface BotMessageProps {
  showSuggestions: boolean;
  isStreaming: boolean;
  questionId: string;
  message: string;
  feedback: any;
  model: any;
  tokens: any;
  trigger?: any;
  messageIndex: number;
  question: string;
  refer?: Refer;
}

export const BotMessage: FC<BotMessageProps> = ({
  showSuggestions,
  isStreaming,
  questionId,
  feedback,
  model,
  tokens,
  message,
  trigger,
  refer,
  question,
  messageIndex,
}) => {
  const [clipboardText, setClipboardText] = useState("Click to copy");
  // const { user } = useSelector((state: any) => state.auth);
  const location = useLocation();
  const theme = useTheme();
  const HandleTooltipClose = () => {
    setTimeout(() => {
      setClipboardText("Click to copy");
    }, 200);
  };
  // useEffect(() => {
  //   if (currentWord >= message.length || !isStreaming) {
  //     setIsComplete(true);
  //     return;
  //   }
  //   let currentIndex: number = 0;
  //   const interval = setInterval(() => {
  //     setCurrentWord(
  //       (prevWord) =>
  //         prevWord +
  //         message.split(" ")[currentIndex].length +
  //         message.split(" ")[currentIndex - 1].length
  //     );
  //     currentIndex = 5 + currentIndex;
  //     if (!isStreaming) {
  //       return () => clearInterval(interval);
  //     }
  //   }, 50);

  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentWord, message]);
  // useEffect(() => {
  //   let count = 0;
  //   if (isBotResponse) {
  //     count++;
  //   }
  //   if (count === 1 && messageIndex === 0) {
  //     dispatch(updateChatStreaming(false));
  //     count--;
  //   }
  // }, []);

  const getReferences = () => {
    let refers: any = "";
    const categories = ["science", "legal", "wisdom"];
    let sourcesText = "Sources";

    categories.forEach((category) => {
      if (refer && refer[category]?.length > 0) {
        sourcesText = category.charAt(0).toUpperCase() + category.slice(1);
        refer[category].map((_item, idx) => {
          refers +=
            (refers ? "\n" : "") +
            (idx + 1) +
            ". " +
            `${
              _item.title ? _item.title + " by " + _item.author : _item.name
            }` +
            ((_item.page || _item.section) &&
              `. (${_item?.page ? "p." + _item?.page + " " : ""}${
                _item?.section ? "sec." + _item?.section : ""
              })`) +
            ` ${
              UserService.getRole()?.roles?.includes("admin")
                ? `(${_item.url})`
                : ""
            }`;
        });
      }
    });

    return `${
      "\n" +
      "Generated by https://EntheoGPT.com Entheo AI chatbot. " +
      "\n\n" +
      `${sourcesText} : \n${refers}`
    }`;
  };
  const copy = async () => {
    await navigator.clipboard.writeText(
      "EntheoGPT:\n" + message + "\n" + getReferences()
    );
  };
  return (
    <>
      {trigger ? (
        <ChatOverlays intent={trigger} />
      ) : (
        <Grid
          container
          justifyContent="start"
          sx={{
            marginTop: "13px",
            marginBottom: "13px",
            paddingRight: { xs: "10px", md: "70px" },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ display: "flex", position: "relative" }}
            alignItems="end"
          >
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <img
                alt="EntheoGPT"
                src={
                  location.pathname.includes("law")
                    ? entheoLawImg
                    : location.pathname.includes("wisdom")
                    ? entheoWisdomImg
                    : entheoGPTImg
                }
                className="rounded-full  "
                style={{ width: 62, height: 62 }}
              />
            </Box>
            <Box
              sx={{
                marginLeft: {
                  xs: "10px",
                  md: "26px",
                },
                position: "relative",
                maxWidth: "100%",
              }}
            >
              <Typography
                component="div"
                variant="body1"
                className="break-words"
                sx={{
                  backgroundColor:
                    theme?.palette.mode === "light"
                      ? location.pathname.includes("law")
                        ? "#E8F5E9 "
                        : location.pathname.includes("wisdom")
                        ? "#FDE2E4 "
                        : "background.botMessage"
                      : "#202940",
                  padding: "18px 38px 18px 26px",
                  maxWidth: { md: "835px", xs: "100%" },
                  borderRadius: "18px 18px 18px 0px",
                  span: {
                    whiteSpace: "inherit !important",
                  },
                }}
              >
                {/* {isBotResponse && messageIndex === 0 && !showSuggestions ? (
                <FormattedResponse
                  response={message.substring(0, currentWord)}
                />
              ) : (
                <FormattedResponse response={message} />
              )} */}
                <Markdown
                  options={{
                    overrides: {
                      a: {
                        props: {
                          className: "text-blue-500 underline",
                          target: "_blank",
                          rel: "noreferrer noopener",
                        },
                      },
                    },
                  }}
                >
                  {message}
                </Markdown>
                {/* <FormattedResponse response={message} /> */}
                {/* {messageIndex === 0 && isStreaming && (
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  ...
                </span>
              )} */}
                {refer &&
                  !isStreaming &&
                  refer?.science?.length +
                    refer?.wisdom?.length +
                    refer?.legal?.length >
                    0 && (
                    <div className="flex justify-start items-center gap-2 mt-1">
                      <ShowSources
                        questionId={questionId}
                        question={question}
                        message={message}
                        refer={refer}
                      />
                      {model && (
                        <Chip
                          className="self-center"
                          variant="outlined"
                          color="primary"
                          size="small"
                          label={textToHeaderCase(model)}
                        />
                      )}
                      {UserService.getRole()?.roles?.includes("admin") &&
                        tokens && (
                          <Chip
                            className="self-center"
                            variant="filled"
                            color="info"
                            size="small"
                            label={`Tokens:${tokens}`}
                          />
                        )}
                    </div>
                  )}
              </Typography>
              <Box
                className={`triangle-down-bot  ${
                  theme?.palette.mode === "dark"
                    ? "!border-t-[#202940]"
                    : location.pathname.includes("law")
                    ? "#E8F5E9 "
                    : location.pathname.includes("wisdom")
                    ? "#FDE2E4 "
                    : "background.botMessage"
                }`}
              ></Box>
              <Box
                className="bottom-3 right-2"
                sx={{
                  // position: { xs: 'inherit', md: 'absolute' },
                  // marginLeft: { xs: 'calc(100% - 98px)', md: '0px' },
                  position: "absolute",
                  display: "flex",

                  // marginLeft: 0,
                }}
              >
                <CopyToClipboard
                  text={message + `${getReferences()}`}
                  onCopy={() => {
                    // alert("copied");
                    setClipboardText("Copied");
                  }}
                >
                  <Box>
                    <Tooltip
                      title={clipboardText}
                      placement="top"
                      onClose={HandleTooltipClose}
                    >
                      <IconButton aria-label="copy" onClick={() => copy()}>
                        <ContentCopyIcon
                          sx={{ height: "16px", width: "16px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </CopyToClipboard>

                <ResponseFeedback questionId={questionId} feedback={feedback} />
              </Box>
            </Box>
          </Grid>
          <Box
            sx={{
              display: { xs: "block", md: "none" },
              mt: "10px",
              width: "50px",
              height: "50px",
            }}
          >
            <img
              alt="EntheoGPT"
              src={
                location.pathname.includes("law")
                  ? entheoLawImg
                  : location.pathname.includes("wisdom")
                  ? entheoWisdomImg
                  : entheoGPTImg
              }
              className="rounded-full "
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};
interface BotThinkingProps {
  text: string;
}
export const BotThinking: FC<BotThinkingProps> = ({ text }) => {
  const dispatch = useDispatch();
  const dispatchHttp = useMainController();
  const location = useLocation();
  // const [loadingTime, setLoadingTime] = useState(0);
  // const theme = useTheme();
  const { totalStatistics } = useSelector((state: any) => state.dataFlow);

  // let timer: any;
  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   timer = setInterval(() => {
  //     setLoadingTime((value) => value + 1);
  //   }, 1000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // });

  const getStatistics = useMutation(
    () => {
      return http.get(
        "train/book/statistics-total",
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: ({ data }) => {
        dispatch(saveStatistics(data.statistics));
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  useEffect(() => {
    !totalStatistics && getStatistics.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const thinkingContent = useMemo(() => {
  //   if (loadingTime === 0) {
  //     return `Searching ${
  //       totalStatistics
  //         ? totalStatistics?.docsTotal + totalStatistics?.websTotal
  //         : ""
  //     } documents database…`;
  //   } else if (loadingTime > 0 && loadingTime < 3) {
  //     return `Reading thousands of papers…`;
  //   } else if (loadingTime >= 3 && loadingTime < 6) {
  //     return "References retrieved! Augmenting your prompt…";
  //   } else {
  //     clearInterval(timer);
  //     return "Generating augmented response…";
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loadingTime]);

  return (
    <Grid
      container
      justifyContent="start"
      sx={{
        marginTop: "13px",
        marginBottom: "13px",
        paddingRight: { xs: "10px", md: "70px" },
      }}
    >
      <Grid
        item
        xs={12}
        sx={{ display: "flex", position: "relative" }}
        alignItems="end"
      >
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <img
            alt="EntheoGPT"
            src={
              location.pathname.includes("law")
                ? lawLoader
                : location.pathname.includes("wisdom")
                ? wisdomLoader
                : scienceLoader
            }
            className="rounded-xl"
            style={{ width: 62, height: 62 }}
          />
        </Box>

        <Box
          sx={{
            marginLeft: { xs: "10px", md: "26px" },
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <Typography
            component="div"
            variant="body1"
            className="break-words"
            sx={{
              // backgroundColor:
              // theme?.palette.mode === "light"
              //       ? location.pathname.includes('law') ?'#E8F5E9 ' :
              //        location.pathname.includes('wisdom') ?'#FDE2E4' :
              //         "background.botMessage"
              //       : "#202940",
              padding: "18px 38px 18px 26px",
              maxWidth: { md: "835px", xs: "100%" },
              borderRadius: "18px 18px 18px 0px",
              span: {
                whiteSpace: "inherit !important",
              },
            }}
          >
            <div className="streamingTestLoader">{text}</div>
          </Typography>
          {/* <Box
            className={`triangle-down-bot 
                ${theme?.palette.mode === "dark" ? "!border-t-[#202940]" 
                  : location.pathname.includes('law') ?'!border-t-[#E8F5E9 ]' 
                  : location.pathname.includes('wisdom') ?'!border-t-[#FDE2E4]' :
                   "background.botMessage"}`}
          ></Box>{" "} */}
        </Box>
      </Grid>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          mt: "10px",
          width: "50px",
          height: "50px",
        }}
      >
        <img
          alt="EntheoGPT"
          src={
            location.pathname.includes("law")
              ? lawLoader
              : location.pathname.includes("wisdom")
              ? wisdomLoader
              : scienceLoader
          }
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
    </Grid>
  );
};
