import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import {
  UserCircleIcon,
  Cog6ToothIcon,
  CircleStackIcon,
} from "@heroicons/react/24/outline";
import GeneralSettings from "../SettingsPages/GeneralSettings";
import DataControl from "../SettingsPages/DataControl";
import ProfileSetting from "../SettingsPages/profileSetting";
import { useMediaQuery } from "@mui/material";

export function AllSettings() {
  const smallScreen = useMediaQuery("(max-width: 768px)");

  const data = [
    {
      label: "General",
      value: "general",
      icon: Cog6ToothIcon,
      desc: <GeneralSettings />,
    },
    {
      label: "Data Flow",
      value: "data-flow",
      icon: CircleStackIcon,
      desc: <DataControl />,
    },
    {
      label: "Profile",
      value: "profile",
      icon: UserCircleIcon,
      desc: <ProfileSetting />,
    },
  ];
  return (
    <Tabs value="general" orientation={smallScreen ? "horizontal" : "vertical"}>
      <TabsHeader className={`flex ${smallScreen ? "flex-row" : "flex-col"} `}>
        {data.map(({ label, value, icon }) => (
          <Tab
            key={value}
            value={value}
            className="place-items-start !justify-start"
          >
            <div className="flex flex-row !justify-start items-center gap-1 !w-[max-content]">
              {React.createElement(icon, { className: "w-5 h-5" })}
              {label}
            </div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className="w-full my-1">
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value} className="py-0 w-full">
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}
