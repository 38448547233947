import { Box, Typography } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { useEffect, useMemo, useState } from "react";

export const BotThinking = () => {
  const [loadingTime, setLoadingTime] = useState(0);
  let timer;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setInterval(() => {
      setLoadingTime((value) => value + 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  const thinkingContent = useMemo(() => {
    if (loadingTime === 0) {
      return "Sending your request to the neural net. Please stand by!";
    } else if (loadingTime > 0 && loadingTime < 3) {
      return "Thank you for your question! Stand by while I look for relevant keywords to upsert your request!";
    } else if (loadingTime >= 3 && loadingTime < 6) {
      return "Relevant text located! Sending upserted request to neural net…";
    } else {
      clearInterval(timer);
      return "Thank you for your question! Stand by while I look for relevant keywords to upsert your request!";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTime]);

  return (
    <>
      <Box
        sx={{
          marginLeft: { xs: "10px", md: "26px" },
          position: "relative",
          maxWidth: "100%",
        }}
      >
        <Typography
          component="div"
          variant="body2"
          className="break-words"
          sx={{
            // backgroundColor:
            // theme?.palette.mode === "light"
            //       ? location.pathname.includes('law') ?'#E8F5E9 ' :
            //        location.pathname.includes('wisdom') ?'#FDE2E4' :
            //         "background.botMessage"
            //       : "#202940",
            padding: "18px 38px 18px 26px",
            maxWidth: { md: "835px", xs: "100%" },
            borderRadius: "18px 18px 18px 0px",
            span: {
              whiteSpace: "inherit !important",
            },
          }}
        >
          <div className="streamingTestLoader">{thinkingContent}</div>
        </Typography>
        {/* <Box
                  className={`triangle-down-bot 
                      ${theme?.palette.mode === "dark" ? "!border-t-[#202940]" 
                        : location.pathname.includes('law') ?'!border-t-[#E8F5E9 ]' 
                        : location.pathname.includes('wisdom') ?'!border-t-[#FDE2E4]' :
                         "background.botMessage"}`}
                ></Box>{" "} */}
      </Box>
    </>
  );
};

export const BotMessage = ({
  message,
  messageIndex,
  allMessages,
  refer,
  questionId,
  question,
}) => {
  const [currentWord, setCurrentWord] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (currentWord >= message.length) {
      setIsComplete(true);
      return;
    }
    let currentIndex = 0;
    const interval = setInterval(() => {
      setCurrentWord(
        (prevWord) =>
          prevWord +
          message.split(" ")[currentIndex].length +
          message.split(" ")[currentIndex - 1].length
      );
      currentIndex++;
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, [currentWord, message]);
  return (
    <>
      <p className="flex flex-col justify-start items-start ">
        {/* {
          messageIndex === allMessages.length - 1 &&
          allMessages.length !== 1 ? (
            <FormattedResponse response={message.substring(0, currentWord)} />
          ) : (
            <>
              <FormattedResponse response={message} />
            </>
          )
        } */}

        <Markdown
          className="!text-black"
          options={{
            overrides: {
              a: {
                props: {
                  className: "text-blue-500 underline",
                  target: "_blank",
                  rel: "noreferrer noopener",
                },
              },
            },
          }}
        >
          {message}
        </Markdown>
        {messageIndex === 0 && !isComplete && (
          <span
            style={{
              marginLeft: "5px",
            }}
          >
            ...
          </span>
        )}
        {/* {refer && refer.length > 0 ? (
          <>
            <ShowSources
              personalityBot={true}
              questionId={questionId}
              question={question}
              message={message}
              refer={refer}
            />
          </>
        ) : null} */}
      </p>
    </>
  );
};
