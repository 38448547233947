import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  // Link
} from "@mui/material";
// import { useNavigate } from 'react-router-dom';

import useStyles from "./styles/styles";

import { useMutation } from "react-query";
import { setSessionExpired, useMainController } from "../../context";
import http from "../../services/http-common";
const HowItWorks = () => {
  const dispatch = useMainController();
  const [totalDocs, setTotalDocs] = useState(null);
  // const navigate = useNavigate();
  const classes = useStyles();

  const getPrompt = useMutation(
    () => {
      return http.get(
        "train/book/statistics-total",
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        setTotalDocs(data.statistics.total);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  useEffect(() => {
    getPrompt.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clases = {
    header2: {
      fontSize: { xs: "7.63vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: { md: "3vw", xs: "0" },
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      my: { xs: "5vw", md: "0" },
      // textAlign: { xs: 'center', md: 'left' },
    },
    buttonSx: {
      width: { md: "22.48vw", xs: "39.95vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: "2vw",
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
      textAlign: "left",
    },
  };
  return (
    <Grid
      container
      className={classes.sectionBox}
      // sx={{ background: "#F7F9FF" }}
    >
      {/* <Shape secNumber="3" /> */}
      <Grid
        container
        className={`${classes.containerGrid} !py-0`}
        // sx={{ py: { xs: "0", md: "3vw" } }}
      >
        <Container>
          <Grid
            item
            className={`${classes.flexCenter} `}
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <Grid item>
              <Box>
                <Typography sx={clases.contentText} textAlign={"center"}>
                EntheoGPT is your  <span className="font-extrabold">AI-powered companion</span> for navigating psychedelic experiences with <span className="font-extrabold">research-backed insights, risk screening, and integration tools. </span> 
                 Our mission is to make psychedelic exploration <span className="font-extrabold">safer, more informed, and accessible</span> while reducing the <span className="font-extrabold">cost and risk of psychedelic therapies</span> through AI-driven <span className="font-extrabold">preparation, risk assessment, and post-experience integration.</span>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default HowItWorks;
