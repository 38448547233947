export const plansDetails = [
  {
    id: "prod_QxHGHZXafs8jfC",
    object: "product",
    active: true,
    attributes: [],
    created: 1727853038,
    default_price: {
      id: "price_1Q5MhnHa0zoJgb0f6UxIFbt2",
      object: "price",
      active: true,
      billing_scheme: "per_unit",
      created: 1727853039,
      currency: "usd",
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: "prod_QxHGHZXafs8jfC",
      recurring: null,
      tax_behavior: "unspecified",
      tiers_mode: null,
      transform_quantity: null,
      type: "one_time",
      unit_amount: 0,
      unit_amount_decimal: "0",
    },
    description: null,
    features: [],
    images: [],
    livemode: false,
    marketing_features: [],
    metadata: {
      average_questions: "5-50",
      legal_documents: "no",
      max_references: "3",
      off: "no",
      price: "Free",
      scientific_documents: "no",
      support: "Community Forum",
      tokens: "25000",
      year: "no",
    },
    name: "EntheoGPT",
    package_dimensions: null,
    shippable: null,
    statement_descriptor: null,
    tax_code: "txcd_20030000",
    type: "service",
    unit_label: null,
    updated: 1727855230,
    url: null,
  },
  {
    id: "prod_Qs1ciLFthoGvXU",
    object: "product",
    active: true,
    attributes: [],
    created: 1726641639,
    default_price: {
      id: "price_1Q0HZ6Ha0zoJgb0fAgWUGVt2",
      object: "price",
      active: true,
      billing_scheme: "per_unit",
      created: 1726641640,
      currency: "usd",
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: "prod_Qs1ciLFthoGvXU",
      recurring: {
        aggregate_usage: null,
        interval: "month",
        interval_count: 1,
        meter: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      tax_behavior: "exclusive",
      tiers_mode: null,
      transform_quantity: null,
      type: "recurring",
      unit_amount: 2200,
      unit_amount_decimal: "2200",
    },
    description: "you get 150,000 tokens/month",
    features: [],
    images: [],
    livemode: false,
    marketing_features: [],
    metadata: {
      average_questions: "30-300",
      legal_documents: "no",
      max_references: "5",
      name: "entheogpt_monthly",
      off: "no",
      price: "$22/mo",
      scientific_documents: "yes",
      support: "Priority Email",
      tokens: "150000",
      year: "no",
    },
    name: "EntheoGPT+",
    package_dimensions: null,
    shippable: null,
    statement_descriptor: null,
    tax_code: "txcd_20030000",
    type: "service",
    unit_label: null,
    updated: 1727853521,
    url: null,
  },
  {
    id: "prod_QwtnX4VhJhxIWy",
    object: "product",
    active: true,
    attributes: [],
    created: 1727765763,
    default_price: {
      id: "price_1Q5008Ha0zoJgb0fDF7jYBkD",
      object: "price",
      active: true,
      billing_scheme: "per_unit",
      created: 1727765764,
      currency: "usd",
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: "prod_QwtnX4VhJhxIWy",
      recurring: {
        aggregate_usage: null,
        interval: "year",
        interval_count: 1,
        meter: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      tax_behavior: "unspecified",
      tiers_mode: null,
      transform_quantity: null,
      type: "recurring",
      unit_amount: 24000,
      unit_amount_decimal: "24000",
    },
    description: "you get 1,800,000 tokens a year.",
    features: [],
    images: [],
    livemode: false,
    marketing_features: [],
    metadata: {
      average_questions: "30-300",
      legal_documents: "no",
      max_references: "5",
      name: "entheogpt_yearly",
      off: "$24",
      price: "$240/yr",
      scientific_documents: "yes",
      support: "Priority Email",
      tokens: "1800000",
      year: "yes",
    },
    name: "EntheoGPT+",
    package_dimensions: null,
    shippable: null,
    statement_descriptor: null,
    tax_code: "txcd_20030000",
    type: "service",
    unit_label: null,
    updated: 1727853823,
    url: null,
  },
  {
    id: "prod_QwtqxKu973sc6h",
    object: "product",
    active: true,
    attributes: [],
    created: 1727765904,
    default_price: {
      id: "price_1Q502PHa0zoJgb0f2FJkjdjG",
      object: "price",
      active: true,
      billing_scheme: "per_unit",
      created: 1727765905,
      currency: "usd",
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: "prod_QwtqxKu973sc6h",
      recurring: {
        aggregate_usage: null,
        interval: "year",
        interval_count: 1,
        meter: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      tax_behavior: "unspecified",
      tiers_mode: null,
      transform_quantity: null,
      type: "recurring",
      unit_amount: 48000,
      unit_amount_decimal: "48000",
    },
    description: "you get  6,000,000 tokens a year.",
    features: [],
    images: [],
    livemode: false,
    marketing_features: [],
    metadata: {
      average_questions: "100-1000",
      client_intakes: "yes",
      custom_assistants: "yes",
      gpt_store_listing: "yes",
      legal_documents: "no",
      max_references: "10",
      name: "entheo_pro_yearly",
      off: "$48",
      price: "$480/yr",
      psiloscreen_prescreening: "yes",
      scientific_documents: "yes",
      support: "Priority Email",
      tokens: "6000000",
      year: "yes",
    },
    name: "EntheoPro",
    package_dimensions: null,
    shippable: null,
    statement_descriptor: null,
    tax_code: "txcd_20030000",
    type: "service",
    unit_label: null,
    updated: 1727854872,
    url: null,
  },
  {
    id: "prod_Qwu28R0sqkC1d7",
    object: "product",
    active: true,
    attributes: [],
    created: 1727766646,
    default_price: {
      id: "price_1Q50ENHa0zoJgb0f2liFZomz",
      object: "price",
      active: true,
      billing_scheme: "per_unit",
      created: 1727766647,
      currency: "usd",
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: "prod_Qwu28R0sqkC1d7",
      recurring: {
        aggregate_usage: null,
        interval: "year",
        interval_count: 1,
        meter: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      tax_behavior: "unspecified",
      tiers_mode: null,
      transform_quantity: null,
      type: "recurring",
      unit_amount: 96000,
      unit_amount_decimal: "96000",
    },
    description: "you get 9,000,000 tokens a year.",
    features: [],
    images: [],
    livemode: false,
    marketing_features: [],
    metadata: {
      average_questions: "50-1500",
      client_intakes: "yes",
      custom_assistants: "yes",
      gpt_store_listing: "yes",
      legal_documents: "yes",
      max_references: "15",
      name: "entheo_law_yearly",
      off: "$96",
      price: "$960/yr",
      psiloscreen_prescreening: "yes",
      scientific_documents: "yes",
      support: "Dedicated Support",
      tokens: "9000000",
      year: "yes",
    },
    name: "EntheoLaw",
    package_dimensions: null,
    shippable: null,
    statement_descriptor: null,
    tax_code: "txcd_10000000",
    type: "service",
    unit_label: null,
    updated: 1727855114,
    url: null,
  },
  {
    id: "prod_Qs1iwEtcyNpb04",
    object: "product",
    active: true,
    attributes: [],
    created: 1726641981,
    default_price: {
      id: "price_1Q0HecHa0zoJgb0f8eFLjBak",
      object: "price",
      active: true,
      billing_scheme: "per_unit",
      created: 1726641982,
      currency: "usd",
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: "prod_Qs1iwEtcyNpb04",
      recurring: {
        aggregate_usage: null,
        interval: "month",
        interval_count: 1,
        meter: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      tax_behavior: "unspecified",
      tiers_mode: null,
      transform_quantity: null,
      type: "recurring",
      unit_amount: 4400,
      unit_amount_decimal: "4400",
    },
    description: "you get 500,000 tokens/month",
    features: [],
    images: [],
    livemode: false,
    marketing_features: [],
    metadata: {
      average_questions: "100-1000",
      client_intakes: "yes",
      custom_assistants: "yes",
      gpt_store_listing: "yes",
      legal_documents: "no",
      max_references: "10",
      name: "entheo_pro_monthly",
      off: "no",
      price: "$44/mo",
      psiloscreen_prescreening: "yes",
      scientific_documents: "yes",
      support: "Priority Email",
      tokens: "500000",
      year: "no",
    },
    name: "EntheoPro",
    package_dimensions: null,
    shippable: null,
    statement_descriptor: null,
    tax_code: "txcd_20030000",
    type: "service",
    unit_label: null,
    updated: 1727855163,
    url: null,
  },
  {
    id: "prod_Qwu0DYp3QimwqZ",
    object: "product",
    active: true,
    attributes: [],
    created: 1727766549,
    default_price: {
      id: "price_1Q50CoHa0zoJgb0fj6cpzIFP",
      object: "price",
      active: true,
      billing_scheme: "per_unit",
      created: 1727766550,
      currency: "usd",
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: "prod_Qwu0DYp3QimwqZ",
      recurring: {
        aggregate_usage: null,
        interval: "month",
        interval_count: 1,
        meter: null,
        trial_period_days: null,
        usage_type: "licensed",
      },
      tax_behavior: "unspecified",
      tiers_mode: null,
      transform_quantity: null,
      type: "recurring",
      unit_amount: 8800,
      unit_amount_decimal: "8800",
    },
    description: "you get 750,000 tokens a month.",
    features: [],
    images: [],
    livemode: false,
    marketing_features: [],
    metadata: {
      average_questions: "150-1500",
      client_intakes: "yes",
      custom_assistants: "yes",
      gpt_store_listing: "yes",
      legal_documents: "yes",
      max_references: "15",
      name: "entheo_law_monthly",
      off: "no",
      price: "$88/mo",
      psiloscreen_prescreening: "yes",
      scientific_documents: "yes",
      support: "Dedicated Support",
      tokens: "750000",
      year: "no",
    },
    name: "EntheoLaw",
    package_dimensions: null,
    shippable: null,
    statement_descriptor: null,
    tax_code: "txcd_20030000",
    type: "service",
    unit_label: null,
    updated: 1727854749,
    url: null,
  },

  {
    id: "prod_QtuOuK6NUpad90",
    object: "product",
    active: true,
    attributes: [],
    created: 1727076020,
    default_price: {
      id: "price_1Q26ZHHa0zoJgb0fJG5X91VY",
      object: "price",
      active: true,
      billing_scheme: "per_unit",
      created: 1727076023,
      currency: "usd",
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: "prod_QtuOuK6NUpad90",
      recurring: null,
      tax_behavior: "unspecified",
      tiers_mode: null,
      transform_quantity: null,
      type: "one_time",
      unit_amount: 2200,
      unit_amount_decimal: "2200",
    },
    description:
      "This plan is not recurring. It is one time charge. And numbers of tokens you get have no expiry date.",
    features: [],
    images: [],
    livemode: false,
    marketing_features: [],
    metadata: {
      name: "five_dollar_plan",
      tokens: "25000",
    },
    name: "$5 plan  (25000 tokens)",
    package_dimensions: null,
    shippable: null,
    statement_descriptor: null,
    tax_code: "txcd_20030000",
    type: "service",
    unit_label: null,
    updated: 1727768327,
    url: null,
  },
];
