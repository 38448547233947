import * as React from "react";
// import SwipeableViews from 'react-swipeable-views';
import { Divider, Box, Typography } from "@mui/material";

// ** import children component
import { useSelector } from "react-redux";
import MyResearchDocs from "../components/dedicate/my-research-docs";
import MyContributeWebsites from "../components/dedicate/my-contribute-website";
import Leaderboard from "../components/SettingsPages/LeaderBoard/Leaderboard";

const Dedicate = () => {
  const { user } = useSelector((state: any) => state.auth);

  return (
    user && (
      <Box
        sx={{
          maxHeight: "calc(100vh - 140px)",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: { sm: "center", xs: "start" },
            flexDirection: {
              sm: "row",
              xs: "column",
            },
            mt: "15px",
          }}
        >
          <Typography
            component="h1"
            sx={{
              fontSize: "30px !important",
              lineHeight: "23px",
              color: "primary.main",
              fontWeight: "700",
              fontStyle: "normal",
              fontFamily: "DM Sans",
              mr: "auto",
              mb: "20px",
            }}
          >
            Contribute research
          </Typography>
        </Box>
        <Divider />
        {/* <SwipeableViews
					axis={'x'}
					index={value}
					onChangeIndex={handleChangeIndex}
				> */}
        <MyResearchDocs />
        <Divider>OR</Divider>

        <MyContributeWebsites />

        <Divider />

        <Leaderboard />
        {/* </SwipeableViews> */}
      </Box>
    )
  );
};

export default Dedicate;
