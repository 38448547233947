export const timeSlots = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const defaultAvailability = {
  monday: { isAvailable: false, startTime: "09:00", endTime: "17:00" },
  tuesday: { isAvailable: false, startTime: "09:00", endTime: "17:00" },
  wednesday: { isAvailable: false, startTime: "09:00", endTime: "17:00" },
  thursday: { isAvailable: false, startTime: "09:00", endTime: "17:00" },
  friday: { isAvailable: false, startTime: "09:00", endTime: "17:00" },
  saturday: { isAvailable: false, startTime: "09:00", endTime: "17:00" },
  sunday: { isAvailable: false, startTime: "09:00", endTime: "17:00" },
  timeGap: 0,
};

function getRandomTimeSlots() {
  const slots = [];
  const startHour = 9; // 9 AM
  const endHour = 17; // 5 PM
  const slotDuration = 1; // 1 hour slots

  for (let hour = startHour; hour < endHour; hour += slotDuration) {
    if (Math.random() > 0.5) {
      // 50% chance to include the slot
      slots.push(`${hour}:00 - ${hour + slotDuration}:00`);
    }
  }

  return slots;
}

export const dummyAvailability = [
  { date: "2024-11-27" },
  { date: "2024-11-28" },
  { date: "2024-11-29" },
  { date: "2024-11-30" },
  { date: "2024-12-01" },
].map((entry) => ({
  ...entry,
  slots: getRandomTimeSlots(),
}));

export function convertTo12HourFormat(time) {
  let [hours, minutes] = time.split(":");
  hours = parseInt(hours);

  let period = "AM";
  if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  } else if (hours === 0) {
    hours = 12;
  }

  return `${hours}:${minutes} ${period}`;
}

export function getWorkingDaysFromToday({ availability }) {
  const workingDays =
    availability &&
    availability
      .filter((item) => item.slots[0] && item.slots[0].startTime !== null)
      .map((item) => item.dayOfWeek);
  // Get today's date
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();

  // Find the first day of the next month
  const nextMonth = new Date(year, month, 15);

  // Create an array to hold the working day dates
  const workingDayDates = [];

  // Loop through each day from today until the end of the month
  for (
    let current = new Date(year, month, day);
    current < nextMonth;
    current.setDate(current.getDate() + 1)
  ) {
    const dayName = current.toLocaleString("en-US", {
      weekday: "long",
    });

    // Check if the day is a working day
    if (workingDays && workingDays.includes(dayName)) {
      workingDayDates.push(current.toISOString().split("T")[0]);
    }
  }

  return workingDayDates;
}
