import { Grid, Typography } from "@mui/material";

import useStyles from "../styles/styles";
import { Link } from "react-router-dom";

const IntroSection = ({ heading, text }: any) => {
  const classes = useStyles();

  const clases = {
    header2: {
      fontSize: { xs: "7.63vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: "3vw",
      py: "1vw",
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    header4: {
      fontSize: { xs: "5.09vw !important", md: "2.08vw !important" },
      fontWeight: "600 !important",
      // letterSpacing: "-1.33px !important",
      color: "#101010 !important",
      textAlign: "center",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      textAlign: "center",
      // textAlign: { xs: 'center', md: 'left' },
      py: "1vw",
    },
    buttonSx: {
      width: { md: "13.22vw", xs: "66.16vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
      my: { xs: "3vw", md: "0" },
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
      textAlign: "left",
    },
  };
  return (
    <Grid className={classes.sectionBox}>
      <Grid
        item
        container
        xs={12}
        md={12}
        className={classes.flexCenter}
        sx={{
          flexDirection: "column",
          py: { xs: "15.77vw", md: "3vw" },
        }}
      >
        <Typography sx={clases.header3} className="!text-center">
          {heading}
        </Typography>
        <Grid
          item
          xs={11}
          md={8.7}
          className={classes.flexCenter}
          sx={{
            flexDirection: "column",
          }}
        >
          <Grid item xs={11} md={10}>
            <Typography sx={clases.contentText}>
              Whether you're just starting or seeking in-depth insights and
              expert support, <span className="font-bold">EntheoGPT</span> has a
              plan that fits your needs. Begin your journey for free with the{" "}
              <span className="font-bold">EntheoGPT</span> plan, or unlock
              advanced insights and support with{" "}
              <span className="font-bold">EntheoGPT+</span>,{" "}
              <span className="font-bold">EntheoPro</span>, or{" "}
              <span className="font-bold">EntheoLaw</span>.
              <br />
              <br />
              Ready to begin? Start for free or{" "}
              <a
                href="http://entheogpt.com/pay"
                className="underline text-[#000080] font-bold "
              >
                upgrade today
              </a>{" "}
              to access more advanced features and guidance! Are you an
              Entheogenic Professional? Check out{" "}
              <Link
                to="https://entheo.pro/"
                className="underline text-[#000080] font-bold"
              >
                Entheo.Pro
              </Link>{" "}
              !
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntroSection;
