import { Typography, Card, CardBody } from "@material-tailwind/react";

import { useSelector } from "react-redux";
import Tokenlogs from "../Tokenlogs";
// import RedeemCoupon from "./RedeemCoupon";

function TokenSummary() {
  const { tokensSummary } = useSelector((state) => state.dataFlow);
  return (
    <section className="container mx-auto">
      <div className="mt-6 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 items-center md:gap-2.5 gap-4">
        {tokensSummary &&
          [
            {
              title: "Remaining Tokens",
              count: tokensSummary?.curentBalance.toLocaleString(),
              icon: (
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#fdf4ff] text-[#e879f9]">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.52325 6.61231C10.2911 5.20443 12.4206 4.32434 14.6667 4.07333V17.3333H27.9267C27.6757 19.5794 26.7956 21.7089 25.3877 23.4767C23.9798 25.2446 22.1013 26.5791 19.9685 27.3265C17.8357 28.0739 15.5351 28.2039 13.3317 27.7015C11.1282 27.1991 9.11142 26.0847 7.51336 24.4866C5.91529 22.8886 4.80094 20.8718 4.29854 18.6683C3.79614 16.4649 3.92612 14.1643 4.67352 12.0315C5.42092 9.89866 6.75535 8.0202 8.52325 6.61231Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M20 12H27.3173C26.7188 10.3128 25.7513 8.78047 24.4854 7.5146C23.2195 6.24873 21.6872 5.28125 20 4.68268V12Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              ),
            },
            {
              title: "Purchased Tokens",
              count: tokensSummary?.totalCredits.toLocaleString(),
              icon: (
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#ecfeff] text-[#22d3ee]">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.3333 9.33334H28M28 9.33334V20M28 9.33334L17.3333 20L12 14.6667L4 22.6667"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              ),
            },
            {
              title: "Consumed Tokens ",
              count: tokensSummary?.totalDebits.toLocaleString(),
              icon: (
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#ecfdf5] text-[#34d399]">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.3333 9.33334H28M28 9.33334V20M28 9.33334L17.3333 20L12 14.6667L4 22.6667"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              ),
            },
          ].map((tokens, key) => (
            <Card
              key={key}
              className="shadow-sm border border-gray-200 !rounded-lg"
            >
              <CardBody className="p-2">
                <div className="flex items-center space-x-1">
                  <div>{tokens.icon}</div>
                  <div>
                    <div className="flex justify-between w-full items-center">
                      <Typography className="!font-semibold !text-xs  text-gray-600">
                        {tokens.title}
                      </Typography>
                    </div>
                    <Typography
                      color="blue-gray"
                      className="mt-1 font-bold text-xl"
                    >
                      {tokens.count}
                    </Typography>
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}
      </div>

      {/* <RedeemCoupon /> */}

      <Tokenlogs />
    </section>
  );
}

export default TokenSummary;
