import { Fragment } from "react";
import { Select, Option } from "@material-tailwind/react";
import "./formBuilder.css";

const DropdownInput = ({ item, index, handleValue }) => {
  return (
    <Fragment>
      <div className="form-group-container relative">
        <div className="text-base">
          <label className="block text-gray-700">
            {item.required && "* "}
            {index + 1 + ". " + item.value}
          </label>
        </div>
        <div>
          <Select
            size="lg"
            className="w-auto min-w-[200px]"
            name="content"
            id="el-type"
            value={item.type}
            onChange={(e) => handleValue(item.id, e.target.value)}
          >
            {item.options &&
              item.options.length > 0 &&
              item.options.map((opt, key) => (
                <Option key={key} value={opt.value}>
                  {opt.value}
                </Option>
              ))}
          </Select>
        </div>
      </div>
    </Fragment>
  );
};

export default DropdownInput;
