import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState, useEffect, useCallback } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { setSessionExpired, useMainController } from "../../context";
import { httpMushPro } from "../../services/http-common";
import { toast } from "react-toastify";

const BotTrainWebsites = () => {
  const [dispatch] = useMainController();

  const queryClient = useQueryClient();

  const cp = queryClient.getQueryData("websites-cp");
  const ps = queryClient.getQueryData("websites-ps");
  const filters = queryClient.getQueryData("websites-filters");
  const sorters = queryClient.getQueryData("websites-sorters");
  const [editMetaDataOpen, setditMetaDataOpen] = useState(false);
  const [contentId, setContentId] = useState(null);
  const [inputs, setInputs] = useState([{ question: "" }]);

  const [webSites, setWebSites] = useState([]);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [checkValues, setCheckValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(cp || 1);
  const [pageSize, setPageSize] = useState(ps || 1000000);
  const [totalPage, setTotalPage] = useState(10000);
  const [filterValues, setFilterValues] = useState(filters || {});
  const [sorterValues, setSorterValues] = useState(
    sorters || { updated_at: { desc: true } }
  );

  useQuery("websites-cp", () => currentPage);
  useQuery("websites-ps", () => pageSize);
  useQuery("websites-filters", () => filterValues);
  useQuery("websites-sorters", () => sorterValues);

  const { isError, error, data } = useQuery(
    ["websites", currentPage, pageSize, filterValues, sorterValues],
    () => {
      return httpMushPro.get(
        `train/website/mine?cp=${currentPage}&ps=${pageSize}${
          JSON.stringify(filterValues) !== "{}"
            ? `&filters=${JSON.stringify(filterValues)}`
            : ""
        }${
          JSON.stringify(sorterValues) !== "{}"
            ? `&sorters=${JSON.stringify(sorterValues)}`
            : ""
        }`,
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      keepPreviousData: true,
      // refetchInterval: false ,
      queryKey: ["websites", currentPage, pageSize],
      staleTime: Infinity,
      retryOnMount: true,
    }
  );

  useEffect(() => {
    if (isError) {
      console.log(error);
      toast.error("Fetch data failed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (data && data.data) {
      const { websites, pageSize, total } = data.data;
      console.log(websites);

      const updatedWebsites = [...websites, { url: "", id: null }];

      setWebSites(updatedWebsites);
      setTotalPage(Math.ceil(total / pageSize));
    }
  }, [data]);

  const onChangeWebsiteUrl = (e) => {
    setWebsiteUrl(e.target.value);
  };

  const registerWebsite = useMutation(
    (url) => {
      return httpMushPro.post(
        "train/website",
        { url: url },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("websites");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const updateContent = useMutation(
    (data) => {
      return httpMushPro.put(`train/website/update`, data, null);
    },
    {
      onSuccess: () => {
        // toast.success(data.message);
        queryClient.invalidateQueries("websites");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
  const onSubmit = () => {
    registerWebsite.mutate(websiteUrl);
  };

  const deleteWebsiteRecord = useMutation(
    (id) => {
      return httpMushPro.delete(`train/website/${id}`, null);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("websites");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const deleteFunc = (id) => {
    const val = webSites.find((el) => el.id === id);
    switch (val.status) {
      case "training": {
        toast.warning("Sorry! Please try after complete training");
        break;
      }
      case "scraping": {
        toast.warning("Sorry! Please try after complete scraping.");
        break;
      }
      default: {
        deleteWebsiteRecord.mutate(id);
      }
    }
  };

  const toogleOpenEditorMetaData = () => {
    if (editMetaDataOpen === true) {
      setContentId(null);
    }
    setditMetaDataOpen(!editMetaDataOpen);
  };
  const status = (value) => {
    switch (value) {
      case "ready": {
        return "blue";
      }
      case "scraping": {
        return "purple";
      }
      case "scraping failed": {
        return "red";
      }
      case "scraped": {
        return "cyan";
      }
      case "traning": {
        return "teal";
      }
      case "training failed": {
        return "pink";
      }
      case "trained": {
        return "green";
      }
      default: {
      }
    }
  };

  const bulkDeleteRequest = useMutation(
    () => {
      return httpMushPro.post(
        "train/website/bulk/delete",
        { ids: checkValues },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        setCheckValues([]);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
  const bulkDelete = () => {
    bulkDeleteRequest.mutate();
  };

  function getFilePreviewValue(file) {
    if (file.includes(".pdf")) {
      return "/svg/pdf.svg";
    } else if (file.includes(".doc")) {
      return "/svg/doc.svg";
    } else if (file.includes(".txt")) {
      return "/svg/txt.svg";
    } else {
      return "Preview Not Available";
    }
  }

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleInputChange = (index, event) => {
    const values = [...webSites];
    values[index][event.target.name] = event.target.value;
    setWebSites(values);

    if (values[index].id === null) {
      debounceSave(values[index][event.target.name]);
    } else {
      debounceUpdate(values[index]);
    }
  };

  const debounceSave = useCallback(
    debounce((value) => {
      registerWebsite.mutate(value);
    }, 1000),
    []
  );
  const debounceUpdate = useCallback(
    debounce((data) => {
      updateContent.mutate(data);
    }, 1000),
    []
  );

  const handleRemoveInput = (id) => {
    deleteWebsiteRecord.mutate(id);
  };

  return (
    <>
      <div className="mt-1  flex flex-col gap-2">
        {webSites.map((input, index) => (
          <div key={index} className="flex items-center gap-2">
            <input
              type="text"
              name="url"
              value={input.url}
              onChange={(event) => handleInputChange(index, event)}
              className="w-full text-sm overflow-y-auto rounded-lg border border-[#d9d9d9] px-3 py-2 focus:border focus:outline-1   "
              placeholder="Type your question here..."
            />
            {webSites.length > 1 && input.id !== null && (
              <span
                className="bg-danger border-none text-white p-0 cursor-pointer"
                onClick={() => handleRemoveInput(input.id)}
              >
                <XMarkIcon className="h-5 w-5 bg-white text-black" />
              </span>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default BotTrainWebsites;
