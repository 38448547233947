import { combineReducers } from "redux";

import authReducer from "./auth/reducer";
import msgReducer from "./messages/reducer";
import dataReducer from "./data/reducer";
import BotReducer from "./bot/reducer";
import DataFlowReducer from "./dataFlow/reducer";
import websocketReducer from "./websocket/reducer";

const reducer = combineReducers({
  msg: msgReducer,
  auth: authReducer,
  data: dataReducer,
  bot: BotReducer,
  dataFlow: DataFlowReducer,
  websocket: websocketReducer,
});

export default reducer;
