import {
  Grid,
  Box,
  Button,
  Typography,
  // Link
} from "@mui/material";
// import { useNavigate } from 'react-router-dom';

import useStyles from "../styles/styles";

import Shape from "../components/Shape";
import { useLocation } from "react-router-dom";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Switch } from "@material-tailwind/react";
import { useState } from "react";
import { useMutation } from "react-query";
import http from "../../../services/http-common";
import UserService from "../../../KeyCloackServices/UserService";
// const navigate = useNavigate();

export default function PricingSection({ plans }: any) {
  const [isAnnualBilling, setIsAnnualBilling] = useState("no");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const classes = useStyles();
  const clases = {
    header3: {
      fontSize: { xs: "7.5vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#000080 !important",
    },
    header1: {
      fontSize: {
        xs: "8.9vw !important",
        md: "3.38vw !important",
      },
      fontWeight: "600 !important",
      // letterSpacing: "-1.33px !important",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      textAlign: { xs: "center", md: "left" },
    },
    buttonSx: {
      width: { md: "22.48vw", xs: "39.95vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
    },
  };

  const filteredProducts = plans.filter((product: any) => {
    return (
      product.metadata.year === isAnnualBilling ||
      product.metadata.price === "Free"
    );
  });

  const handleBuy = (id: any, isRecurring: any) => {
    setLoading(true);
    buyProduct.mutate({ id, isRecurring });
  };

  const navigate = (url: any) => (window.location.href = url);

  const buyProduct = useMutation(
    (data: any) => {
      return http.post(
        `stripe/create-checkout-session`,
        {
          priceId: data.id,
          isRecurring: data.isRecurring,
        },
        null
      );
    },
    {
      onSuccess: ({ data }: any) => {
        navigate(data.url);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );
  return (
    <Grid container className={classes.sectionBox}>
      <Shape secNumber="2" />
      <Grid container className={classes.containerGrid}>
        <div
          id="pricing"
          className="w-full flex flex-col justify-center items-center"
        >
          <div className="relative mx-auto max-w-7xl px-6 text-center lg:px-8 mb-2 ">
            <Typography sx={clases.header3}>Plans</Typography>
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-4 mb-6">
            <div className=" flex justify-center">
              <div className="flex items-center">
                <Switch
                  checked={isAnnualBilling === "yes"}
                  onClick={() =>
                    setIsAnnualBilling(isAnnualBilling === "no" ? "yes" : "no")
                  }
                  id="custom-switch-component"
                  ripple={false}
                  className="h-full w-full checked:bg-[#000080]"
                  containerProps={{
                    className: "w-11 h-6",
                  }}
                  circleProps={{
                    className: "before:hidden left-0.5 border-none",
                  }}
                  crossOrigin={undefined}
                />
                <span className="ml-3 text-sm" id="headlessui-label-:R58bm:">
                  <span className="font-medium text-gray-900 dark:text-gray-200">
                    {isAnnualBilling === "yes"
                      ? "Discounted Annual Billing"
                      : "Monthly Billing"}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        {filteredProducts &&
          filteredProducts.map((plan: any) => (
            <div className="rounded-md  w-full bg-[#FFFFFF] overflow-hidden border-[1px] border-gray-200 p-8 relative lg:col-span-2">
              <div className="h-full">
                <div className="h-full z-10 relative lg:flex lg:justify-between lg:w-full lg:pr-8 lg:items-center">
                  <div className="flex flex-col flex-1 justify-between h-full space-y-5">
                    <div className="flex justify-between flex-col">
                      <div className="text-xl md:text-2xl font-bold text-gray-900 flex justify-between">
                        <div className="flex justify-start items-center gap-1">
                          <span>{plan.name}</span>
                          {plan.metadata?.off &&
                            plan.metadata?.off !== "no" &&
                            isAnnualBilling && (
                              <p className=" ml-2 rounded-full flex justify-center items-center text-white bg-[#000080] dark:bg-teal-400/10 px-2 py-1 text-sm font-semibold leading-1 ">
                                {plan.metadata?.off} OFF
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="pt-5 text-gray-500 font-medium text-base space-y-1">
                        <div className="flex items-center align-bottom">
                          <div className="ml-1 mr-2 text-xl text-mute md:text-2xl font-bold text-gray-900">
                            <span>{plan.metadata?.price}</span>
                          </div>
                        </div>
                      </div>
                      <div className="lg:hidden">
                        <ul className="space-y-2 pt-8 lg:pt-0">
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <svg
                              className="h-4 w-4"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                            <span>
                              Tokens:{" "}
                              {Number(plan.metadata?.tokens)?.toLocaleString()}
                            </span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <svg
                              className="h-4 w-4"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                            <span>
                              {" "}
                              Average Questions:{" "}
                              {plan.metadata?.average_questions}
                            </span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <svg
                              className="h-4 w-4"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                            <span>
                              Max References: {plan.metadata?.max_references}{" "}
                            </span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            {plan.metadata?.scientific_documents === "yes" ? (
                              <svg
                                className="h-4 w-4"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            ) : (
                              <XMarkIcon className="w-6 text-[#ff3a3a]" />
                            )}

                            <span>Scientific Documents</span>
                          </li>

                          <li className="flex items-center font-medium space-x-2 text-gray-600">
                            {plan.metadata?.legal_documents === "yes" ? (
                              <svg
                                className="h-4 w-4"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            ) : (
                              <XMarkIcon className="w-6 text-[#ff3a3a]" />
                            )}

                            <span>Legal References</span>
                          </li>
                          <li className="flex items-center font-medium space-x-2 text-black">
                            <svg
                              className="h-4 w-4"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>

                            <span>{plan.metadata?.support} Support</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="pt-2">
                      <span
                        onClick={() =>
                          handleBuy(
                            plan.default_price.id,
                            plan.default_price.recurring ? true : false
                          )
                        }
                        className="appearance-none inline-flex hover:shadow-2xl transition-all duration-300 hover:scale-105 items-center group space-x-2.5 bg-black text-white py-4 px-5 rounded-2xl cursor-pointer"
                      >
                        <span className="w-full font-semibold text-base">
                          Buy Now
                        </span>
                        <svg
                          className="inline-block h-6"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 12.4999H21L14 19.4999M14 5.5L18 9.5"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="hidden lg:block">
                    <ul className="space-y-2 pt-8 lg:pt-0">
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <svg
                          className="h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        <span>
                          Tokens:{" "}
                          {Number(plan.metadata?.tokens)?.toLocaleString()}
                        </span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <svg
                          className="h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        <span>
                          Average Questions: {plan.metadata?.average_questions}
                        </span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <svg
                          className="h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        <span>
                          Max References: {plan.metadata?.max_references}{" "}
                        </span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        {plan.metadata?.scientific_documents === "yes" ? (
                          <svg
                            className="h-4 w-4"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        ) : (
                          <XMarkIcon className="w-6 text-[#ff3a3a]" />
                        )}

                        <span>Scientific Documents</span>
                      </li>

                      <li className="flex items-center font-medium space-x-2 text-gray-600">
                        {plan.metadata?.legal_documents === "yes" ? (
                          <svg
                            className="h-4 w-4"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        ) : (
                          <XMarkIcon className="w-6 text-[#ff3a3a]" />
                        )}

                        <span>Legal References</span>
                      </li>
                      <li className="flex items-center font-medium space-x-2 text-black">
                        <svg
                          className="h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>

                        <span>{plan.metadata?.support} Support</span>
                      </li>
                      {/*  <li className="flex items-center font-medium space-x-2 text-gray-600">
                        <svg
                          className="h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.4444 3.03947C15.1056 2.37412 13.5965 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6244 21.9793 11.2537 21.939 10.8889M9 11L12 14L22 4"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        <span>200 events / month</span>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            // <div className="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
            //   <div className="p-8 sm:p-7">
            //     <div className="flex">
            //       <h3
            //         className="text-lg font-semibold leading-8 tracking-tight text-[#000080] "
            //         id="tier-basic"
            //       >
            //         {plan.name}
            //       </h3>
            //       {plan.metadata?.off &&
            //         plan.metadata?.off !== "no" &&
            //         isAnnualBilling && (
            //           <p className=" ml-2 rounded-full flex justify-center items-center text-white bg-[#000080] dark:bg-teal-400/10 px-2 py-1 text-xs font-semibold leading-1 ">
            //             {plan.metadata?.off} OFF
            //           </p>
            //         )}
            //     </div>
            //     <div className="mt-4 flex items-baseline text-xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">
            //       {plan.metadata?.price}
            //       <span className="text-lg font-semibold leading-8 tracking-normal text-gray-500 dark:text-gray-400"></span>
            //     </div>
            //   </div>
            //   <div className="flex flex-1 flex-col p-2">
            //     <div className="flex flex-1 flex-col justify-between rounded-2xl bg-gray-50 dark:bg-gray-700 p-3 sm:p-4">
            //       <ul className="space-y-6">
            //         <li className="flex items-start">
            //           <div className="flex-shrink-0">
            //             <CheckIcon className="w-6 text-[#000080]" />
            //           </div>
            //           <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
            //             Tokens:{" "}
            //             {Number(
            //               plan.metadata?.tokens
            //             )?.toLocaleString()}
            //           </p>
            //         </li>
            //         <li className="flex items-start">
            //           <div className="flex-shrink-0">
            //             <CheckIcon className="w-6 text-[#000080]" />
            //           </div>
            //           <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
            //             Average Questions:{" "}
            //             {plan.metadata?.average_questions}
            //           </p>
            //         </li>
            //         <li className="flex items-start">
            //           <div className="flex-shrink-0">
            //             <CheckIcon className="w-6 text-[#000080]" />
            //           </div>
            //           <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
            //             Max References: {plan.metadata?.max_references}
            //           </p>
            //         </li>
            //         <li className="flex items-start">
            //           <div className="flex-shrink-0">
            //             {plan.metadata?.scientific_documents ===
            //             "yes" ? (
            //               <CheckIcon className="w-6 text-[#000080]" />
            //             ) : (
            //               <XMarkIcon className="w-6 text-[#ff3a3a]" />
            //             )}
            //           </div>
            //           <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
            //             Scientific Documents
            //           </p>
            //         </li>
            //         <li className="flex items-start">
            //           <div className="flex-shrink-0">
            //             {plan.metadata?.legal_documents === "yes" ? (
            //               <CheckIcon className="w-6 text-[#000080]" />
            //             ) : (
            //               <XMarkIcon className="w-6 text-[#ff3a3a]" />
            //             )}
            //           </div>
            //           <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
            //             Legal References
            //           </p>
            //         </li>
            //         <li className="flex items-start">
            //           <div className="flex-shrink-0">
            //             <CheckIcon className="w-6 text-[#000080]" />
            //           </div>
            //           <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
            //             {plan.metadata?.support} Support
            //           </p>
            //         </li>
            //       </ul>
            //       {location.pathname !== "/" && (
            //         <div className="flex">
            //           <Button
            //             disabled={loading}
            //             size="small"
            //             type="button"
            //             onClick={() =>
            //               handleBuy(
            //                 plan.default_price.id,
            //                 plan.default_price.recurring ? true : false
            //               )
            //             }
            //             variant="contained"
            //             className="!w-full"
            //             sx={{
            //               mt: 2,
            //               borderRadius: "10px",
            //               height: "50.65px",
            //               mb: "20px",
            //             }}
            //           >
            //             Buy Now
            //           </Button>
            //         </div>
            //       )}
            //     </div>
            //   </div>
            // </div>
          ))}
        {location.pathname === "/" && (
          <>
            <Box
              className={classes.flexCenter}
              sx={{
                gap: "2vw",
                p: { md: "1vw", xs: "5vw" },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={clases.buttonSx}
                onClick={() =>
                  UserService.doLogin({
                    redirectUri: `${window.location.href + "pay"}`,
                  })
                }
              >
                Upgrade Now!
              </Button>
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
}
