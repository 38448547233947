import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { MdOutlineInfo } from "react-icons/md";
import CopyGptLink from "./GptMenu/CopyGptLink";
import { useDispatch } from "react-redux";
import { FiFlag } from "react-icons/fi";
import { BsChatSquareText } from "react-icons/bs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  sendReportGPT,
  showGPTDetails,
  showGPTReview,
} from "../../store/bot/actions";
import AddGptSidebar from "./GptMenu/AddGptSidebar";
import AlertDeleteBotChat from "../GPTChat/alertDeleteChat";

const GPTSettings = () => {
  const dispatch = useDispatch();

  const toggleReport = () => {
    dispatch(sendReportGPT(true));
  };
  const toggleDetails = () => {
    dispatch(showGPTDetails(true));
  };

  const toggleReview = () => {
    dispatch(showGPTReview(true));
  };

  const MenuListData = [
    {
      title: "Clear Bot Chat",
      component: <AlertDeleteBotChat />,
    },
    {
      title: "About",
      component: (
        <span
          className="flex justify-start items-center gap-2 cursor-pointer "
          onClick={toggleDetails}
        >
          <MdOutlineInfo className="text-base" /> About
        </span>
      ),
    },
    {
      title: "Keep in sidebar",
      component: <AddGptSidebar />,
    },

    {
      title: "Review GPT",
      component: (
        <span
          className="flex justify-start items-center gap-2 cursor-pointer "
          onClick={toggleReview}
        >
          <BsChatSquareText className="text-base" /> Review GPT
        </span>
      ),
    },
    {
      title: "Report",
      component: (
        <span
          className="flex justify-start items-center gap-2 cursor-pointer "
          onClick={toggleReport}
        >
          <FiFlag className="text-base" /> Report
        </span>
      ),
    },
    {
      title: "Share",
      component: <CopyGptLink />,
    },
  ];

  return (
    <>
      <Menu
        placement="bottom-end"
        dismiss={{
          itemPress: false,
        }}
      >
        <MenuHandler>
          <div className="flex justify-start items-center gap-2 ">
            {/* <IoIosArrowDown fontSize="small" /> */}
            <KeyboardArrowDownIcon className="cursor-pointer" />
          </div>
        </MenuHandler>

        <MenuList>
          {MenuListData?.map((menu) => {
            return <MenuItem key={menu.title}>{menu.component}</MenuItem>;
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export default GPTSettings;
