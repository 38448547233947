import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import http from "../../services/http-common";
import { useMutation } from "react-query";
import { setSessionExpired, useMainController } from "../../context";
import { useTheme } from "@mui/material";
import FormattedResponse from "../FormattedResponse";
import UpgradePlan from "../Upgrade/UpgradePlan";
import { IoArrowBackOutline } from "react-icons/io5";
import { plansDetails } from "../../utils/PlanDetails";
import PricingSection from "../../pages/home/Pricing/PricingSection";

// Filter the plan with Name='EntheoPro'
const entheoProPlan = plansDetails.filter((plan) => plan.name === "EntheoPro");
const ShowSources = ({ questionId, question, message, refer }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openUpgrade, setOpenUpgrade] = React.useState(false);
  const { user } = useSelector((state) => state.auth);
  const [dispatch] = useMainController();

  const handleOpen = () => setOpen(!open);
  const handleUpgrade = () => {
    setOpenUpgrade(!openUpgrade);
  };
  const scrapeWebSiteContent = useMutation(
    (id) => {
      return http.post(
        `chat/email-references`,
        { chatId: id },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        setOpen(!open);
        toast.success(data.message);
      },
      onError: (err) => {
        errorFunc(err);
      },
    }
  );
  const errorFunc = (error) => {
    let message = error.response.data.message;
    toast.error(message);
  };
  const handleMailSources = (id) => {
    scrapeWebSiteContent.mutate(id);
  };

  return (
    <>
      <div className="flex justify-start  items-center">
        <Typography
          as="a"
          href="#"
          className={`text-sm font-semibold ${
            theme?.palette.mode === "light"
              ? "text-blue-gray-600"
              : "text-white"
          }`}
          onClick={handleOpen}
        >
          Refs: [{refer?.science.length + refer?.legal.length}]
        </Typography>
      </div>
      <Dialog
        open={open}
        size={openUpgrade ? "lg" : ""}
        handler={handleOpen}
        className={theme.palette.mode === "dark" && "!bg-[#282d3d]"}
        animate={{
          mount: { scale: 1, y: -20 },
          unmount: { scale: 0.9, y: 100 },
        }}
      >
        <div className="flex items-center justify-between ">
          <DialogHeader>
            {openUpgrade && (
              <span onClick={handleUpgrade}>
                <IoArrowBackOutline className="mr-3 h-5 w-5 cursor-pointer" />
              </span>
            )}

            <Typography
              variant="h6"
              sx={{ textDecoration: "none", color: "grey" }}
            >
              <span
                className={`font-bold ${
                  theme.palette.mode === "dark" && "text-[#e3e3e3]"
                }`}
              >
                {openUpgrade ? "Back to References" : " References"}
              </span>
            </Typography>
          </DialogHeader>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5 cursor-pointer"
            onClick={handleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <DialogBody
          divider
          className={`${
            openUpgrade ? "sm:h-auto max-h-[50rem]" : "max-h-[30rem]"
          } overflow-y-auto  ${
            theme.palette.mode === "dark" && "!bg-[#282d3d] border-y-[#363b4e] "
          }`}
          style={{ scrollbarColor: "#1a2035 #f3f6ff", scrollbarWidth: "thin" }}
        >
          {openUpgrade ? (
            <>
              <PricingSection plans={entheoProPlan} />

              <UpgradePlan open={openUpgrade} handleUpgrade={handleUpgrade} />
            </>
          ) : (
            <>
              <div className="grid gap-1 w-full ">
                <Typography
                  variant="paragraph"
                  className={`no-underline ${
                    theme.palette.mode === "dark"
                      ? "text-[#BDBDBD]"
                      : "text-grey"
                  }`}
                >
                  <span
                    className={`font-bold ${
                      theme.palette.mode === "dark" && "text-[#e3e3e3]"
                    }`}
                  >
                    {user.firstName}:{" "}
                  </span>
                  {parse(question.replace(/\n/g, "<br>"))}
                </Typography>
              </div>
              <br />

              <div className="grid gap-1 w-full ">
                <Typography
                  variant="paragraph"
                  className={`no-underline ${
                    theme.palette.mode === "dark"
                      ? "text-[#BDBDBD]"
                      : "text-grey"
                  }`}
                >
                  <span
                    className={`font-bold ${
                      theme.palette.mode === "dark" && "text-[#e3e3e3]"
                    }`}
                  >
                    EntheoGPT:{" "}
                  </span>
                  <FormattedResponse response={message} />
                  <br />
                  <span
                    className={`font-bold ${
                      theme.palette.mode === "dark" && "text-white"
                    }`}
                  >
                    Generated by{" "}
                    <a
                      href="https://entheogpt.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://entheogpt.com
                    </a>
                  </span>
                  <br />
                  <br />

                  <span
                    className={`font-bold ${
                      theme.palette.mode === "dark" && "text-white"
                    }`}
                  >
                    {" "}
                    References used:{" "}
                  </span>
                  <br />
                  {refer?.science.length > 0 && (
                    <>
                      <span
                        className={`font-bold underline ${
                          theme.palette.mode === "dark" && "text-white"
                        }`}
                      >
                        Science:{" "}
                      </span>

                      <div className="ps-2">
                        {refer?.science.map((item, index) => {
                          return (
                            <>
                              <Typography
                                component={`${item.url ? "a" : "p"}`}
                                href={item.url}
                                sx={{
                                  textDecoration: "none",
                                  color: "grey",
                                  wordWrap: "break-word",
                                }}
                                key={index}
                              >
                                <span onClick={handleUpgrade}>
                                  <Typography
                                    sx={{
                                      width: "100%",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {index + 1 + ". "}
                                    {item.title}

                                    {item.author && " by " + item.author}

                                    {(item.page || item.section) && (
                                      <>
                                        {". ( "}
                                        {typeof item.page === "string" ? (
                                          <>
                                            {item.page
                                              ?.toString()
                                              .split(",")
                                              .map((itm, idx) => (
                                                <React.Fragment key={idx}>
                                                  {`${
                                                    itm !== "undefined"
                                                      ? `p. ${itm}`
                                                      : ""
                                                  } sec. ${
                                                    item.section
                                                      .toString()
                                                      .split(",")[idx]
                                                  } ${
                                                    item.section
                                                      .toString()
                                                      .split(",").length !==
                                                    idx + 1
                                                      ? ","
                                                      : ""
                                                  } `}
                                                </React.Fragment>
                                              ))}
                                          </>
                                        ) : (
                                          <>
                                            {(item.page || item.section) && (
                                              <>
                                                {`${
                                                  item.page
                                                    ? "p." + item.page + " "
                                                    : ""
                                                }${
                                                  item.section &&
                                                  "sec." + item.section
                                                }`}
                                              </>
                                            )}
                                          </>
                                        )}

                                        {") "}
                                      </>
                                    )}
                                  </Typography>
                                </span>
                              </Typography>
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
                  {refer?.legal.length > 0 && (
                    <>
                      <span
                        className={`font-bold underline ${
                          theme.palette.mode === "dark" && "text-white"
                        }`}
                      >
                        Legal:{" "}
                      </span>

                      <div className="ps-2">
                        {refer?.legal.map((item, index) => {
                          return (
                            <>
                              <Typography
                                component={`${item.url ? "a" : "p"}`}
                                href={item.url}
                                sx={{
                                  textDecoration: "none",
                                  color: "grey",
                                  wordWrap: "break-word",
                                }}
                                key={index}
                              >
                                <span onClick={handleUpgrade}>
                                  <Typography
                                    sx={{
                                      width: "100%",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {index + 1 + ". "}
                                    {item.title}

                                    {item.author && " by " + item.author}

                                    {(item.page || item.section) && (
                                      <>
                                        {". ( "}
                                        {typeof item.page === "string" ? (
                                          <>
                                            {item.page
                                              ?.toString()
                                              .split(",")
                                              .map((itm, idx) => (
                                                <React.Fragment key={idx}>
                                                  {`${
                                                    itm !== "undefined"
                                                      ? `p. ${itm}`
                                                      : ""
                                                  } sec. ${
                                                    item.section
                                                      .toString()
                                                      .split(",")[idx]
                                                  } ${
                                                    item.section
                                                      .toString()
                                                      .split(",").length !==
                                                    idx + 1
                                                      ? ","
                                                      : ""
                                                  } `}
                                                </React.Fragment>
                                              ))}
                                          </>
                                        ) : (
                                          <>
                                            {(item.page || item.section) && (
                                              <>
                                                {`${
                                                  item.page
                                                    ? "p." + item.page + " "
                                                    : ""
                                                }${
                                                  item.section &&
                                                  "sec." + item.section
                                                }`}
                                              </>
                                            )}
                                          </>
                                        )}

                                        {") "}
                                      </>
                                    )}
                                  </Typography>
                                </span>
                              </Typography>
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
                </Typography>
              </div>
            </>
          )}
        </DialogBody>
        {!openUpgrade && (
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-1"
            >
              Close
            </Button>
            <Button
              variant="filled"
              className={`font-bold ${
                theme.palette.mode === "dark" && "!bg-[#f3f6ff] !text-[#1a2035]"
              }`}
              onClick={() => {
                handleMailSources(questionId);
              }}
            >
              Email this result
            </Button>
          </DialogFooter>
        )}
      </Dialog>
    </>
  );
};

export default ShowSources;
