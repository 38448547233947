import { Box, Button, IconButton } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
// import { StopCircleIcon } from "@heroicons/react/24/outline";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FilterSearch from "./components/chat/FilterSearch";
import ContentEditable from "react-contenteditable";
import { XMarkIcon } from "@heroicons/react/24/solid";

import {
  BotMessage,
  BotThinking,
  HumanMessage,
} from "./components/chat/message";
import MagicMycQResponse from "./components/faqs/MagicMycQResponse";
import { Dialog, DialogBody } from "@material-tailwind/react";

const ChatUI = () => {
  const { model } = useSelector((state) => state.dataFlow);
  const [isMaximizedChat, setIsMaximizedChat] = useState(false);
  const [streamingMsg, setStreamingMsg] = useState("");
  const [streamingIntent, setStreamingIntent] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { sidebarWeb } = useSelector((state) => state.dataFlow);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const chatMessagesRef = useRef(null);
  const socketRef = useRef(null);
  const inputBoxRef = useRef(null);

  const connectSocket = () => {
    if (socketRef.current) {
      socketRef.current.close();
    }

    socketRef.current = new WebSocket("wss://api.entheogpt.com/ws");

    socketRef.current.onopen = () => {
      // Request chat history on connection
      socketRef.current.send(
        JSON.stringify({
          type: "get_chat_history",
          kcId: user.id,
          finalId: "last",
          fName: user.firstName,
          // intent: docType,
        })
      );
    };

    socketRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "chat_history") {
        setMessages(
          message.data.map((msg) => ({
            content: msg.content,
            role: msg.role,
            feedback: msg.feedback,
            id: msg.id,
            isApproved: msg.isApproved,
            kcId: msg.kcId,
            refer: msg.refer ? JSON.parse(msg.refer) : null,
          }))
        );
      } else if (message.type === "bot_message") {
        setLoading(false);
        setIsStreaming(true);
        appendStreamMessage(message.content);
      } else if (message.type === "statusMessage") {
        setStreamingMsg(message.message);
      } else if (message.type === "intent") {
        setStreamingIntent(message.intent);
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          if (updatedMessages.length > 0) {
            updatedMessages[updatedMessages.length - 1] = {
              ...updatedMessages[updatedMessages.length - 1],
              intent: message.intent,
            };
          }
          return updatedMessages;
        });
        if (
          [
            "book_appointment",
            "prescreening",
            "psiloscreen",
            "handle_entheodna",
            "post_treatment_support",
            "handle_billing",
            "technical_support",
            "privacy_security",
            "submit_feedback",
          ].includes(message.intent)
        ) {
          setLoading(false);
          setIsStreaming(false);
          socketRef.current.close();
        }
      } else if (message.type === "saved-messages") {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          if (updatedMessages.length > 0) {
            updatedMessages[updatedMessages.length - 1] = {
              content: message.messages[1].content,
              feedback: message.messages[1].feedback,
              id: message.messages[1].id,
              isApproved: message.messages[1].isApproved,
              kcId: message.messages[1].kcId,
              refer: JSON.parse(message.messages[1].refer),
              role: "assistant",
            };
          }
          return updatedMessages;
        });
        setIsStreaming(false);
      }
    };
  };

  useEffect(() => {
    connectSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  const sendMessage = (value) => {
    const message = value.trim();
    setLoading(true);
    if (message) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: message, role: "user" },
      ]);

      if (socketRef.current) {
        if (socketRef.current.readyState === WebSocket.OPEN) {
          socketRef.current.send(
            JSON.stringify({
              kcId: user.id,
              prompt: message,
              model: "dolphin-llama3",
              fName: user.firstName,
            })
          );
        } else {
          console.error(
            "WebSocket is not open. Ready state: ",
            socketRef.current.readyState
          );
          connectSocket();
          socketRef.current.onopen = () => {
            socketRef.current.send(
              JSON.stringify({
                kcId: user.id,
                prompt: message,
                model: "dolphin-llama3",
                fName: user.firstName,
              })
            );
          };
        }
      } else {
        console.error("WebSocket is not initialized.");
        connectSocket();
        socketRef.current.onopen = () => {
          socketRef.current.send(
            JSON.stringify({
              kcId: user.id,
              prompt: message,
              model: "dolphin-llama3",
              fName: user.firstName,
            })
          );
        };
      }

      setInputValue("");
      startBotResponse();
    }
  };

  const startBotResponse = () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { content: "", role: "bot" },
    ]);
  };

  const appendStreamMessage = (content) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      if (lastMessage.role === "bot") {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = {
          ...lastMessage,
          content: lastMessage.content + content,
        };
        return updatedMessages;
      }
      return prevMessages;
    });
  };

  const handleChange = (evt) => {
    const value = evt.target.value.replace(/<\/?[^>]+(>|$)/g, "");
    setInputValue(value);
  };

  const deleteAllMessages = () => {
    setMessages([(prevMessages) => prevMessages.slice(0, 1)]);
  };

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) {
          document.execCommand("insertLineBreak");
        } else {
          sendMessage(inputValue);
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [sendMessage]);

  return (
    <>
      <Box
        id="scrollableDiv"
        className="max-h-[calc(100vh-230px)]  md:max-h-[calc(100vh-220px)] lg:max-h-[calc(100vh-205px)] xl:max-h-[calc(100vh-205px)] "
        sx={{
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column-reverse",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <div
          ref={chatMessagesRef}
          className="flex-1 p-3 overflow-y-auto flex flex-col space-y-2"
        >
          {messages.map((_item, index) => {
            if (_item.role === "user") {
              return <HumanMessage key={index} message={_item.content} />;
            } else {
              return (
                <>
                  {index === messages.length - 1 && loading ? (
                    <BotThinking key={index} text={streamingMsg} />
                  ) : (
                    <BotMessage
                      questionId={_item?.id && _item?.id}
                      key={index}
                      model={_item?.model ? _item?.model : null}
                      tokens={_item?.tokens ? _item?.tokens : null}
                      question={_item?.question ? _item?.question : ""}
                      trigger={_item?.intent ? _item?.intent : ""}
                      messageIndex={index}
                      message={_item.content}
                      feedback={_item.feedback}
                      refer={_item.refer}
                      isStreaming={isStreaming}
                    />
                  )}
                </>
              );
            }
          })}
        </div>
      </Box>
      <Box
        sx={{
          position: { xs: "fixed", lg: sidebarWeb ? "fixed" : "absolute" },
          bottom: 5,
          minHeight: "90px",
          width: "-webkit-fill-available",
          paddingRight: "40px",
          bgcolor: "background.default",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <Box>
          {!isStreaming && messages?.length > 0 && (
            <>
              <MagicMycQResponse sendMessage={sendMessage} />
            </>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: isMaximizedChat ? `column` : "row",
              alignItems: "center",
              position: "relative",
            }}
          >
            {!isMaximizedChat && (
              <FilterSearch deleteAllMessages={deleteAllMessages} />
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
              className="w-full ms-2"
            >
              <ContentEditable
                className={`content-editable  !p-[12px_24px] !rounded-md ${
                  isStreaming && "cursor-wait"
                }  `}
                data-text="Send a message"
                innerRef={inputBoxRef}
                html={inputValue}
                onChange={handleChange}
              />
              <div className="absolute right-1">
                <FullscreenIcon
                  className="cursor-pointer"
                  color="primary"
                  onClick={() => {
                    setIsMaximizedChat(!isMaximizedChat);
                  }}
                />
                <IconButton
                  aria-label="Submit"
                  disabled={inputValue.length > 0 ? false : true}
                  onClick={() => sendMessage(inputValue)}
                >
                  <SendIcon
                    className={
                      inputValue.length > 0
                        ? "!cursor-pointer"
                        : "!cursor-not-allowed"
                    }
                    color="primary"
                  />
                </IconButton>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        size="xl"
        open={isMaximizedChat}
        handler={() => {
          setIsMaximizedChat(!isMaximizedChat);
        }}
      >
        <DialogBody>
          <span className="flex justify-end items-center mb-2 cursor-pointer">
            <XMarkIcon
              className="w-5 h-5 "
              onClick={() => {
                setIsMaximizedChat(!isMaximizedChat);
              }}
            />
          </span>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMaximizedChat ? `column` : "row",
              alignItems: "center",
              position: "relative",
            }}
          >
            {!isMaximizedChat && <FilterSearch />}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
              className="w-full ms-2"
            >
              <ContentEditable
                className={`content-editable ${
                  isMaximizedChat && "!min-h-[48rem]"
                } !p-[12px_24px] !rounded-md ${isStreaming && "cursor-wait"} `}
                data-text="Send a message"
                innerRef={inputBoxRef}
                html={inputValue}
                onChange={handleChange}
              />
            </Box>
            {isMaximizedChat && (
              <div className="self-end mt-2">
                <Button
                  size="small"
                  aria-label="Submit"
                  variant="contained"
                  onClick={() => sendMessage(inputValue)}
                  endIcon={<SendIcon />}
                >
                  Send
                </Button>
              </div>
            )}
          </Box>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ChatUI;
