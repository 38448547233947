export const formatResponse = (text) => {


text = text?.replace(/([\w+]+\:\/\/)?([\w\d-]+\.)*([\w-]+)([\.\:])(\w+)([\/\?\=\&\#.]?[\w-]+)*(?!\)\[)\/?/gm, (match, p1, p2, p3, p4, p5, p6) => {
  // If p1 (protocol) is missing, default to "https://"
  let protocol = p1 ? p1 : "https://";
  return `<a class="font-bold" style="color:#000080;" href="${protocol}${p2 ? p2 : ""}${p3}${p4}${p5}${p6 ? p6 : ""}" target="_blank">${p1 ? p1 : ''}${p2 ? p2 : ""}${p3}${p4}${p5}${p6 ? p6 : ""}</a>`;
});

  // 1 **Text**:
  text = text?.replace(
    // /(\d+\.\s[^-]+-|\d+\.\s\*\*[\w\s\-]+?\*\*:)/g,
    // eslint-disable-next-line no-useless-escape
    /(\d+).\s\*\*([^\*]+?):?\*\*:?/g,
    '<h3 class="text-xl font-bold mt-3">$1. $2:</h3>'
  );
  
  
  // text = text?.replace(/(\d+)\.\s* /gm, "\n$1. ");
  
  // text = text?.replace(/(\d+\.[^\d])/g, "<br>$1");
   text = text?.replace(/(\d+)\.\s\*\*(.*?)\*\*/gm, `<h2 class="text-lg font-bold mt-4">$1. $2</h2>`);

   
   // Convert numbered headings (e.g., 7. Integrate Your Insights:) to <h2>
// text = text?.replace(/(\d+)\.\s([^:\n]+)( -|:)/gm, '<h2 class="text-xl font-bold mt-4">$1. $2</h2>');
text = text?.replace(/(\d+)\.\s([^:\n]+)( -| :)/gm, '<h2 class="text-xl font-bold mt-4">$1. $2</h2>');

// Convert unordered lists (- Item) to Tailwind-styled lists
text = text?.replace(/- (.*)$/gm, '<li class="list-disc list-inside mb-0">$1</li>');

// Wrap <li> items inside <ul>
text = text?.replace(/(<li class="list-disc list-inside mb-0">.*<\/li>)/g, '<ul class="">$1</ul>');



// Replace [Text](URL) pattern  with anchor tag
text = text?.replace(/\[([^\]]+)\]\((<a [^>]+>)([^<]+)(<\/a>)\)/g, '$2$1$4');

text = text?.replace(`<a class="font-bold" style="color:#000080;" href="https://e.g" target="_blank">e.g</a>`, 'e.g');
text = text?.replace(`<a class="font-bold" style="color:#000080;" href="https://e.i" target="_blank">e.g</a>`, 'e.i');
text = text?.replace(/<a[^>]*?href="https:\/\/(\d+\.\d+(?:-\d+\.\d+)?)"[^>]*?>\1<\/a>/g, '$1');
text = text?.replace(/\n+/g, '<br>');

// Replace [Text](URL) pattern  with anchor tag
// text = text?.replace(
//   /\[([^\]]+)]\((https?:\/\/[^\)]+)\)/gm,
//   '<a class="font-bold" style="color:#000080; " href="$2" target="_blank">$1</a> '
// );

 // Convert bold text with Tailwind CSS
  text = text?.replace(
    /\*\*(.*?)\*\*/g,
    '<strong class="font-semibold">$1</strong>'
  );

   
  return text;
};
