import {
  Tab,
  TabPanel,
  Tabs,
  TabsHeader,
  TabsBody,
} from "@material-tailwind/react";
import { PromptEditor, GreetingMessageEditor } from ".";
import SearchPromptEditor from "./searchPromptEditor copy";

export function OtherAdmin() {
  const data = [
    {
      label: "System Editor Prompt",
      value: "prompt-editor",
      desc: <PromptEditor />,
    },
    {
      label: "Greeting Message",
      value: "greeting-message-editor",
      desc: <GreetingMessageEditor />,
    },
    {
      label: "Search Prompt",
      value: "search-editor",
      desc: <SearchPromptEditor />,
    },
  ];
  window.document.title = "EntheoGPT | Admin | OtherAdmin";
  return (
    <Tabs value="prompt-editor" orientation="horizontal">
      <TabsHeader className="justify-evenly ">
        {data.map(({ label, value }) => (
          <Tab key={value} value={value} className="max-w-[200px]">
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value} className="py-2">
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

export default OtherAdmin;
