import {
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveRefsCountForChat } from "../../store/dataFlow/actions";
import UserService from "../../KeyCloackServices/UserService";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
const ChatSettings = () => {
  const dispatch = useDispatch();
  const { noOfRefsChat } = useSelector((state: any) => state.dataFlow);

  useEffect(() => {
    !noOfRefsChat && dispatch(saveRefsCountForChat(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Menu
        dismiss={{
          itemPress: false,
        }}
      >
        <MenuHandler>
          <IconButton
            aria-label="Refs"
            className="flex sm:flex-row flex-col-reverse"
          >
            {/* <FormatQuoteIcon color="primary" /> */}
            <span className="inline-flex items-center justify-center w-6 h-6  text-sm font-semibold text-white bg-[#000080] rounded-full ">
              {noOfRefsChat}
            </span>
            <KeyboardDoubleArrowUpIcon color="primary" />
          </IconButton>
        </MenuHandler>
        <MenuList>
          <MenuItem>
            <div>
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-1 font-medium"
              >
                Select Refs
              </Typography>
              <div className="relative ">
                <Input
                  type="number"
                  value={noOfRefsChat}
                  onChange={(e) =>
                    dispatch(saveRefsCountForChat(Number(e.target.value)))
                  }
                  className="!border-t-blue-gray-200 text-center placeholder:text-blue-gray-300 placeholder:opacity-100  focus:!border-t-gray-900 appearance-none [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  containerProps={{
                    className: "min-w-0",
                  }}
                  crossOrigin={undefined}
                />
                <div className="absolute left-1 top-1 flex gap-0.5">
                  <IconButton
                    onClick={() =>
                      dispatch(
                        saveRefsCountForChat(
                          Number(noOfRefsChat === 0 ? 0 : noOfRefsChat - 1)
                        )
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      className="h-4 w-4"
                    >
                      <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
                    </svg>
                  </IconButton>
                </div>
                <div className="absolute right-1 top-1 flex gap-0.5">
                  <IconButton
                    onClick={() =>
                      dispatch(
                        saveRefsCountForChat(
                          Number(
                            noOfRefsChat >=
                              (UserService.getRole()?.roles?.includes("admin")
                                ? 15
                                : 5)
                              ? noOfRefsChat
                              : noOfRefsChat + 1
                          )
                        )
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      className="h-4 w-4"
                    >
                      <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
                    </svg>
                  </IconButton>
                </div>
              </div>
            </div>
          </MenuItem>
          {/* ))} */}
        </MenuList>
      </Menu>
    </>
  );
};

export default ChatSettings;
