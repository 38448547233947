import * as actionTypes from "../constants";
export function botRegister(botInfo: any, navigate: any, helpers: any) {
  return {
    type: actionTypes.REGISTER_BOT_REQUEST,
    payload: botInfo,
    navigate,
    helpers,
  };
}
export function updateMycBot(botInfo: any, navigate: any, helpers: any) {
  return {
    type: actionTypes.UPDATE_BOT_MYC_REQUEST,
    payload: botInfo,
    navigate,
    helpers,
  };
}

export function getBotDataMyc(): {
  type: string;
} {
  return {
    type: actionTypes.GET_BOT_DATA_MYC_REQUEST,
  };
}

export function handleUploadLogo(
  file: any,
  callBack: any
): {
  type: string;
  payload: any;
  callBack: any;
} {
  return {
    type: actionTypes.UPLOAD_IMAGE_REQUEST,
    payload: file,
    callBack,
  };
}

export function handleApplyforPro(callBack: any): {
  type: string;
  callBack: any;
} {
  return {
    type: actionTypes.UPGRADE_TO_PRO_REQUEST,
    callBack,
  };
}

// ----------------------------------------------
export function getAllGpts(): {
  type: string;
} {
  return {
    type: actionTypes.GET_ALL_GPTS_REQUEST,
  };
}
export function getAllFavGpts(): {
  type: string;
} {
  return {
    type: actionTypes.GET_ALL_FAV_GPTS_REQUEST,
  };
}

export function addFavGpts(botId: any): {
  type: string;
  payload: any;
} {
  return {
    type: actionTypes.ADD_FAV_GPTS_REQUEST,
    payload: botId,
  };
}

export function getGptDetails(botUniqueName: any): {
  type: string;
  payload: any;
} {
  return {
    type: actionTypes.GET_BOT_DATA_REQUEST,
    payload: botUniqueName,
  };
}

export function saveBotData(botData: any): {
  type: string;
  payload: any;
} {
  return {
    type: actionTypes.SAVE_BOT_DATA,
    payload: botData,
  };
}

export function getBotChatHistory(chatId: any): {
  type: string;
  payload: any;
} {
  return {
    type: actionTypes.GET_BOT_CHAT_HISTORY_REQUEST,
    payload: chatId,
  };
}
export function getBotAnswer(data: any): { type: string; payload: object } {
  return {
    type: actionTypes.GET_BOT_ANSWER_REQUEST,
    payload: data,
  };
}
export function getBotAnswerAgain(data: any): {
  type: string;
  payload: object;
} {
  return {
    type: actionTypes.GET_BOT_ANSWER_AGAIN_REQUEST,
    payload: data,
  };
}

export function deleteBotChatHistory(botId: any): {
  type: string;
  payload: any;
} {
  return {
    type: actionTypes.DELETE_BOT_CHAT_HISTORY_REQUEST,
    payload: botId,
  };
}

export function sendReportGPT(value: any): {
  type: string;
  payload: any;
} {
  return {
    type: actionTypes.IS_REPORTING_GPT,
    payload: value,
  };
}

export function showGPTDetails(value: any): {
  type: string;
  payload: any;
} {
  return {
    type: actionTypes.SHOW_GPT_DETAILS,
    payload: value,
  };
}

export function showGPTReview(value: any): {
  type: string;
  payload: any;
} {
  return {
    type: actionTypes.SHOW_GPT_REVIEW,
    payload: value,
  };
}
export function updateBotChatStreaming(status: boolean = false): {
  type: string;
  payload: boolean;
} {
  return {
    type: actionTypes.BOT_CHAT_STREAMING,
    payload: status,
  };
}
export const getBotDetails = () => ({
  type: actionTypes.GET_BOT_DETAILS_REQUEST,
});

export function updateBot(botInfo: any, navigate: any, helpers: any) {
  return {
    type: actionTypes.UPDATE_BOT_REQUEST,
    payload: botInfo,
    navigate,
    helpers,
  };
}
