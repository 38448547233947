import React from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  // Link
} from "@mui/material";
// import { useNavigate } from 'react-router-dom';

import useStyles from "../styles/styles";

import Shape from "../components/Shape";
const PricePlans = () => {
  // const navigate = useNavigate();
  const classes = useStyles();

  const clases = {
    header2: {
      fontSize: { xs: "7.63vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: { md: "3vw", xs: "0" },
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      my: { xs: "5vw", md: "0" },
      // textAlign: { xs: 'center', md: 'left' },
    },
    buttonSx: {
      width: { md: "22.48vw", xs: "39.95vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: "2vw",
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
      textAlign: "left",
    },
  };
  return (
    <Grid
      container
      className={classes.sectionBox}
      sx={{ background: "#F7F9FF" }}
    >
      <Shape secNumber="3" />
      <Grid
        container
        className={classes.containerGrid}
        sx={{ py: { xs: "16.03vw", md: "3vw" } }}
      >
        <Container>
          <Grid
            item
            className={classes.flexCenter}
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <Grid item>
              <Box>
                <Typography sx={clases.contentText} textAlign={"center"}>
                  Explore, learn, and connect with experts in the field of
                  entheogenic therapies through{" "}
                  <span className="font-bold">EntheoGPT</span>. Whether you're
                  just starting your journey or seeking deeper insights, our
                  flexible plans provide the resources and support you need.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid
            item
            className={`${classes.flexCenter} !mt-4`}
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <Grid item>
              <p className="mt-2 text-4xl font-bold text-center text-gray-900 mb-4 dark:text-gray-200">
                Choose the Plan That's Right for You
              </p>
              <Box>
                <Typography sx={clases.contentText} textAlign={"center"}>
                  Our plans cater to all levels of engagement, from free access
                  with <span className="font-bold">EntheoGPT</span> to premium
                  plans offering comprehensive features with{" "}
                  <span className="font-bold">EntheoGPT+</span> ,{" "}
                  <a
                    href="http://entheo.pro"
                    target="_blank"
                    rel="noreferrer"
                    className="underline text-[#000080] font-bold"
                  >
                    EntheoPro
                  </a>{" "}
                  , and{" "}
                  <a
                    href="http://entheo.pro"
                    target="_blank"
                    rel="noreferrer"
                    className="underline text-[#000080] font-bold"
                  >
                    EntheoLaw
                  </a>{" "}
                  .
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default PricePlans;
