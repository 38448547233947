import * as actionTypes from "../constants";

export function updateDataFlow(data: string): {
  type: string;
  payload: string;
} {
  return {
    type: actionTypes.CHANGE_DATA_FLOW,
    payload: data,
  };
}

export function updateModelSelection(data: any): {
  type: string;
  payload: string;
} {
  return {
    type: actionTypes.MODEL_SELECTION,
    payload: data,
  };
}
export function saveSuggestedQuestions(data: any): {
  type: string;
  payload: string;
} {
  return {
    type: actionTypes.SAVE_SUGGESTED_QUESTIONS,
    payload: data,
  };
}
export function showSideBarWeb(data: any): {
  type: string;
  payload: string;
} {
  return {
    type: actionTypes.SHOW_SIDEBAR_WEB,
    payload: data,
  };
}
export function saveStatistics(data: any): {
  type: string;
  payload: string;
} {
  return {
    type: actionTypes.GET_STATISTICS,
    payload: data,
  };
}

export function saveTokenSummary(data: any): {
  type: string;
  payload: string;
} {
  return {
    type: actionTypes.SET_TOKENS_SUMMARY,
    payload: data,
  };
}
export function saveRefsCountForChat(data: any): {
  type: string;
  payload: string;
} {
  return {
    type: actionTypes.SET_REFERENCES_CHAT,
    payload: data,
  };
}

export function setIsSubscriptionAvailable(data: any) {
  return {
    type: actionTypes.IS_SUBSCRIPTION_AVAILABLE,
    payload: data,
  };
}

export function saveMsgRefs(data: any) {
  return {
    type: actionTypes.SELECTED_MSG_REFS,
    payload: data,
  };
}

export function searchFilters(data: any) {
  return {
    type: actionTypes.SEARCH_FILTERS,
    payload: data,
  };
}
export function saveBotStarters(data: any) {
  return {
    type: actionTypes.SET_BOT_STARTERS,
    payload: data,
  };
}
