import { useEffect, useState } from "react";
import http from "../../services/http-common";
import { useMutation } from "react-query";
import * as messages from "../../services/messages";
import { toast } from "react-toastify";
import {
  Typography,
  Tooltip,
  IconButton,
  Button,
  Card,
  CardBody,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Chip,
} from "@material-tailwind/react";
import { FaTrashAlt } from "react-icons/fa";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import {
  dateFormat,
  dateFormatForInput,
  timeAgo,
} from "../../utils/dateFormat";
import ExportCSV from "./ExportCSV";
import AddCoupon from "./AddCoupon";
import EdtCoupon from "./EdtCoupon";

const status = {
  ACTIVE: "green",
  INACTIVE: "cyan",
  EXPIRED: "red",
};

const CouponManagement = () => {
  const theme = useTheme();
  const [allCoupons, setAllCoupons] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");

  const fetchAllCoupons = useMutation(
    (filter) => {
      return http.post("coupon/fetch", { status: filter });
    },
    {
      onSuccess: ({ data }) => {
        setAllCoupons(data);
        setLoading(false);
      },
      onError: (error) => {
        console.error(error);
        setLoading(false);
      },
    }
  );

  const getCoupons = () => {
    setLoading(true);
    fetchAllCoupons.mutate("");
  };
  const handleFilter = (filter) => {
    setFilter(filter);
    setLoading(true);
    fetchAllCoupons.mutate(filter);
  };

  const deleteCoupon = useMutation(
    async (couponId) => {
      return await http.deleteWithBody(`coupon/delete/`, {
        data: {
          id: couponId,
        },
      });
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Successfully Deleted!");
        getCoupons();
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const onDeleteCoupon = (id) => {
    deleteCoupon.mutate(id);
  };

  function statusIndicator(text) {
    return status[text] || "gray";
  }

  useEffect(() => {
    getCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex w-full justify-center">
        <div className="mt-5 flex w-full flex-col gap-4">
          {/* <div className="mt-5">
            <table className="mt-4 w-full min-w-max table-auto text-left">
              <tbody>
                {allCoupons && <CouponList couponsList={allCoupons?.coupons} />}
              </tbody>
            </table>
          </div> */}

          <Card className="w-full  shadow-lg">
            <CardBody>
              <div className="mb-3 flex items-center justify-between">
                <Typography
                  variant="h5"
                  color="blue-gray"
                  className="font-medium"
                >
                  Coupon Management
                </Typography>
                <div className="flex items-center gap-2">
                  <ExportCSV data={allCoupons && allCoupons?.coupons} />

                  <Menu>
                    <MenuHandler>
                      <Button
                        size="sm"
                        variant="outlined"
                        className="flex justify-between  items-center gap-2 rounded-md normal-case"
                      >
                        {filter ? filter : "Filter"}
                        <ChevronUpIcon
                          strokeWidth={2.5}
                          className={`h-5 w-5 transition-transform rotate-180  `}
                        />
                      </Button>
                    </MenuHandler>
                    <MenuList>
                      <MenuItem onClick={() => handleFilter("")}>ALL</MenuItem>
                      <MenuItem onClick={() => handleFilter("ACTIVE")}>
                        Active
                      </MenuItem>
                      <MenuItem onClick={() => handleFilter("INACTIVE")}>
                        INACTIVE
                      </MenuItem>
                      <MenuItem onClick={() => handleFilter("EXPIRED")}>
                        Expired
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <AddCoupon fetchAllCoupons={getCoupons} />
                </div>
              </div>
              <TableContainer sx={{ minWidth: 720 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Coupon code</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Date of creation</TableCell>
                      <TableCell align="center">Expiration</TableCell>
                      <TableCell align="center">Redemption</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <>
                        {[1, 2, 3].map((row) => (
                          <TableRow>
                            <TableCell>
                              <Typography
                                as="div"
                                variant="h1"
                                className="h-3 w-56 rounded-full bg-gray-300"
                              >
                                &nbsp;
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                as="div"
                                variant="h1"
                                className="h-3 w-14 rounded-full bg-gray-300"
                              >
                                &nbsp;
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                as="div"
                                variant="h1"
                                className="h-3 w-14 rounded-full bg-gray-300"
                              >
                                &nbsp;
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                as="div"
                                variant="h1"
                                className="h-3 w-14 rounded-full bg-gray-300"
                              >
                                &nbsp;
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                as="div"
                                variant="h1"
                                className="h-3 w-14 rounded-full bg-gray-300"
                              >
                                &nbsp;
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {/* <MoreMenuButton /> */}
                              <Typography
                                as="div"
                                variant="h1"
                                className="h-3 w-14 rounded-full bg-gray-300"
                              >
                                &nbsp;
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        {allCoupons &&
                          allCoupons?.coupons.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.code}</TableCell>
                              <TableCell>
                                <Chip
                                  className=" rounded-full text-center "
                                  size="sm"
                                  color={statusIndicator(row.status)}
                                  value={
                                    <Typography
                                      variant="small"
                                      color="white"
                                      className="font-medium capitalize leading-none"
                                    >
                                      {row.status}
                                    </Typography>
                                  }
                                />
                              </TableCell>
                              <TableCell align="center">
                                {timeAgo(row.created_at)}
                              </TableCell>
                              <TableCell align="center">
                                {row?.expiryDate
                                  ? dateFormatForInput(row.expiryDate)
                                  : "Never"}
                              </TableCell>
                              <TableCell align="center">
                                {row.redemptions}
                              </TableCell>
                              <TableCell align="center">
                                {/* <MoreMenuButton /> */}
                                <EdtCoupon
                                  fetchAllCoupons={getCoupons}
                                  data={row}
                                />

                                <Tooltip content="Delete">
                                  <IconButton
                                    variant="text"
                                    onClick={() => {
                                      onDeleteCoupon(row.id);
                                    }}
                                  >
                                    <FaTrashAlt
                                      color={
                                        theme.palette.mode === "dark" &&
                                        "#e70b25"
                                      }
                                      className={`h-4 w-4 `}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default CouponManagement;
