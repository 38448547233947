import { Grid, Typography, Box, Container } from "@mui/material";

import useStyles from "../styles/styles";

import Shape from "../components/Shape";

const Tokens = () => {
  // const navigate = useNavigate();
  const classes = useStyles();

  const clases = {
    header2: {
      fontSize: { xs: "7vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: { md: "3.63vw", xs: "0" },
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    header4: {
      fontSize: { xs: "7.63vw", md: "2.08vw !important" },
      fontWeight: "600 !important",
      // letterSpacing: "-1.33px !important",
      color: "#101010 !important",
      paddingY: { md: "0", xs: "0" },
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      my: { xs: "5vw", md: "1vw" },
      // textAlign: { xs: 'center', md: 'left' },
    },
    buttonSx: {
      width: { md: "18vw", xs: "78.63vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
      my: { xs: "9vw", md: "0" },
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
      textAlign: "left",
    },
  };

  return (
    <>
      <Grid
        className={classes.sectionBox}
        id="gptStore"
        sx={{ background: "#f7f9ff" }}
      >
        <Shape secNumber="6" />

        <Container>
          <Grid
            container
            className={classes.containerGrid}
            sx={{
              paddingBottom: "2vw",
              pt: { xs: "17.3vw", md: "3.07vw" },
            }}
          >
            <Grid item className={classes.flexCenter} xs={11} md={12}>
              <Typography sx={clases.header2}>What Is a Token?</Typography>
            </Grid>
            <Box
              className={classes.flexCenter}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Grid item>
                <Box>
                  <Typography sx={clases.contentText}>
                    A token is a unit used within the EntheoGPT platform to
                    access AI-driven insights and responses. Tokens allow you to
                    engage with the platform’s knowledge base and retrieve
                    research, guidance, and specialized information.
                  </Typography>
                  <ul className="list-disc px-3">
                    <li>
                      <Typography sx={clases.contentText}>
                        <span className="font-bold">1 Token</span> is as long as
                        a word or as short as a character like a period.
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={clases.contentText}>
                        <span className="font-bold">Example</span>: A simple
                        inquiry may require 500 tokens, while a more complex
                        question with multiple references may use up to 5,000
                        tokens.
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default Tokens;
