import { useEffect, useState } from "react";
import { Box, Divider, Grid, IconButton, Paper } from "@mui/material";
import SidebarSection from "./sidebar-section";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

// ** Import type
import { FC, ReactNode } from "react";

import EPImg from "../../assets/images/egpt-logo-social.png";
import LawImg from "../../assets/images/egpt-logo-law.svg";
import WisdomImg from "../../assets/images/egpt-logo-wisdom.svg";

import {
  DocumentIcon,
  UsersIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  SquaresPlusIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";

import UserService from "../../KeyCloackServices/UserService";
import { useDispatch, useSelector } from "react-redux";
import { showSideBarWeb } from "../../store/dataFlow/actions";
import FavGptsList from "../../pages/GptStore/FavGptsList";
import Subscription from "./Subscription/Subscription";
import { TicketIcon } from "@heroicons/react/24/solid";

const icon = {
  width: "30px",
};
interface Item {
  title: string;
  desc?: string;
  path?: string;
  permission?: string;
  icon: ReactNode;
  option?: ReactNode;
  setting?: ReactNode;
  link?: string;
}

interface Section {
  key: number;
  title?: string;
  item: Item[];
}

export const getSectionsAdmin = (): Section[] => [
  {
    key: 1,
    item: [
      {
        title: "Explore GPTs",
        path: "/gpts",
        icon: <Squares2X2Icon {...icon} />,
      },
      {
        title: "EntheoGPT Science",
        // desc: '12,262 documents',
        path: "/chat",
        icon: (
          <img
            src={EPImg}
            alt="bot logo"
            className="max-w-[40px] max-h-[40px]"
          />
        ),
      },
      {
        title: "EntheoGPT Law",
        // desc: '12,262 documents',
        path: "/chat-law",
        icon: (
          <img
            src={LawImg}
            alt="Entheo Law logo"
            className="max-w-[40px] max-h-[40px]"
          />
        ),
      },
      {
        title: "EntheoGPT Wisdom",
        // desc: '12,262 documents',
        path: "/chat-wisdom",
        icon: (
          <img
            src={WisdomImg}
            alt="Entheo Wisdom logo"
            className="max-w-[40px] max-h-[40px]"
          />
        ),
      },

      {
        title: "Documents",
        path: "/documents",
        icon: <DocumentIcon {...icon} />,
      },
      {
        title: "Websites",
        path: "/websites",
        icon: <GlobeAltIcon {...icon} />,
      },
      {
        title: "User",
        path: "/users",
        icon: <UsersIcon {...icon} />,
      },
    ],
  },
  {
    key: 2,
    title: "Settings",
    item: [
      {
        title: "System Settings",
        path: "/system-settings",
        icon: <SquaresPlusIcon {...icon} />,
      },
      {
        title: "Coupon Management",
        path: "/coupon-management",
        icon: <TicketIcon {...icon} />,
      },
      {
        title: "Suggestions",
        path: "/suggestions",
        icon: <Square3Stack3DIcon {...icon} />,
      },
      {
        title: "Submit Research",
        path: "/data",
        icon: <DocumentTextIcon {...icon} />,
      },
    ],
  },
];
export const getSettingData = (): Section[] => [
  {
    key: 1,
    item: [
      {
        title: "Logout",
        path: undefined,
        icon: <LogoutRoundedIcon />,
      },
    ],
  },
];
export const getChatBots = (): Section[] => [
  {
    key: 1,
    item: [
      {
        title: "Submit Research",
        path: "/data",
        icon: <DocumentTextIcon {...icon} />,
      },
      {
        title: "Explore GPTs",
        path: "/gpts",
        icon: <Squares2X2Icon {...icon} />,
      },
      {
        title: "EntheoGPT",
        // desc: '12,262 documents',
        path: "/chat",
        icon: (
          <img
            src={EPImg}
            alt="bot logo"
            className="max-w-[40px] max-h-[40px]"
          />
        ),
      },
      // {
      //   title: "EntheoGPT Law",
      //   // desc: '12,262 documents',
      //   path: "/chat-law",
      //   icon: (
      //     <img
      //       src={LawImg}
      //       alt="Entheo Law logo"
      //       className="max-w-[40px] max-h-[40px]"
      //     />
      //   ),
      // },
      // {
      //   title: "EntheoGPT Wisdom",
      //   // desc: '12,262 documents',
      //   path: "/chat-wisdom",
      //   icon: (
      //     <img
      //       src={WisdomImg}
      //       alt="Entheo Wisdom logo"
      //       className="max-w-[40px] max-h-[40px]"
      //     />
      //   ),
      // },
    ],
  },
];

interface SideBarProps {
  mode?: string;
}

const SideBar: FC<SideBarProps> = ({ mode }) => {
  const { sidebarWeb } = useSelector((state: any) => state.dataFlow);
  const dispatch = useDispatch();
  const sections = UserService.getRole()?.roles?.includes("admin")
    ? getSectionsAdmin()
    : getChatBots();

  const [buf, setBuf] = useState("");
  useEffect(() => {
    setBuf(mode === "drawer" ? "calc(100vh - 19rem )" : "calc(100vh - 260px)");
  }, [mode]);

  const handleSidebar = () => {
    sidebarWeb
      ? dispatch(showSideBarWeb(false))
      : dispatch(showSideBarWeb(true));
  };

  return (
    <>
      <Paper
        sx={{
          background: "background.default",
          border: "1px solid #B3B6DA",
          borderRadius: "18px",
          minHeight: "100%",
          position: "relative",
          minWidth: { xs: "80vw", md: "350px" },
        }}
      >
        <Grid
          item
          sx={{ display: { xs: "none", lg: "flex" } }}
          alignItems="end"
          justifyContent={"end"}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mx: 0, mr: 2, mb: 0, display: { xs: "none", lg: "flex" } }}
            onClick={() => {
              handleSidebar();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              className="icon-lg juice:mx-2.5 juice:text-token-text-secondary"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M3 8a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1m0 8a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1"
                clip-rule="evenodd"
              ></path>
            </svg>
          </IconButton>
        </Grid>
        {/* <Box
          className={"w-full flex justify-center items-center my-3"}
          sx={{ textAlign: "center", paddingTop: "8px" }}
        >
          <img
            src="/images/egpt-logo-wide-v2.png"
            alt="logo"
            className="sm:w-[250px] w-[150px]"
            style={{ height: "auto" }}
          />
        </Box> */}

        <Box
          sx={{
            maxHeight: buf,
            overflowY: "auto",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {sections.map((_item) => {
            return (
              <SidebarSection key={_item.key} item={_item}></SidebarSection>
            );
          })}
          <Divider />
          <FavGptsList />
        </Box>
        {/* <ProfileMenu sidebar={true} /> */}
        <Subscription sidebar={true} />
      </Paper>
    </>
  );
};

export default SideBar;
