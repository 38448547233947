import React from "react";
import { Alert } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { createSelector } from "reselect";
import imageCompression from "browser-image-compression";
import { Avatar, Button } from "@material-tailwind/react";
import Preview from "./Preview";
import { botRegister, handleUploadLogo } from "../../store/bot/actions";
import BotUpdates from "./BotUpdates";
import Bot from "./Bot";

const initialValues = {
  botName: "Your bot’s name",
  iconUrl: "",
  footerNote: "Contact us for help! support@entheo.info.",
  primaryDomain:
    "Expert in mushroom microdosing, plant medicines, kambo, somatic therapy, and ibogaine.",
  greeting:
    "Hello! I’m {bot_name}, an AI assistant here to help you. Please note that I am not a human, and all interactions are for informational and educational purposes only.",
  tone: "You are a warm, witty and friendly educator who is succinct and compassionate.",
  additionalCertification: "Tripsitter, Trauma informed therapist, MFT",
  specialInstruction:
    "Please do not share private information. For contact info or appointments, just ask!",
};

const validationSchema = Yup.object({
  botName: Yup.string()
    .min(1, "Must be at least 1 character")
    .max(20)
    .required("Required"),
  primaryDomain: Yup.string()
    .min(1, "Must be a at least 1 character")
    .max(255)
    .required("Required"),
  greeting: Yup.string()
    .min(2, "Must be at least 2 characters")
    .max(255)
    .required("Required"),
  tone: Yup.string().required("Required"),
  additionalCertification: Yup.string().max(100).required("Required"),
  specialInstruction: Yup.string().max(200).required("Required"),
});

const data = [
  {
    label: "Create",
    value: "create",
    desc: `It really matters and then like it really doesn't matter.
    What matters is the people who are sparked by it. And the people
    who are like offended by it, it doesn't matter.`,
  },
  {
    label: "Configure",
    value: "confugre",
    desc: `Because it's about motivating the doers. Because I'm here
    to follow my dreams and inspire other people to follow their dreams, too.`,
  },
];

const AutoRegisterBot = () => {
  document.title = "Personality Bot | EntheoGPT by The Entheology Project";
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("Upload Logo");
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [previewAvatar, setPreviewAvatar] = useState(null);
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("configure");

  const onDismiss = () => setVisible(false);

  const onUploadCallBack = ({ status, returnData }) => {
    if (status === "success") {
      setIsUploading(false);
      validation.setFieldTouched("iconUrl", true);
      validation.setFieldValue("iconUrl", returnData.data.imageUrl);
      setFileName("Upload Successfully");
    } else {
      setIsUploading(false);
      console.log(returnData.message);
    }
  };

  const imageSelect = async (e) => {
    setIsUploading(true);
    const imageFile = e.currentTarget.files[0];
    const formData = new FormData();
    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        console.log(` ${img.width}x${img.height} Pixels`);
      };
      img.src = event.target.result;
    };

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
      fileType: ".png",
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);

      formData.append("file", compressedFile);
      dispatch(handleUploadLogo(formData, onUploadCallBack));
      compressedFile && setPreviewAvatar(URL.createObjectURL(compressedFile));
    } catch (error) {
      console.log(error);
    }
  };

  const validation = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      dispatch(botRegister(values, navigate, helpers));
    },
  });

  const selectLoginState = (state) => state.bot;
  const BotProperties = createSelector(selectLoginState, (bot) => ({
    error: bot.error,
    success: bot.success,
  }));

  const { error, success } = useSelector(BotProperties);

  useEffect(() => {
    if (validation.values.botName !== "Your bot’s name") {
      validation.setFieldValue(
        "greeting",
        `Hi I’m ${validation.values.botName}! How can I help you, Seeker?`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation.values.botName]);

  const onChangePrompt = (html) => {
    validation.setFieldTouched("footerNote", true);
    validation.setFieldValue("footerNote", html);
  };

  return (
    <>
      <div className="w-100 user-chat mt-3 mt-sm-0">
        {error && error ? (
          <Alert
            severity="error"
            style={{ marginTop: "13px" }}
            onClose={onDismiss}
            className="flex justify-between items-center"
          >
            {error}
            <Button
              color="inherit"
              onClick={onDismiss}
              className="cursor-pointer"
            ></Button>
          </Alert>
        ) : null}
        {success ? (
          <Alert
            severity="success"
            style={{ marginTop: "13px" }}
            onClose={onDismiss}
            className="flex justify-between items-center"
          >
            {success}
            <Button
              color="inherit"
              onClick={onDismiss}
              className="cursor-pointer"
            ></Button>
          </Alert>
        ) : null}
        <div className="grid xl:grid-cols-2 sm:grid-cols-1 ">
          <div className="sm:border-r-2 sm:pr-5 border-gray-400  sm:max-h-[85vh] max-h-full overflow-y-auto">
            {/* <Tabs id="custom-animation" value="confugre">
              <TabsHeader className="flex justify-center items-center">
                {data.map(({ label, value }) => (
                  <Tab key={value} value={value} className="w-1/3">
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody
                animate={{
                  initial: { y: 250 },
                  mount: { y: 0 },
                  unmount: { y: 250 },
                }}
              >
                {data.map(({ value, desc }) => (
                  <TabPanel key={value} value={value}>
                    {desc}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
            <Tabs value={selectedTab} className="sm:hidden block mb-3">
              <Tab
                label="Configure"
                value="configure"
                icon={<ChatBubbleLeftRightIcon className="w-5 h-5" />}
                onClick={() => setSelectedTab("configure")}
              />
              <Tab
                label="Preview"
                value="preview"
                icon={<Cog8ToothIcon className="w-5 h-5" />}
                onClick={() => setSelectedTab("preview")}
              />
            </Tabs> */}
            {selectedTab === "create" && (
              <>
                <p>Register Bot</p>
              </>
            )}
            {selectedTab === "configure" && (
              <>
                {user?.attributes?.isBotCreated &&
                user?.attributes?.isBotCreated[0] !== "false" ? (
                  <BotUpdates />
                ) : (
                  <>
                    {/* <Card> */}
                    <form noValidate onSubmit={validation.handleSubmit}>
                      <div className="pb-12">
                        <div class="flex w-full items-center justify-center gap-4">
                          <button
                            className="h-20 w-20"
                            type="button"
                            id="radix-:r52:"
                            aria-haspopup="menu"
                            aria-expanded="false"
                            data-state="closed"
                            data-testid="undefined-button"
                            onClick={() =>
                              document.getElementById("file-input").click()
                            } // Add this line
                          >
                            <div className="flex relative h-full w-full items-center justify-center rounded-full border-2 border-dashed border-token-border-medium">
                              {previewAvatar ? (
                                <>
                                  <div className="flex flex-col justify-center items-center gap-2">
                                    <Avatar
                                      size="xl"
                                      variant="circular"
                                      alt="Bot logo"
                                      className="border-2 border-white"
                                      src={previewAvatar}
                                    />
                                  </div>
                                  {isUploading && (
                                    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="text-center h-1/4 w-1/4 animate-spin" // Add animate-spin class
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <line
                                          x1="12"
                                          y1="2"
                                          x2="12"
                                          y2="6"
                                        ></line>
                                        <line
                                          x1="12"
                                          y1="18"
                                          x2="12"
                                          y2="22"
                                        ></line>
                                        <line
                                          x1="4.93"
                                          y1="4.93"
                                          x2="7.76"
                                          y2="7.76"
                                        ></line>
                                        <line
                                          x1="16.24"
                                          y1="16.24"
                                          x2="19.07"
                                          y2="19.07"
                                        ></line>
                                        <line
                                          x1="2"
                                          y1="12"
                                          x2="6"
                                          y2="12"
                                        ></line>
                                        <line
                                          x1="18"
                                          y1="12"
                                          x2="22"
                                          y2="12"
                                        ></line>
                                        <line
                                          x1="4.93"
                                          y1="19.07"
                                          x2="7.76"
                                          y2="16.24"
                                        ></line>
                                        <line
                                          x1="16.24"
                                          y1="7.76"
                                          x2="19.07"
                                          y2="4.93"
                                        ></line>
                                      </svg>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="text-4xl"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12 3C12.5523 3 13 3.44772 13 4L13 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13L13 13L13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20L11 13L4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11L11 11L11 4C11 3.44772 11.4477 3 12 3Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              )}
                            </div>
                          </button>
                          <input
                            accept="image/png,.png,image/jpeg,.jpg,.jpeg,image/gif,.gif,image/webp,.webp"
                            multiple=""
                            tabIndex="-1"
                            type="file"
                            className="hidden"
                            id="file-input" // Add this line
                            onChange={imageSelect} // Add this line to handle file selection
                          />
                        </div>
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                          <div className="col-span-full">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Assistant’s Name
                            </label>
                            <div className="mt-1">
                              <input
                                id="botName"
                                name="botName"
                                type="text"
                                className="w-full text-sm overflow-y-auto rounded-lg border border-[#d9d9d9] px-3 py-2 focus:border focus:outline-1   "
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.botName || ""}
                                error={
                                  validation.touched.botName &&
                                  Boolean(validation.errors.botName)
                                }
                                helperText={
                                  validation.touched.botName &&
                                  validation.errors.botName
                                }
                              />
                            </div>
                          </div>

                          <div className="col-span-full">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              About the Coach
                            </label>
                            <div className="mt-1">
                              <textarea
                                name="primaryDomain"
                                placeholder="Expert in mushroom microdosing, plant medicines, kambo, somatic therapy, and ibogaine."
                                className="w-full text-sm overflow-y-auto rounded-lg border border-[#d9d9d9] px-3 py-2 focus:border focus:outline-1  h-32 "
                                defaultValue={""}
                                multiline
                                rows={4}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.primaryDomain || ""}
                                error={
                                  validation.touched.primaryDomain &&
                                  Boolean(validation.errors.primaryDomain)
                                }
                                helperText={
                                  validation.touched.primaryDomain &&
                                  validation.errors.primaryDomain
                                }
                              />
                            </div>
                          </div>

                          <div className="col-span-full">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Instructions
                            </label>
                            <div className="mt-1">
                              <textarea
                                name="specialInstruction"
                                rows={3}
                                placeholder="Please do not share private information. For contact info or appointments, just ask!"
                                className="w-full text-sm overflow-y-auto rounded-lg border border-[#d9d9d9] px-3 py-2 focus:border focus:outline-1  h-32 "
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.specialInstruction || ""
                                }
                                error={
                                  validation.touched.specialInstruction &&
                                  Boolean(validation.errors.specialInstruction)
                                }
                                helperText={
                                  validation.touched.specialInstruction &&
                                  validation.errors.specialInstruction
                                }
                              />
                            </div>
                          </div>

                          <div className="col-span-full">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Greeting
                            </label>
                            <div className="mt-1">
                              <textarea
                                name="greeting"
                                placeholder="How can I help you, Seeker?"
                                rows={3}
                                className="w-full text-sm overflow-y-auto rounded-lg border border-[#d9d9d9] px-3 py-2 focus:border focus:outline-1  h-32 "
                                disabled
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.greeting || ""}
                                error={
                                  validation.touched.greeting &&
                                  Boolean(validation.errors.greeting)
                                }
                                helperText={
                                  validation.touched.greeting &&
                                  validation.errors.greeting
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full justify-end items-center">
                          <Button type="submit">Create Bot</Button>
                        </div>{" "}
                      </div>
                    </form>

                    {/* <CardContent>
                          <form noValidate onSubmit={validation.handleSubmit}>
                            <Box
                              sx={{
                                alignItems: "center",
                                display: "flex",
                                mt: "20px",
                                mb: "10px",
                              }}
                            >
                              {previewAvatar ? (
                                <div className="flex flex-col justify-center items-center gap-2">
                                  <Avatar
                                    src={previewAvatar}
                                    alt="Profile Pic"
                                    className="rounded-circle avatar-md max-w-[118px] max-h-[118px] sm:me-0 me-3  border-2 border-dashed border-token-border-medium "
                                  />
                                </div>
                              ) : (
                                <div className="flex flex-col justify-center items-center gap-2">
                                  <button
                                    className="h-20 w-20 me-2"
                                    type="button"
                                    id="radix-:ri:"
                                    aria-haspopup="menu"
                                    aria-expanded="false"
                                    data-state="closed"
                                  >
                                    <div className="flex h-full w-full items-center justify-center rounded-full border-2 border-dashed border-token-border-medium">
                                      <LuUser className="text-4xl" />
                                    </div>
                                  </button>
                                </div>
                              )}

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="outlined"
                                  component="label"
                                  className="rounded-sm"
                                >
                                  {isUploading ? (
                                    <CircularProgress />
                                  ) : (
                                    fileName
                                  )}
                                  <input
                                    type="file"
                                    name="avatar"
                                    hidden
                                    onChange={imageSelect}
                                  />
                                </Button>
                              </Box>
                            </Box>
                            <div className="form-group mb-3">
                              <TextField
                                name="botName"
                                label="Assistant’s Name"
                                placeholder="Your bot’s name"
                                type="text"
                                fullWidth
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.botName || ""}
                                error={
                                  validation.touched.botName &&
                                  Boolean(validation.errors.botName)
                                }
                                helperText={
                                  validation.touched.botName &&
                                  validation.errors.botName
                                }
                              />
                            </div>
                            <div className="form-group mb-3">
                              <TextField
                                name="primaryDomain"
                                label="About the Coach"
                                placeholder="Expert in mushroom microdosing, plant medicines, kambo, somatic therapy, and ibogaine."
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.primaryDomain || ""}
                                error={
                                  validation.touched.primaryDomain &&
                                  Boolean(validation.errors.primaryDomain)
                                }
                                helperText={
                                  validation.touched.primaryDomain &&
                                  validation.errors.primaryDomain
                                }
                              />
                            </div>
                            <div className="form-group mb-3">
                              <TextField
                                name="specialInstruction"
                                label="Instructions"
                                placeholder="Please do not share private information. For contact info or appointments, just ask!"
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.specialInstruction || ""
                                }
                                error={
                                  validation.touched.specialInstruction &&
                                  Boolean(validation.errors.specialInstruction)
                                }
                                helperText={
                                  validation.touched.specialInstruction &&
                                  validation.errors.specialInstruction
                                }
                              />
                            </div>
                            <div className="form-group mb-3">
                              <TextField
                                name="greeting"
                                label="Greeting"
                                placeholder="How can I help you, Seeker?"
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                                disabled
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.greeting || ""}
                                error={
                                  validation.touched.greeting &&
                                  Boolean(validation.errors.greeting)
                                }
                                helperText={
                                  validation.touched.greeting &&
                                  validation.errors.greeting
                                }
                              />
                            </div>
                            <div className="flex justify-content-center align-items-center">
                              <Button
                                type="submit"
                                className="!bg-[#7a6fbe] text-white !btn !btn-primary !w-md"
                              >
                                Create Bot
                              </Button>
                            </div>
                          </form>
                        </CardContent> */}
                    {/* </Card> */}
                  </>
                )}
              </>
            )}
            {selectedTab === "preview" && (
              <>
                {user?.attributes?.isBotCreated &&
                user?.attributes?.isBotCreated[0] !== "false" ? (
                  <Bot />
                ) : (
                  <Preview botDetails={validation.values} />
                )}
              </>
            )}
          </div>
          <div className="sm:block hidden px-3">
            {user?.attributes?.isBotCreated &&
            user?.attributes?.isBotCreated[0] !== "false" ? (
              <Bot />
            ) : (
              <Preview botDetails={validation.values} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoRegisterBot;
