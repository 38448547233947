import { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import { format } from "date-fns";
import { Typography, Button, Input, Textarea } from "@material-tailwind/react";
import "react-day-picker/style.css";
import { httpMushPro } from "../../../../../services/http-common";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setSessionExpired, useMainController } from "../../../../../context";
import { convertTo12HourFormat, getWorkingDaysFromToday } from "../data";

const BookingForm = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [dispatch] = useMainController();
  const [selectedTime, setSelectedTime] = useState(null);
  const [availabileSlots, setAvailabileSlots] = useState(null);
  const [availability, setAvailability] = useState(null);
  const { botDetails } = useSelector((state) => state.bot);

  const getProAvailability = useMutation(
    () => {
      return httpMushPro.get(
        `user/scheduling/availability-by-id/${botDetails?.coach?.id}`,
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        // Generate dates for two months starting from today
        setAvailabileSlots(data.availability);
        const workingDays = data.availability
          .filter((item) => item.slots[0] && item.slots[0].startTime !== null)
          .map((item) => item.dayOfWeek);
        // Get today's date
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();

        // Find the first day of the 2nd next month
        const nextMonth = new Date(year, month + 2, 1);

        // Create an array to hold the working day dates
        const workingDayDates = [];

        // Loop through each day from today until the end of the month
        for (
          let current = new Date(year, month, day);
          current < nextMonth;
          current.setDate(current.getDate() + 1)
        ) {
          const dayName = current.toLocaleString("en-US", {
            weekday: "long",
          });

          // Check if the day is a working day
          if (workingDays && workingDays.includes(dayName)) {
            workingDayDates.push({
              date:
                current.getFullYear() +
                "-" +
                (current.getMonth() + 1) +
                "-" +
                current.getDate(),
              slots: data.availability.find(
                (days) => days.dayOfWeek === dayName
              ).slots,
            });
          }
        }
        setAvailability(workingDayDates);
      },
      onError: () => {
        toast.error("Fetch data failed");
      },
    }
  );

  const availableDays = availability?.map((day) => new Date(day.date));
  const timeSlots = selectedDate
    ? availability?.find(
        (day) => day.date === format(selectedDate, "yyyy-MM-dd")
      )?.slots || []
    : [];

  const onSubmit = async (data) => {
    console.log("Form submitted with data:", data);
  };

  useEffect(() => {
    getProAvailability.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-8  bg-white">
      <div className="md:h-80 flex flex-col md:flex-row gap-5 ">
        <div className="w-full">
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={(date) => {
              setSelectedDate(date);
              setSelectedTime(null); // Reset selected time when date changes
            }}
            disabled={[{ before: new Date() }]}
            modifiers={{ available: availableDays }}
            modifiersStyles={{
              available: {
                background: "#4590fb",
                color: "#ffffff",
                borderRadius: 100,
              },
            }}
          />
        </div>
        <div className="w-full h-full overflow-hidden md:overflow-scroll no-scrollbar">
          {selectedDate && (
            <div className="mb-4">
              <Typography variant="h5" className="mb-2 text-center">
                Available Time Slots
              </Typography>
              <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                {availabileSlots &&
                  availabileSlots
                    .find(
                      (days) =>
                        days.dayOfWeek ===
                        selectedDate?.toLocaleString("en-US", {
                          weekday: "long",
                        })
                    )
                    .slots.map((slot) => (
                      <Button
                        size="sm"
                        key={slot}
                        color="primary"
                        variant={
                          selectedTime === slot.startTime
                            ? "filled"
                            : "outlined"
                        }
                        className={
                          selectedTime === slot.startTime
                            ? "bg-black text-white "
                            : " border-[#000080] text-[#000080]  w-auto "
                        }
                        onClick={() => setSelectedTime(slot.startTime)}
                      >
                        {convertTo12HourFormat(slot.startTime) +
                          " - " +
                          convertTo12HourFormat(slot.endTime)}
                      </Button>
                    ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {selectedTime && (
        <Button
          variant="gradient"
          color="#000080"
          type="submit"
          className="w-full"
        >
          Submit Request
        </Button>
        // <form className="space-y-4">
        //   <div>
        //     <Input placeholder="Your Name" />
        //     {/* {errors.name && (
        //       <Typography variant="small" color="red">
        //         {errors.name.message}
        //       </Typography>
        //     )} */}
        //   </div>
        //   <div>
        //     <Input type="email" placeholder="Your Email" />
        //     {/* {errors.email && (
        //       <Typography variant="small" color="red">
        //         {errors.email.message}
        //       </Typography>
        //     )} */}
        //   </div>
        //   <div>
        //     <Textarea placeholder="Additional Information" />
        //   </div>
        //   <Button
        //     variant="gradient"
        //     color="#000080"
        //     type="submit"
        //     className="w-full"
        //   >
        //     Schedule Event
        //   </Button>
        // </form>
      )}
    </div>
  );
};

export default BookingForm;
