export const dropdownOptionsForm = [
  {
    value: 'agreementScale',
    label: 'Agreement Scale',
    options: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree'],
  },
  {
    value: 'satisfactionScale',
    label: 'Satisfaction Scale',
    options: ['Very Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Very Dissatisfied'],
  },
  {
    value: 'likelihoodScale',
    label: 'Likelihood Scale',
    options: ['Extremely Likely', 'Likely', 'Neutral', 'Unlikely', 'Extremely Unlikely'],
  },
  {
    value: 'familiarityScale',
    label: 'Familiarity Scale',
    options: ['Very Familiar', 'Familiar', 'Neutral', 'Unfamiliar', 'Very Unfamiliar'],
  },
  {
    value: 'interestScale',
    label: 'Interest Scale',
    options: ['Very Interested', 'Interested', 'Neutral', 'Not Interested', 'Not at All Interested'],
  },
  { value: 'easeScale', label: 'Ease Scale', options: ['Very Easy', 'Easy', 'Neutral', 'Difficult', 'Very Difficult'] },
  { value: 'frequencyScale', label: 'Frequency Scale', options: ['Always', 'Often', 'Sometimes', 'Rarely', 'Never'] },
  {
    value: 'qualityScale',
    label: 'Quality Scale',
    options: ['Very High Quality', 'High Quality', 'Average Quality', 'Low Quality', 'Very Low Quality'],
  },
  {
    value: 'approvalScale',
    label: 'Approval Scale',
    options: ['Strongly Approve', 'Approve', 'Neutral', 'Disapprove', 'Strongly Disapprove'],
  },
  {
    value: 'priorityScale',
    label: 'Priority Scale',
    options: ['Top Priority', 'High Priority', 'Neutral', 'Low Priority', 'Not a Priority'],
  },
  {
    value: 'importanceScale',
    label: 'Importance Scale',
    options: ['Very Important', 'Important', 'Neutral', 'Unimportant', 'Very Unimportant'],
  },
  {
    value: 'confidenceScale',
    label: 'Confidence Scale',
    options: ['Very Confident', 'Confident', 'Neutral', 'Unconfident', 'Very Unconfident'],
  },
  {
    value: 'timelinessScale',
    label: 'Timeliness Scale',
    options: ['Very Early', 'Early', 'On Time', 'Late', 'Very Late'],
  },
  {
    value: 'clarityScale',
    label: 'Clarity Scale',
    options: ['Very Clear', 'Clear', 'Neutral', 'Unclear', 'Very Unclear'],
  },
  {
    value: 'helpfulnessScale',
    label: 'Helpfulness Scale',
    options: ['Very Helpful', 'Helpful', 'Neutral', 'Not Helpful', 'Not at All Helpful'],
  },
  { value: 'availabilityScale', label: 'Availability Scale', options: ['All', 'Most', 'Some', 'Few', 'None'] },
  {
    value: 'friendlinessScale',
    label: 'Friendliness Scale',
    options: ['Very Friendly', 'Friendly', 'Neutral', 'Unfriendly', 'Very Unfriendly'],
  },
  {
    value: 'effectivenessScale',
    label: 'Effectiveness Scale',
    options: ['Very Effective', 'Effective', 'Neutral', 'Ineffective', 'Very Ineffective'],
  },
  {
    value: 'positivityScale',
    label: 'Positivity Scale',
    options: ['Very Positive', 'Positive', 'Neutral', 'Negative', 'Very Negative'],
  },
  { value: 'lengthScale', label: 'Length Scale', options: ['Too Short', 'Short', 'Just Right', 'Long', 'Too Long'] },
  {
    value: 'responsivenessScale',
    label: 'Responsiveness Scale',
    options: ['Very Responsive', 'Responsive', 'Neutral', 'Unresponsive', 'Not at All Responsive'],
  },
  {
    value: 'awarenessScale',
    label: 'Awareness Scale',
    options: ['Very Aware', 'Aware', 'Neutral', 'Unaware', 'Very Unaware'],
  },
  {
    value: 'desirabilityScale',
    label: 'Desirability Scale',
    options: ['Very Desirable', 'Desirable', 'Neutral', 'Undesirable', 'Very Undesirable'],
  },
  {
    value: 'attentivenessScale',
    label: 'Attentiveness Scale',
    options: ['Very Attentive', 'Attentive', 'Neutral', 'Unattentive', 'Not at All Attentive'],
  },
  {
    value: 'expectationScale',
    label: 'Expectation Scale',
    options: [
      'Far Exceeded Expectations',
      'Exceeded Expectations',
      'Met Expectations',
      'Below Expectations',
      'Far Below Expectations',
    ],
  },
  {
    value: 'professionalismScale',
    label: 'Professionalism Scale',
    options: ['Very Professional', 'Professional', 'Neutral', 'Unprofessional', 'Very Unprofessional'],
  },
  {
    value: 'clarityUnderstandingScale',
    label: 'Clarity Understanding Scale',
    options: ['Clearly', 'Somewhat Clearly', 'Neutral', 'Unclear', 'Not at All Clear'],
  },
  {
    value: 'usefulnessScale',
    label: 'Usefulness Scale',
    options: ['Very Useful', 'Useful', 'Neutral', 'Not Useful', 'Not at All Useful'],
  },
  {
    value: 'valueScale',
    label: 'Value Scale',
    options: ['Extremely Valuable', 'Valuable', 'Neutral', 'Not Valuable', 'Not at All Valuable'],
  },
  {
    value: 'daysOfWeek',
    label: 'Days of the Week',
    options: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  },
  {
    value: 'months',
    label: 'Months',
    options: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
  { value: 'quarters', label: 'Quarters', options: ['Q1', 'Q2', 'Q3', 'Q4'] },
  {
    value: 'raceEthnicity',
    label: 'Race/Ethnicity',
    options: [
      'American Indian or Alaska Native',
      'Asian',
      'Black or African American',
      'Hispanic or Latino',
      'Native Hawaiian or Other Pacific Islander',
      'White',
      'Other',
    ],
  },
  {
    value: 'incomeLevels',
    label: 'Income Levels',
    options: [
      'Less than $20,000',
      '$20,000 - $39,999',
      '$40,000 - $59,999',
      '$60,000 - $79,999',
      '$80,000 - $99,999',
      '$100,000 - $149,999',
      '$150,000 or more',
    ],
  },
  {
    value: 'ageRanges',
    label: 'Age Ranges',
    options: ['Under 18', '18-24', '25-34', '35-44', '45-54', '55-64', '65 or older'],
  },
  { value: 'frequency', label: 'Frequency', options: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Annually'] },
  {
    value: 'recentExperienceScale',
    label: 'Recent Experience Scale',
    options: ['Very Recent', 'Somewhat Recent', 'Neutral', 'Not Recent', 'Not at All Recent'],
  },
];
