import { Typography } from "@material-tailwind/react";
import { Divider } from "@mui/material";
import { useMutation } from "react-query";
import http from "../../services/http-common";
import { SwitchModeButton } from "../SwitchModeButton";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const GeneralSettings = () => {
  const navigateURL = (url) => (window.location.href = url);
  const { user } = useSelector((state) => state.auth);

  const checkMySubscrptions = () => {
    getOrdersList.mutate();
  };
  const getOrdersList = useMutation(
    () => {
      return http.post(`stripe/createCustomerPortalSession`, {}, null);
    },
    {
      onSuccess: ({ data }) => {
        navigateURL(data.url);
      },
      onError: (error) => {
        console.log(error);
      },
      onMutate: () => {
        // setLoading(true);
      },
      onSettled: () => {
        // setLoading(false);
      },
    }
  );

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="w-full flex justify-between items-center ">
        <Typography variant="small" color="blue-gray" className=" font-medium">
          Email
        </Typography>
        <Typography variant="small" color="blue-gray" className=" font-medium">
          {user?.email}
        </Typography>
      </div>
      <Divider />
      <div className="w-full flex justify-between items-center ">
        <Typography variant="small" color="blue-gray" className=" font-medium">
          Theme
        </Typography>
        <SwitchModeButton />
      </div>
      <Divider />
      <div className=" flex justify-between items-center ">
        <Typography variant="small" color="blue-gray" className=" font-medium">
          Subscription
        </Typography>
        <span
          onClick={() => {
            checkMySubscrptions();
          }}
        >
          <ChevronRightIcon className="w-4 h-4" />
        </span>
      </div>
      <Divider />
      <div className=" flex justify-between items-center ">
        <Typography variant="small" color="blue-gray" className=" font-medium">
          Help, Privacy & Terms
        </Typography>
        <Link to="https://entheo.info">
          <ChevronRightIcon className="w-4 h-4" />
        </Link>
      </div>
    </div>
  );
};

export default GeneralSettings;
