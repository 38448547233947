import axios from "axios";

const apiMushPro = axios.create({
  // baseURL: `https://api.entheo.pro/`,
  baseURL: `https://dev-api.mush.pro/`,
  // baseURL: `http://localhost:8000/`,
  // baseURL: process.env.REACT_APP_API_MUSH_PRO,
});

export default apiMushPro;
