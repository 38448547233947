import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import * as messages from "../../../services/messages";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import UserService from "../../../KeyCloackServices/UserService";
import http, { httpPostProcessing } from "../../../services/http-common";

function WisdomReSearchDetails(props) {
  const { id } = props;
  const { isSubscriptionAvailable } = useSelector((state) => state.dataFlow);
  const [scrapedContent, setScrapedContent] = useState(null);

  const errorFunc = (error) => {
    let message;
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR;
        break;
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR;
        break;
      }
      case 400: {
        message = messages.BAD_REQUEST;
        break;
      }
      default: {
        message = error.message;
      }
    }
    toast.error(message);
  };

  const getDocDetails = useMutation(
    (docId) => {
      return httpPostProcessing.post(`wisdom-docs/find-by-id`, { id: docId });
    },
    {
      onSuccess: ({ data }) => {
        setScrapedContent(data.doc);
      },
      onError: (error) => {
        errorFunc(error);
      },
      onSettled: () => {},
      onMutate: () => {},
    }
  );

  const getDocDetailFunc = () => {
    if (id === null) return false;
    getDocDetails.mutate(id);
  };
  useEffect(() => {
    getDocDetailFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      {scrapedContent ? (
        <Container className="!px-0">
          <Grid justifyContent="center" className="px-0">
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className="text-xl  font-bold !text-center">
                        {scrapedContent?.title ?? scrapedContent?.name ?? "N/A"}
                        {scrapedContent?.author && (
                          <>
                            <br />
                            <span className="text-mute text-gray-600 text-sm">
                              {"by " + scrapedContent?.author}
                            </span>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span>
                          <strong>Abstract</strong>
                        </span>
                        <br />
                        <span className="text-mute text-gray-600">
                          {scrapedContent?.abstract
                            ? scrapedContent?.abstract
                            : "N/A"}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span>
                          <strong>Publication date</strong>
                        </span>
                        <br />
                        <span className="text-mute text-gray-600">
                          {scrapedContent?.publication_date
                            ? scrapedContent?.publication_date
                            : "N/A"}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span>
                          <strong>DOI. (Digital Object Identifier)</strong>
                        </span>
                        <br />
                        <span className="text-mute text-gray-600">
                          {scrapedContent?.doi ? scrapedContent?.doi : "N/A"}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span>
                          <strong>Journal name</strong>
                        </span>
                        <br />
                        <span className="text-mute text-gray-600">
                          {scrapedContent?.journal_name
                            ? scrapedContent?.journal_name
                            : "N/A"}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span>
                          <strong>Notes</strong>
                        </span>
                        <br />
                        <span className="text-mute text-gray-600">
                          {scrapedContent?.notes
                            ? scrapedContent?.notes
                            : "N/A"}
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {(UserService.getRole()?.roles?.includes("admin") ||
              isSubscriptionAvailable) && (
              <div className="flex justify-center items-center mt-4">
                <Button
                  href={scrapedContent?.originalUrl}
                  download={
                    scrapedContent?.title ? scrapedContent?.title : "N/A"
                  }
                  variant="contained"
                  color="primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Doc
                </Button>
              </div>
            )}
          </Grid>
        </Container>
      ) : (
        <span>Loading ...</span>
      )}
    </>
  );
}

export default WisdomReSearchDetails;
