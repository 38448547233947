import React, { useEffect, useState } from "react";

import {
  Box,
  FormControl,
  TextField,
  FormLabel,
  DialogTitle,
  Dialog,
  DialogContent,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "react-day-picker/style.css";

import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { httpMushPro } from "../../../services/http-common";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import TimezoneSelect from "react-timezone-select";
import NewBookAppointment from "./NewBookAppointment";
import EventBookingPage from "./BookAppointment/EventBookingPage";
import { setSessionExpired, useMainController } from "../../../context";
import { DayPicker } from "react-day-picker";
import { Button, Typography } from "@material-tailwind/react";
import { convertTo12HourFormat } from "./BookAppointment/data";

function AppointmentBot() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [dispatch] = useMainController();
  const [selectedTime, setSelectedTime] = useState(null);
  const [availabileSlots, setAvailabileSlots] = useState(null);
  const [availability, setAvailability] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useSelector((state) => state.auth);
  const { botDetails } = useSelector((state) => state.bot);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({
    botId: botDetails?.id,
    seekerId: user?.id,
    proId: botDetails?.kcId,
    title: "Appointment",
    date: "",
    duration: "",
    slot: {
      startTime: "",
      endTime: "",
    },
    timezone: "Etc/GMT",
  });

  const getProAvailability = useMutation(
    () => {
      return httpMushPro.get(
        `user/scheduling/availability-by-id/${botDetails?.coach?.id}`,
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        // Generate dates for two months starting from today
        setContent({
          ...content,
          duration: data.availability[0]?.duration,
        });

        setAvailabileSlots(data.availability);
        const workingDays = data.availability
          .filter((item) => item.slots[0] && item.slots[0].startTime !== null)
          .map((item) => item.dayOfWeek);

        // Get today's date
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();

        // Find the first day of the 2nd next month
        const nextMonth = new Date(year, month + 2, 1);

        // Create an array to hold the working day dates
        const workingDayDates = [];

        // Loop through each day from today until the end of the month
        for (
          let current = new Date(year, month, day);
          current < nextMonth;
          current.setDate(current.getDate() + 1)
        ) {
          const dayName = current.toLocaleString("en-US", {
            weekday: "long",
          });

          // Check if the day is a working day
          if (workingDays && workingDays.includes(dayName)) {
            workingDayDates.push({
              date:
                current.getFullYear() +
                "-" +
                (current.getMonth() + 1) +
                "-" +
                current.getDate(),
              slots: data.availability.find(
                (days) => days.dayOfWeek === dayName
              ).slots,
            });
          }
        }
        setAvailability(workingDayDates);
      },
      onError: () => {
        toast.error("Fetch data failed");
      },
    }
  );

  const availableDays = availability?.map((day) => new Date(day.date));

  useEffect(() => {
    getProAvailability.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fixAppointment = useMutation(
    () => {
      return httpMushPro.post(`user/scheduling/appointment`, content);
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Appointment Create Successfully!");
        handleOpen();
        setContent({
          botId: botDetails?.id,
          seekerId: user?.id,
          proId: botDetails?.kcId,
          title: "Appointment",
          startDate: "",
          endDate: "",
          date: "",
          duration: "",
          slot: {
            startTime: "",
            endTime: "",
          },
          timezone: "Etc/GMT",
        });
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const handleOpen = () => {
    setOpen(!open);
  };
  const onSubmit = () => {
    fixAppointment.mutate();
  };

  const onChangeTimezone = (e) => {
    setContent({ ...content, timezone: e.value });
  };
  return (
    <>
      <span
        variant="text"
        className="!w-md mb-1 underline text-[#000080] font-semibold cursor-pointer"
        onClick={handleOpen}
      >
        Click Here
      </span>
      <Dialog
        open={open}
        sx={{
          backdropFilter: "blur(5px) sepia(5%)",
          // 👇 Another option to style Paper
          "& .MuiDialog-paper": {
            borderRadius: { xs: "0px", lg: "24px" },
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="flex justify-between items-center"
        >
          Schedule Meeting with Coach
          <CloseIcon onClick={handleOpen} className="cursor-pointer" />
        </DialogTitle>

        <Divider />
        <DialogContent>
          {/* <Box
            sx={{
              overflowY: "scroll",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <FormControl fullWidth sx={{ flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Title</FormLabel>
              <TextField
                size="small"
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="title"
                type="text"
                validate={{
                  required: { value: true },
                }}
                value={content.title}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Description</FormLabel>
              <TextField
                size="small"
                minRows={3}
                maxRows={5}
                multiline={true}
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="description"
                type="textarea"
                validate={{
                  required: { value: true },
                }}
                value={content.description}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>
                Meeting Platform
              </FormLabel>
              <TextField
                size="small"
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="location"
                type="text"
                validate={{
                  required: { value: true },
                }}
                value={content.location}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Meeting Link</FormLabel>
              <TextField
                size="small"
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="meetingLink"
                type="text"
                validate={{
                  required: { value: true },
                }}
                value={content.meetingLink}
              />
            </FormControl>
            
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Start Date</FormLabel>
              <TextField
                size="small"
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="startDate"
                type="datetime-local"
                validate={{
                  required: { value: true },
                }}
                value={content.startDate}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>End Date</FormLabel>
              <TextField
                size="small"
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="endDate"
                type="datetime-local"
                validate={{
                  required: { value: true },
                }}
                value={content.endDate}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "row", xs: "column-reverse" },
                justifyContent: { sm: "end", xs: "start" },
                alignItems: "center",
              }}
            >
              <LoadingButton
                size="small"
                sx={{
                  mt: 2,
                  borderRadius: "10px",
                  height: "50.65px",
                  mb: "20px",
                  width: "205px",
                }}
                type="submit"
                variant="contained"
                onClick={onSubmit}
                disabled={loading}
              >
                Request Appointment
              </LoadingButton>
            </Box>
          </Box> */}
          <div className="flex flex-col gap-8  bg-white">
            <div className=" flex justify-center flex-col md:flex-row gap-5 ">
              <div className="w-full flex flex-col justify-center items-center gap-2">
                <FormControl fullWidth sx={{ flexGrow: 1 }}>
                  <FormLabel sx={{ marginBottom: "8px" }}>Timezone</FormLabel>
                  <TimezoneSelect
                    className="z-20"
                    labelStyle="original"
                    value={content.timezone}
                    onChange={(e) => onChangeTimezone(e)}
                  />
                </FormControl>{" "}
                <DayPicker
                  mode="single"
                  selected={selectedDate}
                  onSelect={(date) => {
                    setSelectedDate(date);
                    setContent({
                      ...content,
                      date:
                        date.getFullYear() +
                        "-" +
                        (date.getMonth() + 1) +
                        "-" +
                        date.getDate(),
                      startDate: date,
                      endDate: date,
                    });
                    setSelectedTime(null); // Reset selected time when date changes
                  }}
                  disabled={[{ before: new Date() }]}
                  modifiers={{ available: availableDays }}
                  modifiersStyles={{
                    available: {
                      background: "#4590fb",
                      color: "#ffffff",
                      borderRadius: 100,
                    },
                  }}
                />
              </div>
              {selectedDate && (
                <div className="w-full h-full overflow-hidden md:overflow-scroll no-scrollbar">
                  <div className="mb-4">
                    <Typography variant="h5" className="mb-2 text-center">
                      Available Time Slots
                    </Typography>
                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                      {availabileSlots &&
                        availabileSlots
                          .find(
                            (days) =>
                              days.dayOfWeek ===
                              selectedDate?.toLocaleString("en-US", {
                                weekday: "long",
                              })
                          )
                          .slots.map((slot) => (
                            <Button
                              size="sm"
                              key={slot}
                              color="primary"
                              disabled={slot.isBooked}
                              variant={
                                selectedTime === slot.startTime
                                  ? "filled"
                                  : "outlined"
                              }
                              className={
                                selectedTime === slot.startTime
                                  ? "bg-black text-white "
                                  : " border-[#000080] text-[#000080]  w-auto "
                              }
                              onClick={() => {
                                setSelectedTime(slot.startTime);
                                setContent({
                                  ...content,
                                  slot: {
                                    startTime: slot.startTime,
                                    endTime: slot.endTime,
                                  },
                                });
                              }}
                            >
                              {convertTo12HourFormat(slot.startTime) +
                                " - " +
                                convertTo12HourFormat(slot.endTime)}
                            </Button>
                          ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {selectedTime && (
              <Button
                variant="gradient"
                color="#000080"
                onClick={onSubmit}
                type="submit"
                className="w-full"
              >
                Submit Request
              </Button>
              // <form className="space-y-4">
              //   <div>
              //     <Input placeholder="Your Name" />
              //     {/* {errors.name && (
              //       <Typography variant="small" color="red">
              //         {errors.name.message}
              //       </Typography>
              //     )} */}
              //   </div>
              //   <div>
              //     <Input type="email" placeholder="Your Email" />
              //     {/* {errors.email && (
              //       <Typography variant="small" color="red">
              //         {errors.email.message}
              //       </Typography>
              //     )} */}
              //   </div>
              //   <div>
              //     <Textarea placeholder="Additional Information" />
              //   </div>
              //   <Button
              //     variant="gradient"
              //     color="#000080"
              //     type="submit"
              //     className="w-full"
              //   >
              //     Schedule Event
              //   </Button>
              // </form>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AppointmentBot;
