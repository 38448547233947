import { Grid, Typography, Box } from "@mui/material";

import useStyles from "./styles/styles";

import Shape from "./components/Shape";

const Entheogens = () => {
  // const navigate = useNavigate();
  const classes = useStyles();

  const clases = {
    header2: {
      fontSize: { xs: "7vw", md: "3.18vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
      mb: { md: "3.63vw", xs: "0" },
    },
    header3: {
      fontSize: { xs: "7.63vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    header4: {
      fontSize: { xs: "7.63vw", md: "2.08vw !important" },
      fontWeight: "600 !important",
      // letterSpacing: "-1.33px !important",
      color: "#101010 !important",
      paddingY: { md: "0", xs: "0" },
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      my: { xs: "5vw", md: "1vw" },
      // textAlign: { xs: 'center', md: 'left' },
    },
    buttonSx: {
      width: { md: "18vw", xs: "78.63vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
      my: { xs: "9vw", md: "0" },
    },
    readmoreText: {
      fontSize: { xs: "4.07vw !important", md: "1.2vw !important" },
      fontWeight: "500 !important",
      color: "#000080 !important",
      textAlign: "left",
    },
  };

  return (
    <>
      <Grid
        className={classes.sectionBox}
        id="gptStore"
        sx={{ background: "#ffffff" }}
      >
        <Shape secNumber="6" />

        <Grid
          container
          className={classes.containerGrid}
          sx={{
            paddingBottom: "2vw",
            pt: { xs: "17.3vw", md: "3.07vw" },
          }}
        >
          <Grid item className={classes.flexCenter} xs={11} md={12}>
            <Typography sx={clases.header2}>Did You Know?</Typography>
          </Grid>
          <Box
            className={classes.flexCenter}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Grid
              item
              xs={11}
              md={3.5}
              className={classes.flexCenter}
              sx={{ my: { xs: "15.26vw", md: "0" } }}
            >
              <Box
                component="img"
                src="/images/home/sec6_img.png"
                alt="logo"
                className={classes.image}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item xs={11} md={5.2}>
              <Box>
                <ul className="list-disc px-3">
                  <li>
                    <Typography sx={clases.contentText}>
                      <span className="font-bold">
                        80% of U.S. counties lack access to mental health
                        providers and are unskilled with psychedelic
                        complications.
                      </span>
                    </Typography>
                  </li>
                  <li>
                    <Typography sx={clases.contentText}>
                      <span className="font-bold">
                        1 in 5 individuals have genetic sensitivities to
                        psychedelics that can lead to psychotic episodes.
                      </span>
                    </Typography>
                  </li>
                  <li>
                    <Typography sx={clases.contentText}>
                      <span className="font-bold">
                        40% of people fear stigma when discussing psychedelics
                        and prefer AI instead.
                      </span>
                    </Typography>
                  </li>
                  <li>
                    <Typography sx={clases.contentText}>
                      <span className="font-bold">
                        39% report significant fear or anxiety during a
                        challenging psychedelic experience.
                      </span>
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Entheogens;
