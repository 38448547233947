import { Button } from "@material-tailwind/react";
import React from "react";
import { MdFileDownload } from "react-icons/md";

const ExportCSV = ({ data, fileName }) => {
  const downloadCSV = () => {
    // Convert the data array into a CSV string
    const csvString = [
      ["Coupon code", "Status", "Date of creation", "Expiration", "Redemption"], // Specify your headers here
      ...data.map((item) => [
        item.code,
        item.status,
        item.created_at,
        item.expiryDate ? item.expiryDate : "Never",
        item.redemptions,
      ]), // Map your data fields accordingly
    ]
      .map((row) => row.join(","))
      .join("\n");

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: "text/csv" });

    // Generate a download link and initiate the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName || "download.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Button
      onClick={downloadCSV}
      size="sm"
      variant="outlined"
      className="flex justify-between items-center gap-2 "
    >
      <MdFileDownload className="text-base" /> Export
    </Button>
  );
};

export default ExportCSV;
