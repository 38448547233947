// @mui
import { Typography, Container, Box, Grid } from "@mui/material";
import useStyles from "./styles/styles";
import Shape from "./components/Shape";
import coachIcon from "../../assets/images/ic_popularity.svg";
import analysisIcon from "../../assets/images/ic_statistics.svg";
import reportIcon from "../../assets/images/ic_report.svg";
import searchIcon from "../../assets/images/ic_search.svg";
import creativeIcon from "../../assets/images/ic_creativity.svg";
import fingerPrintIcon from "../../assets/images/ic_fingerprint.svg";

// components

// ----------------------------------------------------------------------

const SERVICES = [
  {
    title: "Adaptive AI Interaction",
    description: (
      <>
        Tracks your mood over time and adjusts its tone, ensuring a{" "}
        <span className="font-bold">personalized and supportive </span>
        experience.
      </>
    ),
    icon: creativeIcon,
  },
  {
    title: "Fact Based AI Answers",
    description: (
      <>
        Psychedelic ChatGPT trained on{" "}
        <span className="font-bold">20,000+ research-backed sources</span> to
        deliver accurate and reliable insights and avoid AI hallucinations.
      </>
    ),
    icon: searchIcon,
  },
  {
    title: "AI Progress Tracking",
    description: (
      <>
        No more manual journaling! Just chat in any language with{" "}
        <span className="font-bold">
          EntheoGPT which creates daily AI progress reports
        </span>{" "}
        tracking overall mood, topics discussed, and key pre/post-journey notes
        for{" "}
        <span className="font-bold">deeper insight into your progress. </span>
      </>
    ),
    icon: analysisIcon,
  },
  {
    title: "Find the Right Coach",
    description: (
      <>
        Use the <span className="font-bold">EntheoGPT Store</span> to connect
        with{" "}
        <span className="font-bold">
          vetted specialists tailored to your needs.
        </span>{" "}
        Once matched, you can grant them access to your AI-generated progress
        history, allowing them to review the progress of your journey for deeper
        insights.
      </>
    ),
    icon: coachIcon,
  },
  {
    title: "Psychedelic Risk Prescreening",
    description:
      'Tools like PsiloScreen & EntheoDNA help identify potential contraindications before they become "accidents." ',
    icon: reportIcon,
  },
  {
    title: "Total Privacy",
    description: (
      <>
        Stay anonymous with <span className="font-bold">EntheoID</span>,
        ensuring a <span className="font-bold">Esecure and confidential</span>{" "}
        experience.
      </>
    ),
    icon: fingerPrintIcon,
  },
];

// ----------------------------------------------------------------------

export default function ServicesInclude() {
  const classes = useStyles();

  const clases = {
    header3: {
      fontSize: { xs: "7.5vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#101010 !important",
    },
    header1: {
      fontSize: {
        xs: "8.9vw !important",
        md: "3.38vw !important",
      },
      fontWeight: "600 !important",
      // letterSpacing: "-1.33px !important",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      textAlign: { xs: "center", md: "left" },
    },
    buttonSx: {
      width: { md: "22.48vw", xs: "39.95vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
    },
  };

  return (
    <Grid container className={classes.sectionBox}>
      <Shape secNumber="3" />

      <Container
        sx={{
          textAlign: "center",
          pt: { xs: 5, md: 10 },
          pb: { xs: 10, md: 15 },
        }}
      >
        <div className="relative mx-auto max-w-7xl px-6 text-center lg:px-8 mb-2 ">
          <Typography sx={clases.header3}>Our Solution</Typography>
        </div>
        <Box
          sx={{
            rowGap: 8,
            columnGap: 10,
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
            },
          }}
        >
          {SERVICES.map((value) => (
            <div key={value.title}>
              <Box
                component="span"
                className="svg-color"
                sx={{
                  width: 64,
                  height: 64,
                  display: "inline-block",
                  bgcolor: "primary.main",
                  mask: `url(${value.icon}) no-repeat center / contain`,
                  WebkitMask: `url(${value.icon}) no-repeat center / contain`,
                  mx: "auto",
                }}
              />

              <Typography
                variant="h5"
                sx={{
                  mt: 5,
                  mb: 2,
                  fontWeight: "600 !important",
                  color: "#000080 !important",
                }}
              >
                {value.title}
              </Typography>

              <Typography sx={{ color: "text.secondary" }}>
                {" "}
                {value.description}{" "}
              </Typography>
            </div>
          ))}
        </Box>
      </Container>
    </Grid>
  );
}
