import { Avatar } from "@material-tailwind/react";

export default function EventDetails() {
  return (
    <div className=" lg:w-1/3 bg-white">
      <h1 className="text-xl font-bold mb-4">{"event.title"}</h1>
      <div className="flex items-center mb-4 gap-2">
        <Avatar
          src="https://docs.material-tailwind.com/img/face-2.jpg"
          alt="avatar"
        />
        <div>
          <h2 className="text-xl font-semibold">{"event.user.name"}</h2>
          <p className="text-gray-600">{"event.user.email"}</p>
        </div>
      </div>
      <div className="flex items-center mb-2">
        {/* <ClockIcon className="mr-2 h-5 w-5" /> */}
        <span>{"event.duration"} minutes</span>
      </div>
      <div className="flex items-center mb-4">
        {/* <CalendarIcon className="mr-2 h-5 w-5" /> */}
        <span>Google Meet</span>
      </div>
      <p className="text-gray-700">{"event.description"}</p>
    </div>
  );
}
