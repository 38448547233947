import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container, Typography } from "@mui/material";
import { useState } from "react";
import AppointmentBot from "./AppointmentBot";

export default function ChatOverlaysBot({ intent }) {
  const [expanded, setExpanded] = useState(false);

  const clases = {
    contentText: {
      fontSize: { xs: "1.7vw !important", md: "0.7vw !important" },
      fontWeight: "500 !important",
      borderRadius: "10px",
      border: "1px solid rgb(224 224 224 / 1)",
    },
    buttonSx: {
      width: { md: "22.48vw", xs: "39.95vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
    },
  };

  const submitFeedback = () => {
    console.log("Starting Feedback ...");
  };

  const sections = [
    {
      title: "General Information",
      func: "general_information",
      content: <>What is EntheoGPT?</>,
    },
    {
      title: "Appointment Booking",
      func: "book_appointment",
      content: (
        <span className="text-gray-700 ">
          Need to schedule an appointment? <AppointmentBot /> to book now!
        </span>
      ),
    },
    {
      title: "Prescreening and Eligibility Check",
      func: "prescreening",
      content: (
        <>
          Ready for your prescreening?{" "}
          <a
            href="https://psiloscreen.me/"
            className="underline text-[#000080]"
            target="_blank"
            rel="noreferrer"
          >
            Visit Here
          </a>{" "}
          to start the Psiloscreening process.
        </>
      ),
    },
    {
      title: "Psiloscreen and Screening Process",
      func: "psiloscreen",
      content: (
        <>
          <a
            href="https://psiloscreen.me/"
            className="underline text-[#000080]"
            target="_blank"
            rel="noreferrer"
          >
            Visit Here
          </a>{" "}
          to start the Psiloscreening process.
        </>
      ),
    },
    {
      title: "EntheoDNA Testing",
      func: "handle_entheodna",
      content: (
        <>
          Interested in the EntheoDNA Home Kit?{" "}
          <a
            href="https://entheo.info/product/entheodna-home-kit/"
            className="underline text-[#000080]"
            target="_blank"
            rel="noreferrer"
          >
            Click here
          </a>{" "}
          to learn more and purchase
        </>
      ),
    },
    {
      title: "Post-Treatment Support",
      func: "post_treatment_support",
      content: (
        <>
          <span className="text-gray-700 ">
            Need to schedule an appointment? <AppointmentBot /> to book now!
          </span>
        </>
      ),
    },
    {
      title: "Billing and Payment Processing",
      func: "handle_billing",
      content: (
        <>
          This feature is currently on hold. For more details,{" "}
          <a
            href="https://entheo.info/"
            className="underline text-[#000080]"
            target="_blank"
            rel="noreferrer"
          >
            Visit Here
          </a>{" "}
          to contact our support team.
        </>
      ),
    },
    {
      title: "Technical Support",
      func: "technical_support",
      content: (
        <>
          Facing technical issues?{" "}
          <a
            href="https://entheo.info/"
            className="underline text-[#000080]"
            target="_blank"
            rel="noreferrer"
          >
            Visit Here
          </a>{" "}
          to reach out to our technical support team.
        </>
      ),
    },
    {
      title: "Privacy and Security Inquiries",
      func: "privacy_security",
      content: (
        <>
          Have concerns about privacy or security?{" "}
          <a
            href="https://entheo.info/"
            className="underline text-[#000080]"
            target="_blank"
            rel="noreferrer"
          >
            Visit Here
          </a>{" "}
          to contact us for assistance
        </>
      ),
    },
    {
      title: "Feedback and Suggestions",
      func: "submit_feedback",
      content: (
        <button onClick={() => submitFeedback()}>Submit Feedback</button>
      ),
    },
    {
      title: "Testing and QA",
      func: "Sub-task",
      content: (
        <button onClick={() => submitFeedback()}>Submit Feedback</button>
      ),
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="!w-full flex flex-col justify-end items-end ">
      <Container className=" !flex  flex-col sm:items-end items-center ">
        {sections.map((section, index) => (
          <>
            {section.func === intent && (
              <Accordion
                defaultExpanded
                // expanded={expanded === section.title}
                onChange={handleChange(section.title)}
                disableGutters
                key={index}
                className="my-1 sm:!w-[max-content] !w-full"
                elevation={expanded === section.title ? 1 : 0}
                sx={{
                  backgroundColor: expanded !== section.title && "transparent",
                  boxShadow: expanded !== section.title && "none",
                  "&:before": {
                    display: expanded !== section.title && "none",
                  },
                }}
              >
                <AccordionSummary
                  sx={clases.contentText}
                  expandIcon={<ExpandMoreIcon className="ms-2" />}
                  aria-controls="panel1-content"
                  id={section.title}
                >
                  <Typography
                    component="span"
                    variant="body2"
                    className="break-words"
                    sx={{
                      span: {
                        whiteSpace: "inherit !important",
                      },
                      me: "50px",
                    }}
                  >
                    {section.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    component="span"
                    variant="body2"
                    className="break-words"
                    sx={{
                      span: {
                        whiteSpace: "inherit !important",
                      },
                    }}
                  >
                    {section.content}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
          </>
        ))}
      </Container>
    </div>
  );
}
