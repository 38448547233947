import { Fragment } from "react";
import "./formBuilder.css";
import { Radio, Typography } from "@material-tailwind/react";

const RadioInput = ({ item, index, handleValue }) => {
  return (
    <Fragment>
      <form className="mb-2 w-full">
        <div className="mb-1 flex flex-col gap-6">
          <Typography variant="h6" color="blue-gray" className="-mb-3">
            {item.required && "* "}
            {index + 1 + ". " + item.value}
          </Typography>
          {item.options && item.options.length > 0 && (
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1">
              {item.options.map((opt, key) => (
                <div className="flex " key={opt.id}>
                  <Radio
                    label={opt?.value}
                    name="content"
                    id={`multiple-choice-${key}`}
                    onChange={(e) => handleValue(item.id, e)}
                    defaultChecked={item.content === opt.value}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </form>
    </Fragment>
  );
};

export default RadioInput;
