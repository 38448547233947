import { Fragment } from "react";
import { Typography, Slider } from "@material-tailwind/react";
import "./formBuilder.css";

const SliderInput = ({
  item,
  index,
  handleValue,
  handleSliderValue,
  deleteEl,
  handleRequired,
  handleElType,
  duplicateElement,
  handleSave,
  addOption,
  handleOptionValues,
  handleDefaultOptionValues,
  deleteOption,
}) => {
  return (
    <Fragment>
      <div className="form-group-container relative">
        <div className="mb-4">
          <Typography variant="h6">
            {item.required && "* "}
            {index + 1 + ". " + item.value}
          </Typography>
        </div>
        <div className="p-3">
          <div className="flex justify-between">
            <span className="font-bold">{item.min}</span>
            <span className="font-bold">{item.max}</span>
          </div>
          <Slider
            min={item.min}
            max={item.max}
            value={item.value}
            onChange={(value) => handleSliderValue(value, index)}
            className="mt-4"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default SliderInput;
