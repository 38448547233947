import axios from "axios";

const apiPostProcessing = axios.create({
  // baseURL: `https://pp.entheo.ai/`,
  baseURL: `https://dev-api.entheogpt.com/`,
  // baseURL: `http://localhost:8000/`,
  // baseURL: process.env.REACT_APP_API_DEV_MUSH_GPT,
});

export default apiPostProcessing;
