import { Fragment } from "react";
import "./formBuilder.css";
import { Input, Typography } from "@material-tailwind/react";

const TextFieldNew = ({ item, index, handleValue }) => {
  return (
    <Fragment>
      <form className=" mb-2 w-full">
        <div className="mb-1 flex flex-col gap-6">
          <Typography variant="h6" color="blue-gray" className="-mb-3">
            {item.required && "* "}
            {index + 1 + ". " + item.value}
          </Typography>
          <Input
            fullwidth
            size="lg"
            name="content"
            placeholder="Enter here"
            value={item.content}
            onChange={(e) => handleValue(item.id, e)}
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900 !w-full"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
          />
        </div>
      </form>
    </Fragment>
  );
};

export default TextFieldNew;
