// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaderboard-dark {
  padding: 20px;
  border: 5px solid cyan;
  background-color: black;
  font-family: "Press Start 2P", cursive; /* Using the imported font */
}
.leaderboard-light {
  padding: 20px;
  border: 5px solid #000080;
  font-family: "Press Start 2P", cursive; /* Using the imported font */
}

.leaderboard-dark h1,
.leaderboard-light h1 {
  margin-bottom: 30px;
  font-size: 24px;
}

.leaderboard-dark ul,
.leaderboard-light ul {
  list-style: none;
  padding: 0;
  margin-bottom: 10px;
}

.leaderboard-dark li,
.leaderboard-light li {
  margin: 10px 0;
  font-size: 18px;
}

.rank,
.score,
.initial {
  display: inline-block;
}

.rank {
  width: 20%;
}

.score {
  width: 50%;
}

.initial {
  width: 30%;
}

.red {
  color: #ff6b6b;
}
.yellow {
  color: #000080;
}
.green {
  color: #3cb371;
}

.cyan {
  color: #379cc4;
}
`, "",{"version":3,"sources":["webpack://./src/components/SettingsPages/LeaderBoard/Leaderboard.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,sCAAsC,EAAE,4BAA4B;AACtE;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,sCAAsC,EAAE,4BAA4B;AACtE;;AAEA;;EAEE,mBAAmB;EACnB,eAAe;AACjB;;AAEA;;EAEE,gBAAgB;EAChB,UAAU;EACV,mBAAmB;AACrB;;AAEA;;EAEE,cAAc;EACd,eAAe;AACjB;;AAEA;;;EAGE,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap\");\n\n.leaderboard-dark {\n  padding: 20px;\n  border: 5px solid cyan;\n  background-color: black;\n  font-family: \"Press Start 2P\", cursive; /* Using the imported font */\n}\n.leaderboard-light {\n  padding: 20px;\n  border: 5px solid #000080;\n  font-family: \"Press Start 2P\", cursive; /* Using the imported font */\n}\n\n.leaderboard-dark h1,\n.leaderboard-light h1 {\n  margin-bottom: 30px;\n  font-size: 24px;\n}\n\n.leaderboard-dark ul,\n.leaderboard-light ul {\n  list-style: none;\n  padding: 0;\n  margin-bottom: 10px;\n}\n\n.leaderboard-dark li,\n.leaderboard-light li {\n  margin: 10px 0;\n  font-size: 18px;\n}\n\n.rank,\n.score,\n.initial {\n  display: inline-block;\n}\n\n.rank {\n  width: 20%;\n}\n\n.score {\n  width: 50%;\n}\n\n.initial {\n  width: 30%;\n}\n\n.red {\n  color: #ff6b6b;\n}\n.yellow {\n  color: #000080;\n}\n.green {\n  color: #3cb371;\n}\n\n.cyan {\n  color: #379cc4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
