import {
  Typography,
  Chip,
  Tooltip,
  Progress,
  Grid,
  GridItem,
  Button,
  Input,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CloudArrowUpIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { setSessionExpired, useMainController } from "../../context";
import { toast } from "react-toastify";
import { httpMushPro } from "../../services/http-common";

const BotAllDocs = () => {
  const [dispatch] = useMainController();
  const queryClient = useQueryClient();
  const [contentId, setContentId] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [uploadFiles, setUploadFiles] = useState(null);

  const cp = queryClient.getQueryData("books-cp");
  const ps = queryClient.getQueryData("books-ps");
  const filters = queryClient.getQueryData("books-filters");
  const sorters = queryClient.getQueryData("books-sorters");

  const { register, handleSubmit, reset } = useForm({
    mode: "onSubmit",
  });
  const [books, setBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(cp || 1);
  const [pageSize, setPageSize] = useState(ps || 50);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [filterValues, setFilterValues] = useState(filters || {});
  const [sorterValues, setSorterValues] = useState(
    sorters || { updated_at: { desc: true } }
  );

  useQuery("books-cp", () => currentPage);
  useQuery("books-ps", () => pageSize);
  useQuery("books-filters", () => filterValues);
  useQuery("books-sorters", () => sorterValues);

  const { isError, error, data, refetch } = useQuery(
    ["books", currentPage, pageSize, filterValues, sorterValues],
    () => {
      return httpMushPro.get(
        `train/book/mine?cp=${currentPage}&ps=${pageSize}${
          JSON.stringify(filterValues) !== "{}"
            ? `&filters=${JSON.stringify(filterValues)}`
            : ""
        }${
          JSON.stringify(sorterValues) !== "{}"
            ? `&sorters=${JSON.stringify(sorterValues)}`
            : ""
        }`,
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      keepPreviousData: true,
      // refetchInterval: 5000,
      queryKey: ["books", currentPage, pageSize], // Unique key for the query
      staleTime: 30000, // Keep data in cache for 30 seconds
      retryOnMount: true,
    }
  );

  useEffect(() => {
    if (isError) {
      console.log(error);
      toast.error("Fetch data failed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (data && data.data) {
      const { docs } = data.data;
      const newDocs = docs.map((doc) => {
        return {
          ...doc,
          key: doc.id,
          name: (
            <Tooltip
              content={doc.name}
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <a href={doc.url} target="_blank" rel="noreferrer">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-semibold"
                >
                  {doc.name.length > 39
                    ? doc.name.slice(0, 39) + "..."
                    : doc.name}
                </Typography>
              </a>
            </Tooltip>
          ),
          author: (
            <Tooltip
              content={doc?.author}
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <Typography
                variant="small"
                color="blue-gray"
                className="font-semibold"
              >
                {doc?.author.split(",").length > 2
                  ? doc?.author.slice(0, 30) + "..."
                  : doc?.author}
              </Typography>
            </Tooltip>
          ),
          updated_at: (
            <Typography
              variant="small"
              color="blue-gray"
              className="font-semibold"
            >
              {doc.updated_at}
            </Typography>
          ),
          status: (
            <Chip
              variant="gradient"
              color={status(doc.status)}
              value={doc.status}
              className="py-0.5 px-2 text-[11px] font-medium"
            />
          ),
          private: (
            <Chip
              variant="gradient"
              color={doc.private ? "red" : "blue"}
              value={doc.private ? "private" : "public"}
              className="py-0.5 px-2 text-[11px] font-medium"
            />
          ),
        };
      });
      setBooks(newDocs);
    }
  }, [data]);

  const onSubmit = () => {
    if (uploadFiles?.length) {
      const formData = new FormData();
      for (let i = 0; i < uploadFiles.length; i++) {
        formData.append(`books`, uploadFiles[i]);
      }
      fileUpload.mutate(formData);
    } else {
      return false;
    }
  };

  const fileUpload = useMutation(
    async (file) => {
      return httpMushPro.post(
        `train/book`,
        file,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            if (percent < 100) {
              setUploadPercentage(percent);
            } else {
              setUploadPercentage(0);
            }
          },
        },
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        data.message === "Only 0 files uploaded"
          ? toast.error("This file is already uploaded.")
          : toast.success(data.message);
        setUploadFiles(null);
        refetch();
        document.getElementById("documents-upload").value = null;
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const toogleOpenEditor = () => {
    if (editorOpen === true) {
      setContentId(null);
    }
    setEditorOpen(!editorOpen);
  };

  const handleEdit = (id) => {
    setContentId(id);
    toogleOpenEditor();
  };

  const deleteData = useMutation(
    (id) => {
      return httpMushPro.delete(`train/book/${id}`, null);
    },
    {
      onSuccess: () => {
        toast.success("Successfully Deleted");
        refetch();
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const deleteFunc = (id) => {
    deleteData.mutate(id);
  };

  const status = (value) => {
    switch (value) {
      case "ready": {
        return "blue";
      }
      case "pending": {
        return "purple";
      }
      case "done": {
        return "green";
      }
      case "failed": {
        return "red";
      }
      default: {
      }
    }
  };

  function getFilePreviewValue(file) {
    if (file.includes(".pdf")) {
      return "https://dev-chat.entheo.pro/svg/pdf.svg";
    } else if (file.includes(".doc")) {
      return "/svg/doc.svg";
    } else if (file.includes(".txt")) {
      return "/svg/txt.svg";
    } else {
      return "Preview Not Available";
    }
  }
  const onChangeUploadFiles = (e) => {
    setUploadFiles(e.target.files);
  };

  return (
    <>
      <div className=" mb-8 flex flex-col gap-2">
        <div>
          <div className="flex h-full w-full flex-row items-center gap-6 ">
            <Input
              variant="outlined"
              sx={{ display: "none" }}
              id="documents-upload"
              inputProps={{ multiple: true, accept: ".pdf,.doc,.docx,.txt" }}
              type="file"
              onChange={onChangeUploadFiles}
            />

            <Button
              size="sm"
              className="btn btn-primary flex items-center  gap-1 py-1 px-2 "
              type="submit"
              disabled={fileUpload.isLoading || uploadFiles === null}
              onClick={onSubmit}
            >
              <CloudArrowUpIcon strokeWidth={2} className="h-5 w-5" /> Upload
            </Button>
          </div>
          {uploadPercentage > 0 && uploadPercentage < 100 && (
            <Progress
              value={uploadPercentage}
              active
              label={`Completed`}
              className="my-3"
            />
          )}
        </div>
        {books?.map((f, i) => {
          return (
            <div
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i + "-file"}
            >
              <div className="p-2 flex items-center">
                <div className="mr-2">
                  <img
                    data-dz-thumbnail=""
                    width="40"
                    height="40"
                    className="avatar-xs rounded bg-light"
                    alt={f.name}
                    src={getFilePreviewValue(f.originalUrl)}
                  />
                </div>
                <div className=" flex-1">
                  <Link to="#" className="text-muted !text-xs font-weight-bold">
                    {f.name}
                  </Link>
                  {/* <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p> */}
                </div>
                <div className="flex justify-normal items-center gap-2">
                  {/* <span
                    className=" border-none  p-0 mr-2 hover:bg-white cursor-pointer"
                    onClick={() => handleEdit(f.id)}
                  >
                    <EyeIcon className="h-5 w-5 text-dark " />
                  </span> */}
                  <span
                    className="bg-danger  text-black border-none p-0 cursor-pointer"
                    onClick={() => deleteFunc(f.id)}
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default BotAllDocs;
