import {
  Grid,
  // Link
} from "@mui/material";
// import { useNavigate } from 'react-router-dom';

import useStyles from "../styles/styles";

import Shape from "../components/Shape";
import { useLocation } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Switch } from "@material-tailwind/react";
import { useState } from "react";
import { useMutation } from "react-query";
import http from "../../../services/http-common";
import UserService from "../../../KeyCloackServices/UserService";
// const navigate = useNavigate();
const plans = [
  {
    name: "Seeker Unlimited",
    monthlyPrice: "$11",
    annualPrice: "$120",
    features: [
      "Find Professional Coaches in the EntheGPT Store",
      "Access to all Science, Legal, Wisdom AI Libraries",
      "AI Mood Tracking & Journaling",
      "Get Personal Journey Insights",
    ],
    cta: "Start Free Trial",
  },
  {
    name: "Pro Science",
    monthlyPrice: "$22",
    annualPrice: "$240",
    features: [
      "Confidential Seeker-to-Pro Matching",
      "Create & Train Your AI Assistant",
      "View Science and Wisdom Training Documents",
      "AI Appointment Tracking & Scheduling",
      "Seeker AI Insights & Analytics",
    ],
    cta: "Start Free Trial",
  },
  {
    name: "Legal Law",
    monthlyPrice: "$44",
    annualPrice: "$480",
    features: [
      "Confidential Seeker-to-Pro Matching",
      "Create & Train Your AI Assistant",
      "View Science, Law and Wisdom Training Documents",
      "AI Appointment Tracking & Scheduling",
      "Seeker AI Insights & Analytics",
      "Access to High Reasoning Models",
    ],
    cta: "Start Free Trial",
  },
];

export default function PricingTable() {
  const [isAnnualBilling, setIsAnnualBilling] = useState("no");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  // const handleBuy = (id: any, isRecurring: any) => {
  //   setLoading(true);
  //   buyProduct.mutate({ id, isRecurring });
  // };

  const navigate = (url: any) => (window.location.href = url);

  // const buyProduct = useMutation(
  //   (data: any) => {
  //     return http.post(
  //       `stripe/create-checkout-session`,
  //       {
  //         priceId: data.id,
  //         isRecurring: data.isRecurring,
  //       },
  //       null
  //     );
  //   },
  //   {
  //     onSuccess: ({ data }: any) => {
  //       navigate(data.url);
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //       setLoading(false);
  //     },
  //     onMutate: () => {
  //       setLoading(true);
  //     },
  //     onSettled: () => {
  //       setLoading(false);
  //     },
  //   }
  // );
  return (
    <Grid container className={classes.sectionBox}>
      <Shape secNumber="2" />
      <Grid container className={classes.containerGrid}>
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
              <p className="mt-2 text-4xl font-bold tracking-tight text-[#000080] sm:text-5xl">
                Choose the Plan That's Right for You
              </p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
              All plans include a 30-day free trial before activation.
            </p>
            <div className="mt-16 flex justify-center">
              <div className="mx-auto max-w-7xl px-6 lg:px-4 mb-6">
                <div className=" flex justify-center">
                  <div className="flex items-center">
                    <Switch
                      checked={isAnnualBilling === "yes"}
                      onClick={() =>
                        setIsAnnualBilling(
                          isAnnualBilling === "no" ? "yes" : "no"
                        )
                      }
                      id="custom-switch-component"
                      ripple={false}
                      className="h-full w-full checked:bg-[#000080]"
                      containerProps={{
                        className: "w-11 h-6",
                      }}
                      circleProps={{
                        className: "before:hidden left-0.5 border-none",
                      }}
                      crossOrigin={undefined}
                    />
                    <span
                      className="ml-3 text-sm"
                      id="headlessui-label-:R58bm:"
                    >
                      <span className="font-medium text-gray-900 dark:text-gray-200">
                        {isAnnualBilling === "yes"
                          ? "Discounted Annual Billing"
                          : "Monthly Billing"}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {plans.map((plan, idx) => (
                <div
                  key={idx}
                  className={"ring-1 ring-gray-200 rounded-3xl p-8 xl:p-10"}
                >
                  <div className="flex items-center justify-between gap-x-4">
                    <h3
                      className={`text-lg font-semibold leading-8 text-[#000080]`}
                    >
                      {plan.name}
                    </h3>
                  </div>
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      {isAnnualBilling === "yes"
                        ? plan.annualPrice
                        : plan.monthlyPrice}
                    </span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">
                      {isAnnualBilling === "yes" ? "/month" : "/year"}
                    </span>
                  </p>
                  <button
                    className={`mt-6 block rounded-md px-3 w-full py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                      idx === 1
                        ? "bg-[#000080] text-white shadow-sm hover:bg-indigo-500"
                        : "text-[#000080] ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300"
                    }`}
                    onClick={() =>
                      UserService.doLogin({
                        redirectUri: `${window.location.href + "pay"}`,
                      })
                    }
                  >
                    {plan.cta}
                  </button>
                  <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                    {plan.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          aria-hidden="true"
                          className="h-6 w-5 flex-none text-[#000080]"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
