import { Box } from "@mui/material";

import ContentEdit from "../components/chat/content-edit";
import ContentContainer from "../components/chat/content-container";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MagicMycQResponse from "../components/faqs/MagicMycQResponse";
import ReactGA from "react-ga4";
import EditChatFooter from "./Chat Settings/EditChatFooter";
import { updateChatStreaming } from "../store/messages/actions";

const Chat = () => {
  const dispatch = useDispatch();
  const [showSuggestions, setShowSuggestions] = useState(true);
  const { loading } = useSelector((state: any) => state.msg);
  const { isStreaming } = useSelector((state: any) => state.msg);
  const { sidebarWeb } = useSelector((state: any) => state.dataFlow);

  const handleStopStreaming = () => {
    dispatch(updateChatStreaming(false));
  };
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "EntheoGPT by The Entheology Project",
    });
  }, []);
  return (
    <>
      <Box
        id="scrollableDiv"
        className="max-h-[calc(100vh-230px)]  md:max-h-[calc(100vh-220px)] lg:max-h-[calc(100vh-205px)] xl:max-h-[calc(100vh-205px)] "
        sx={{
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column-reverse",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <ContentContainer
          showSuggestions={showSuggestions}
          setShowSuggestions={setShowSuggestions}
        />
      </Box>
      <Box
        sx={{
          position: { xs: "fixed", lg: sidebarWeb ? "fixed" : "absolute" },
          bottom: 0,
          minHeight: "90px",
          width: "-webkit-fill-available",
          paddingRight: "40px",
          bgcolor: "background.default",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <Box sx={{ padding: "0px" }}>
          <EditChatFooter />
        </Box>
        <Box>
          {!loading && !isStreaming && (
            <>
              <MagicMycQResponse
                setShowSuggestions={setShowSuggestions}
                showSuggestions={showSuggestions}
              />
            </>
          )}
          <ContentEdit
            handleStopStreaming={handleStopStreaming}
            setShowSuggestions={setShowSuggestions}
          />
        </Box>
      </Box>
    </>
  );
};

export default Chat;
