import * as actionTypes from "../constants";

const initialState: {
  connected: boolean;
  messages: [];
} = {
  connected: false,
  messages: [],
};

const reducer = (
  prevState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    // ** When user press enterKey or send message button
    case actionTypes.WEBSOCKET_CONNECT:
      return { ...prevState, connected: true };
    case actionTypes.WEBSOCKET_DISCONNECT:
      return { ...prevState, connected: false };
    case actionTypes.WEBSOCKET_GET_CHAT_HISTORY_SUCCESS:
      return {
        ...prevState,
        messages: action.payload,
      };
    case actionTypes.WEBSOCKET_HUMAN_MESSAGE:
      return {
        ...prevState,
        messages: [...prevState.messages, action.payload],
      };
    case actionTypes.WEBSOCKET_STREAMING_MESSAGE:
      const prevMessages = prevState.messages;
      const lastMessage: any = prevMessages[prevMessages.length - 1];

      if (lastMessage?.role === "bot") {
        // Update the last bot message by appending new content
        const updatedMessages: any = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = {
          ...lastMessage,
          content: lastMessage.content + action.payload,
        };
        return { ...prevState, messages: updatedMessages };
      }

      return {
        ...prevState,
        // messages: [...prevState.messages],
      };
    case actionTypes.WEBSOCKET_BOT_INITIAL_MESSAGE:
      return {
        ...prevState,
        messages: [...prevState.messages, action.payload],
      };
    case actionTypes.WEBSOCKET_BOT_MESSAGE:
      return {
        ...prevState,
        messages: prevState.messages.map((msg, index) =>
          index === prevState.messages.length - 1
            ? {
                msg,
                content: action.payload.content,
                feedback: action.payload.feedback,
                id: action.payload.id,
                isApproved: action.payload.isApproved,
                kcId: action.payload.kcId,
                refer: action.payload.refer ? action.payload.refer : [],
                role: "assistant",
              }
            : msg
        ),
      };
    case actionTypes.DELETE_CHAT_HISTORY_REQUEST_SUCCESS:
      return {
        ...prevState,
        messages: [
          {
            feedback: null,
            isApproved: true,
            role: "assistant",
            content:
              "Welcome to MushGPT by The Entheology Project! We provide scientific research data about entheogens/psychedelics, but don’t endorse illegal activity. This is not medical/legal advice. By continuing, you confirm being 21+ and accept our T&Cs and Privacy Policy. Avoid sharing private info; I don’t store long-term chat memory. All messages are deleted after 10 minutes for privacy. Contact admin@mushgpt.com for assistance.\n\nHi! 嗨！नमस्ते！¡Hola! Salut！مرحبا！হাই！Привет！Oi！ہیلو！Hai！Hallo！こんにちは！नमस्कार！హలో！Merhaba！안녕！ஹாய்！Ciao!\nI’m Magic Myc, an Entheogenic Chatbot trained on a large set of scientific literature. How can I help? 🍄 ✌",
          },
        ],
      };

    default:
      return prevState;
  }
};

export default reducer;
