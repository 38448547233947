import { ServerResponse } from "http";
import * as actionTypes from "../constants";
import { all, call, put, takeLatest } from "redux-saga/effects";

let socket: any = null;

const shooterConnectSocket = (data: any) => {
  socket = new WebSocket(data.url);

  socket.onopen = () => {
    // console.log("WebSocket connected");
    //     // Request chat history on connection
    //     socketRef.current.send(
    //       JSON.stringify({
    //         type: "get_chat_history",
    //         kcId: user.id,
    //         finalId: "last",
    //         fName: user.firstName,
    //       })
    //     );
  };

  socket.onclose = () => {
    console.log("WebSocket disconnected");
  };

  socket.onerror = (error: any) => {
    console.error("WebSocket error:", error);
  };

  return socket;
};

function* connectWebSocket(): any {
  return yield takeLatest(
    actionTypes.WEBSOCKET_CONNECT,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterConnectSocket,
          action.payload
        );
        action.payload.webSocketCallBack({
          status: "success",
          returnData: data,
        });
      } catch (error) {
        console.log(error);
      }
    }
  );
}

async function shooterGetChatHistory(data: any): Promise<any> {
  return new Promise((resolve, reject) => {
    let messages: any[] = [];

    socket.onopen = () => {
      // Request chat history on connection
      socket.send(data);
    };

    socket.onmessage = (event: any) => {
      const message = JSON.parse(event.data);
      if (message.type === "chat_history") {
        messages = message.data.map((msg: any) => ({
          id: msg.id,
          content: msg.content,
          role: msg.role,
          intent: msg?.intent,
          model: msg.llm,
          tokens: msg.tokens,
          feedback: msg.feedback,
          question: msg.question,
          refer: JSON.parse(msg.refer ? msg.refer : "[]"),
        }));
        resolve(messages);
      }
    };

    socket.onerror = (error: any) => {
      reject(error);
    };
  });
}

function* getChatHistory(): any {
  return yield takeLatest(
    actionTypes.WEBSOCKET_GET_CHAT_HISTORY,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(
          shooterGetChatHistory,
          action.payload
        );
        yield put({
          type: actionTypes.WEBSOCKET_GET_CHAT_HISTORY_SUCCESS,
          payload: data,
        });
      } catch (error) {
        console.log(error);
      }
    }
  );
}

export default function* runBlockingCallsExample() {
  yield all([connectWebSocket(), getChatHistory()]);
}
