// app/[username]/[eventId]/page.jsx
// import EventDetails from "./_components/event-details";

import { Card, CardBody } from "@material-tailwind/react";
import EventDetails from "./_components/event-details";
import BookingForm from "./_components/booking-form";
import { dummyAvailability } from "./data";

// export async function generateMetadata({ params }) {
//   return {
//     title: `Book ${event.title} with ${event.user.name} | Your App Name`,
//     description: `Schedule a ${event.duration}-minute ${event.title} event with ${event.user.name}.`,
//   };
// }

const EventBookingPage = () => {
  return (
    <div className="flex flex-col justify-between lg:flex-row px-4 ">
      {/* <EventDetails /> */}
      <BookingForm availability={dummyAvailability} />
    </div>
  );
};

export default EventBookingPage;
