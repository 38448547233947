import React, { useEffect, useState } from "react";
import "./Leaderboard.css"; // You can create a separate CSS file for the styles
import { useMutation } from "react-query";
import http from "../../../services/http-common";
import { setSessionExpired, useMainController } from "../../../context";
import { Card, Spinner } from "@material-tailwind/react";

const Leaderboard = () => {
  const [dispatch] = useMainController();
  const [topContributors, setTopContributors] = useState(null);
  // Leaderboard data
  const colorMap = [
    "red",
    "yellow",
    "green",
    "green",
    "green",
    "cyan",
    "cyan",
    "cyan",
  ];

  const getTopContributors = useMutation(
    () => {
      return http.get(
        "train/book/top-contributors",
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        let transformedData = data.map((item, index) => {
          const rank = `${index + 1}${["ST", "ND", "RD"][index] || "TH"}`;
          return {
            rank: rank,
            score: `${item.docCount} DOCS`,
            initial: item.username,
            color: colorMap[index] || "cyan",
          };
        });
        setTopContributors(transformedData);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  useEffect(() => {
    getTopContributors.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <div className="leaderboard-light w-full text-center ">
        <h1 className="text-center text-lg text-[#000080]">Top Contributors</h1>
        <ul>
          {topContributors ? (
            topContributors?.map((item, index) => (
              <li key={index} className="flex justify-center items-center">
                <span className={`rank ${item.color} text-sm self-center`}>
                  {item.rank}
                </span>
                <span className={`score ${item.color} text-sm self-center`}>
                  {item.score}
                </span>
                <span className={`initial ${item.color} text-sm self-center`}>
                  {item.initial}
                </span>
              </li>
            ))
          ) : (
            <div className="w-full flex justify-center items-center my-[50px]">
              <Spinner />
            </div>
          )}
        </ul>
        <p className="text-sm ">ENTHEOGPT</p>
        <p className="text-sm">FREE TO CONTRIBUTE!</p>
      </div>
    </Card>
  );
};

export default Leaderboard;
